import { ReactNode } from 'react';
import { subYears } from 'date-fns';
import { ChileanFlag } from '../icons';
import { ColombianFlag } from '../icons';
import { MexicanFlag } from '../icons';
import { FireIcon } from '@heroicons/react/24/solid';

export const API_KEY_GOOGLE_MAPS = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const SANTIAGO_COORDINATES = { lat: -33.4509747, lng: -70.6567212 };
export const GOOGLE_MAPS_MARKER_COLOR_URLS = {
  primary: '/marker.svg',
};
export const DEFAULT_GOOGLE_MAPS_ZOOM = 12;
export const CACHE_CONTROL = {
  COMMUNE_CAPITAL_GAIN_DATA: 259200, // 3 days
  UF_PRICE: 10800, // 3 hours
  FPAY: 300, // 2 hours
};
export const DEFAULT_VALUE_FPAY = '1.020.854.714';
export const MAX_FILE_SIZE = 10000000;
// Others
const ADULT_AGE = 18;
const ELDER_AGE = 80;
export const MAX_DATE_CREDIT_FORM = subYears(new Date(), ADULT_AGE);
export const MIN_DATE_CREDIT_FORM = subYears(new Date(), ELDER_AGE);
export const MIN_DATE_SCHEDULE_FORM = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 1
);
export const MAX_DATE_SCHEDULE_FORM = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  new Date().getDate()
);
export const MONTH_NAMES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const DEFAULT_PASE_SIZE = 1000;

export const COLOMBIA_INTERNAL_RECIPIENTS =
  process.env.NOTIFICATIONS_API_COL_INTERNAL_RECIPIENTS;

export const MEXICO_INTERNAL_RECIPIENTS =
  process.env.NOTIFICATIONS_API_MEX_INTERNAL_RECIPIENTS;

export const DEFAULT_QUOTATION_INTERNAL_RECIPIENT =
  process.env.NOTIFICATIONS_API_INTERNAL_RECIPIENT;

export const REGEX_EMAIL =
  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

export const REGEX_PHONE_NUMBER = /^[0-9]{8}$/;
export const REGEX_PHONE_NUMBER_COL = /^[0-9]*$/;

export const NEW_BUYDEPA_COLOR = '#3A5AFF';

export const REGEX_ADDRESS = /(.*)\s(\d){1,5}/i;

export const WEB_SCHEDULER_START_DATE = new Date();

export const WEB_SCHEDULER_MIN_DATE = new Date('2023-02-01');

export const PRE_SALE_BROKER_PURCHASING_STATUS_OPTIONS = [
  'Cierre y Copia',
  'Cerrada para ingreso a CBR',
];

export const STOCK_BROKER_PURCHASING_STATUS_OPTIONS = [
  'Proceso de Inscripcion',
  'Inscripcion Finalizada',
  'Informe Final',
  'Acta de Entrega',
  'Proceso Finalizado',
];

export const BROKER_PROPERTIES_STATUS_OPTIONS = [
  'Stock',
  'Pre venta',
  'Pre venta recibido',
];

export const LIST_FURNISHED = [
  'Cocina a gas',
  'Lavadora',
  'Refrigerador',
  'Cama de 2 plazas + respaldo',
  'Microondas',
  'Veladores',
];

export const AVAILABLE_PURCHASING_STATUSES = [
  'Cierre y Copia',
  'Cerrada para ingreso a CBR',
  'Proceso de Inscripcion',
  'Inscripcion Finalizada',
  'Informe Final',
  'Acta de Entrega',
  'Proceso Finalizado',
];

export const AVAILABLE_SELLING_STATUSES = ['Disponible'];

export const MAX_PAGE_INDEX_FOR_SELECTION = 5;

export const COMMUNE_OPTIONS: string[] = [
  'Santiago',
  'Estacion Central',
  'La Cisterna',
  'La Florida',
  'San Miguel',
  'Quinta Normal',
];
export const BATHROOM_OPTIONS: number[] = [1, 2, 3];
export const BEDROOM_OPTIONS: number[] = [1, 2, 3];
export const ANTIQUITY_YEAR_HELPER = 1000;
export const PURCHASE_OPERATIONS_STATUS = [
  {
    label: 'Antecedentes',
    type: 'Antecedentes',
    value: 'Antecedentes',
    href: 'background',
  },
  {
    label: 'Estudio de título',
    type: 'EstudioDeTitulo',
    value: 'Titulo',
    href: 'title',
  },
  {
    label: 'Notaría y firma',
    type: 'NotariaYFirma',
    value: 'Notaría',
    href: 'notary',
  },
  {
    label: 'Inscripción y entrega',
    type: 'InscripcionYEntrega',
    value: 'Inscripción',
    href: 'registration',
  },
];

export const TYPES_FILES = [
  { label: 'Otros', value: 'Otro' },
  {
    label: 'Contrato de arriendo',
    value: 'ContratoDeArriendo',
  },
  {
    label: 'Copia del reglamento del Fondo de Inversión',
    value: 'CopiaReglamentoFondoInversion',
  },
  { label: 'Copia cédula de identidad', value: 'CopiaCedulaDeIdentidad' },
  { label: 'Escritura de compra venta', value: 'EscrituraDeCompraVenta' },
  {
    label: 'Certificado de dominio vigente',
    value: 'CertificadoDeDominioVigente',
  },
  {
    label: 'Certificado de hipoteca y gravamenes',
    value: 'CertificadoDeHipotecaYGravamenes',
  },
  { label: 'Certificado de avalúo fiscal', value: 'CertificadoDeAvaluoFiscal' },
  {
    label: 'Certificado de contribuciones',
    value: 'CertificadoDeContribuciones',
  },
  {
    label: 'Certificado de estacionamiento',
    value: 'CertificadoDeEstacionamiento',
  },
  { label: 'Certificado de bodega', value: 'CertificadoDeBodega' },
  {
    label: 'Certificado de derechos de aseo',
    value: 'CertificadoDeDerechosDeAseo',
  },
  {
    label: 'Certificado de no expropiación municipal',
    value: 'CertificadoDeNoExpropiacionMunicipal',
  },
  {
    label: 'Certificado de no expropiación serviu',
    value: 'CertificadoDeNoExpropiacionServiu',
  },
  { label: 'Certificado de estado civil', value: 'CertificadoDeEstadoCivil' },
  { label: 'Título reparado', value: 'TituloReparado' },
  { label: 'Título subsanado', value: 'TituloSubsanado' },
  { label: 'Informe de títulos', value: 'InformeDeTitulos' },
  {
    label: 'Confección de borrador de escritura de compraventa',
    value: 'BorradorDeEscrituraLegaltec',
  },
  {
    label: 'Confección de instrucciones notariales',
    value: 'BorradorDeInstruccionesLegaltec',
  },
  { label: 'Borrador de acta de entrega', value: 'BorradorDeActaDeEntrega' },
  {
    label: 'Borrador de esctiura correcciones buydepa',
    value: 'BorradorDeEscrituraCorreccionesBuydepa',
  },
  {
    label: 'Borrador de acta de entrega correciones buydepa',
    value: 'BorradorDeActaDeEntregaCorreccionesBuydepa',
  },
  {
    label: 'Borrador de escritura versión final',
    value: 'BorradorDeEscrituraVersionFinal',
  },
  {
    label: 'Borrador de instrucciones versional final',
    value: 'BorradorDeInstruccionesVersionFinal',
  },
  {
    label: 'Borrador de actca de entrega version final',
    value: 'BorradorDeActaDeEntregaVersionFinal',
  },
  { label: 'GP', value: 'GP' },
  { label: 'Ficha UAF', value: 'FichaUAF' },
  {
    label: 'Liquidación de prepago crédito hipotecario',
    value: 'LiquidacionDePrepagoCreditoHipotecario',
  },
  { label: 'Escritura matrizada', value: 'EscrituraMatrizada' },
  { label: 'Solicitud vale vistas', value: 'SolicitudValeVistas' },
  { label: 'Vale vistas emitidos', value: 'ValeVistasEmitidos' },
  { label: 'Liquidación Banco', value: 'LiquidacionBanco' },
  {
    label: 'Póliza de seguro incéndio y sismos',
    value: 'PolizaDeSeguroIncendioYSismos',
  },
  {
    label: 'Certificado de inscripciones',
    value: 'CertificadoDeInscripciones',
  },
  { label: 'Inscripción hipoteca', value: 'InscripcionHipoteca' },
  { label: 'Inscripción prohibición', value: 'InscripcionProhibicion' },
  { label: 'Certificado cancelación', value: 'CertificadoCancelacion' },
  { label: 'GP actualizado', value: 'GPActualizado' },
  { label: 'Acta de entrega', value: 'ActaDeEntrega' },
  {
    label: 'Certificado no deuda pensión alimentos',
    value: 'CertificadoNoDeudaPensionAlimentos',
  },
  {
    label: 'Borrador de instrucciones',
    value: 'BorradorDeInstrucciones',
  },
  {
    label: 'Copia escritura constitución sociedad',
    value: 'CopiaEscrituraConstitucionSociedad',
  },
  {
    label: 'Copias modificación estatutos sociedad',
    value: 'CopiaModificacionEstatutosSociedad',
  },
  {
    label: 'Copias escritura representantes sociedad',
    value: 'CopiaEscrituraRepresentantesSociedad',
  },
  {
    label: 'Copia inscripción estatutos',
    value: 'CopiaInscripcionEstatutos',
  },
  {
    label: 'Copia publicación sociedad Diario Oficial',
    value: 'CopiaPublicacionSociedadDiarioOficial',
  },
  {
    label: 'Fotocopia tarjetas RUT sociedad',
    value: 'FotocopiaTarjetasRutSociedad',
  },
  {
    label: 'Contrato de arriendo',
    value: 'ContratoDeArriendo',
  },
  {
    label: 'Copia del reglamento del Fondo de Inversión',
    value: 'CopiaReglamentoFondoInversion',
  },
  {
    label: 'Version final escritura de compra venta',
    value: 'VersionFinalEscrituraDeCompraVenta',
  },
  {
    label: 'Version final borradorinstrucciones notariales',
    value: 'VersionFinalBorradorInstruccionesNotariales',
  },
  {
    label: 'Documentos disponibles para firma',
    value: 'DocumentosDisponiblesParaFirma',
  },
  {
    label: 'Documentos firmados',
    value: 'DocumentosFirmados',
  },
  {
    label: 'Escritura cerrada por Notaría',
    value: 'EscrituraCerradaPorNotaria',
  },
  {
    label: 'Entrega de propiedad y liberación de fondos*',
    value: 'EntregaDePropiedadYLiberacionDeFondos',
  },
  {
    label: 'Ingreso a inscripción en CBR',
    value: 'IngresaAInscripcionEnCBR',
  },
  {
    label: 'Compraventa inscrita en CBR',
    value: 'CompraventaInscritaEnCBR',
  },
  {
    label: 'Informe final de la transacción',
    value: 'InformeFinalDeLaTransaccion',
  },
];

export const CIVIL_STATUS = [
  {
    value: 'Soltero',
    label: 'Soltero',
  },
  {
    value: 'Viudo',
    label: 'Viudo',
  },
  {
    value: 'Separado',
    label: 'Separado',
  },
  {
    value: 'CasadoSociedadConyugal',
    label: 'Casado Sociedad Conyugal',
  },
  {
    value: 'CasadoSeparacionBienes',
    label: 'Casado Separaciones Bienes',
  },
  {
    value: 'CasadoSeparacionGananciales',
    label: 'Casado Separación Gananciales',
  },
];

export const MEMBERS_DIRECTORY = [
  {
    image: '/images/nicolas-mendez.png',
    name: 'Nicolás Méndez',
    position: 'Co-Founder & CEO',
    description:
      '9 años liderando equipos de alto rendimiento en el mercado de capitales de LATAM',
  },
  {
    image: '/images/pablo-ruiz-tagle.png',
    name: 'Pablo Ruiz-Tagle',
    position: 'Co-Founder',
    description:
      'Pionero en la industria del multifamily en Chile, desarrolló y vendió sobre 350M USD',
  },
  {
    image: '/images/brian-thornton.png',
    name: 'Brian Thornton',
    position: 'Director',
    description:
      'Especialista en financiamiento estructurado en sectores de energía, inmobiliario e infraestructura.',
  },
  {
    image: '/images/antonio-roa.png',
    name: 'Antonio Roa',
    position: 'Co-Founder',
    description:
      'Emprendedor en serie con foco en rubro inmobiliario, ha creado 4 empresas de alto rendimiento en los últimos 5 años',
  },
  {
    image: '/images/ignacio-canals.png',
    name: 'Ignacio Canals',
    position: 'Inversionista y Director',
    description:
      'Fundador de Migrante, Lemontech, Instagis y director de emprendimiento',
  },
  {
    image: '/images/jose-antonio-barrios.png',
    name: 'José Antonio Berrios',
    position: 'Director',
    description: 'Fundador de Broota y director de startups',
  },
  {
    image: '/images/diego-fleischmann.png',
    name: 'Diego Fleischmann',
    position: 'Inversionista y Director',
    description:
      'Fundador de AVLA, Migrante, Creditú, director de Xepelin y ex presidente de ASECH',
  },
];

export const VERCEL_COUNTRY_HEADER_KEY = 'X-Vercel-IP-Country';

export const COUNTRY_CONFIGS: Record<
  string,
  {
    href: string;
    code: string;
    navBarTitle: string;
    countryFilterValue: string;
    flag: ReactNode;
    selected: boolean;
    showCapitalGain?: boolean;
    showDividendCalculator?: boolean;
    showUFData?: boolean;
    showSubsidyData?: boolean;
    showFinancingData?: boolean;
    showCaprate?: boolean;
    geoApartmentLabel: string;
    currency: string;
    installments: number;
    downpaymentFinancingLabel: string;
    navigation: {
      name: string;
      href: string;
      collapse?: boolean;
      title?: string;
      children?: { name: string; href: string; icon?: any }[];
    }[];
    navigationDetail: { name: string; href: string }[];
  }
> = Object.freeze({
  CL: {
    href: '/',
    code: 'CL',
    navBarTitle: 'CL',
    countryFilterValue: 'Chile',
    flag: <ChileanFlag />,
    selected: false,
    showCapitalGain: true,
    showDividendCalculator: true,
    showUFData: true,
    showSubsidyData: true,
    showCaprate: true,
    geoApartmentLabel: 'Comuna',
    currency: 'UF',
    installments: 60,
    downpaymentFinancingLabel: 'pie',
    navigation: [
      { name: 'Vender', href: '/vende' },
      { name: 'Comprar', href: '/compra' },
    ],
    navigationDetail: [
      { name: 'Brokers', href: '/brokers' },
      { name: 'Créditos', href: '/credito' },
      { name: 'Tracking', href: '/tracking' },
      {
        name: 'Financiamiento',
        href: '/financiamiento/financiamiento-buydepa',
      },
      {
        name: 'Somos Buydepa',
        href: '/quienes-somos/nosotros',
      },
      { name: 'Blog', href: '/blog' },
      { name: 'Agentes inmobiliarios', href: '/agentes-inmobiliarios' },

      { name: 'Preguntas Frecuentes', href: '/preguntas-frecuentes/faqs' },
      { name: 'Canal de denuncias', href: '/canal-de-denuncias' },
    ],
  },
  CO: {
    href: '/co',
    code: 'CO',
    navBarTitle: 'COL',
    countryFilterValue: 'Colombia',
    flag: <ColombianFlag />,
    geoApartmentLabel: 'Barrio',
    selected: false,
    currency: 'COP',
    installments: 60,
    downpaymentFinancingLabel: 'cuota inicial',
    navigation: [{ name: 'Somos Buydepa', href: '/quienes-somos/nosotros' }],
    navigationDetail: [],
  },
  MX: {
    href: '/mx',
    code: 'MX',
    navBarTitle: 'MX',
    countryFilterValue: 'Mexico',
    flag: <MexicanFlag />,
    geoApartmentLabel: 'Distrito',
    currency: 'MXN',
    installments: 60,
    downpaymentFinancingLabel: 'pie',
    selected: false,
    navigation: [],
    navigationDetail: [],
  },
});

export const DEFAULT_COUNTRY_CODE = COUNTRY_CONFIGS.CL.code;

export const COUNTRY_COOKIE_EXPIRATION = 60 * 60 * 24 * 30; // 30 days

export const SEO_DEFAULT = {
  title: 'Buydepa - Accede a tu vivienda!',
  titleTemplate: '%s | Buydepa',
  description:
    'Vende sin pagar comisiones y evita todos los trámites asociados a tu venta',
};
