import Link from 'next/link';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../../../utils/class-names';
import { useRouter } from 'next/router';

const navigation = [
  { name: 'inicio', href: '/vende' },
  { name: 'Vender', href: '/compra' },
  { name: 'comprar', href: '/credito' },
  { name: 'academia', href: '/tracking' },
];

export function Header() {
  const { pathname } = useRouter();
  const countries = [
    {
      name: 'CL',
      href: '/',
      selected: pathname !== '/co' && pathname !== '/mx',
    },
    { name: 'COL', href: '/co', selected: pathname === '/co' },
    { name: 'MX', href: '/mx', selected: pathname === '/mx' },
  ];

  return (
    <Popover as="header" className="relative">
      <div className="pt-3 bg-white">
        <nav
          className="relative flex items-center justify-between px-4 mx-auto max-w-7xl sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1 justify-between">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link href="/">
                <div className="cursor-pointer">
                  <span className="sr-only">Buydepa logo</span>
                </div>
              </Link>
              <div className="flex items-center -mr-2 md:hidden">
                <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden space-x-10 md:flex md:ml-10 mr-4">
              {navigation.map((item) => (
                <Link key={item.name} href={item.href}>
                  <span
                    className={classNames(
                      'text-base font-light text-gray-700 cursor-pointer hover:text-buydepa-blue uppercase',
                      pathname === item.href
                        ? 'text-buydepa-blue font-bold'
                        : '',
                    )}
                  >
                    {item.name}
                  </span>
                </Link>
              ))}
              <div className="">
                {countries.map((item) => (
                  <Fragment key={item.name}>
                    <Link href={item.href}>
                      <span
                        className={classNames(
                          'text-base cursor-pointer uppercase mx-1 hover:text-buydepa-blue',
                          item.selected ? 'text-black' : 'text-gray-700',
                        )}
                      >
                        {item.name}
                      </span>
                    </Link>
                    {item.name !== 'MX' && (
                      <span className="text-base font-light text-gray-700 mx-1">
                        |
                      </span>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 p-2 transition origin-top transform md:hidden"
        >
          <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
            <div className="flex items-center justify-between px-5 pt-4">
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="pt-5 pb-6">
              {navigation.map((item) => (
                <div key={item.name} className="px-5 mt-6">
                  <Link
                    href={item.href}
                    className="block px-3 py-2 text-base font-medium text-gray-900 rounded-md hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
              <div className="px-5 mt-6">
                {countries.map((item) => (
                  <Fragment key={item.name}>
                    <Link href={item.href}>
                      <span
                        className={classNames(
                          'text-base cursor-pointer uppercase mx-1 hover:text-buydepa-blue',
                          item.selected ? 'text-black' : 'text-gray-700',
                        )}
                      >
                        {item.name}
                      </span>
                    </Link>
                    {item.name !== 'MX' && (
                      <span className="text-base font-light text-gray-700 mx-1">
                        |
                      </span>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
