import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const apiUrls = [
  `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
  `${process.env.NEXT_PUBLIC_BD_API_HOST}/graphql`,
  `${process.env.NEXT_PUBLIC_API_CREDITS}/graphql`,
];

const apiHeaders = [
  {
    clientName: 'default',
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_KEY}`,
      // Otros encabezados específicos de la API 1
    },
  },
  {
    clientName: 'apiDocMngr',
  },
  {
    clientName: 'apiCredits',
  },
];

const authLinks = apiHeaders.map(({ clientName, headers }) => {
  return new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    const currentClientName = context.clientName || 'default';

    if (currentClientName === clientName) {
      operation.setContext(({ headers: prevHeaders = {} }) => ({
        headers: {
          ...prevHeaders,
          ...headers,
        },
      }));
    }

    return forward(operation);
  });
});

const httpLinks = apiUrls.map(apiUrl => {
  return createHttpLink({
    uri: apiUrl,
  });
});


const newApolloLink = ApolloLink.split(
  (operation) => !operation.getContext().clientName, // Default client
  authLinks[0].concat(httpLinks[0]),
  ApolloLink.split(
    (operation) => operation.getContext().clientName === 'apiDocMngr',
    authLinks[1].concat(httpLinks[1]),
    ApolloLink.split(
      (operation) => operation.getContext().clientName === 'apiCredits',
      authLinks[2].concat(httpLinks[2]),
    ),
  ),
);


// const newApolloLink = ApolloLink.split(
//   (operation) => !operation.getContext().clientName, // Default client
//   authLink.concat(httpLink),
//   authLinkDocumentManager.concat(
//     ApolloLink.split(
//       (operation) => operation.getContext().clientName === 'apiDocMngr',
//       httpLinkDocumentManagement,
//       authLinkCredits.concat(
//         ApolloLink.split(
//           (operation) => operation.getContext().clientName === 'apiCredits',
//           httpLinkCredits
//         )
//       )
//     ),
//   ),
// );

const client = new ApolloClient({
  link: newApolloLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default client;
