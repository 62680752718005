import { createContext, useCallback, useReducer, useMemo, useContext } from 'react';
import { setCookie } from 'nookies';
import { COUNTRY_COOKIE_EXPIRATION } from '../utils/constants';
import type { FC, ReactNode } from 'react';

interface GeoContextProps {
  country: string;
}
interface StateUi extends GeoContextProps {
  setCountry: (country: string) => void;
}

export type COUNTRY = 'CL' | 'COL' | 'MX' | string;

interface GeoContextProviderProps {
  children: ReactNode;
}
const initialState: GeoContextProps = {
  country: '',
};

export const GeoContext = createContext<StateUi | any>(initialState);
GeoContext.displayName = 'GeoContext';
type Action =
  | { type: 'SET_COUNTRY', value: COUNTRY };

const uiReducer = (state: GeoContextProps, action: Action): GeoContextProps => {
  switch (action.type) {
    case 'SET_COUNTRY':
      setCookie(null, 'country', action.value, {
        maxAge: COUNTRY_COOKIE_EXPIRATION,
        path: '/',
      });
      return {
        ...state,
        country: action.value,
      };
  }
};

export const GeoContextProvider = ({ children }: GeoContextProviderProps) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);
  const setCountry = useCallback(
    (value: COUNTRY) => dispatch({ type: 'SET_COUNTRY', value }),
    [dispatch],
  );
  const value = useMemo(
    () => ({
      ...state,
      setCountry,
    }),
    [state, setCountry],
  );

  return (
    <GeoContext.Provider value={value}>{children}</GeoContext.Provider>
  );
}

export const useUI = () => {
  const context = useContext<StateUi>(GeoContext);
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
};
interface ManagedUIContextProps {
  children: ReactNode;
}
export const ManagedUIContext: FC<ManagedUIContextProps> = ({
  children,
}) => <GeoContextProvider>{children}</GeoContextProvider>;