import axios, { AxiosInstance } from 'axios';

export const LOGOUT_URL = '/brokers/api/auth/logout';

export const getInstance = (): AxiosInstance => {
  const instance = axios.create();

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response && error.response.status === 401) {
        return window.location.replace(LOGOUT_URL);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
