import classNames from 'classnames';
import type { ReactNode } from 'react';

export interface GridProps {
  cols: number;
  children: ReactNode;
  colsSm?: number;
  colsMd?: number;
  colsLg?: number;
  colsXl?: number;
  cols2Xl?: number;
  gap?: number;
  className?: string;
}

export default function Grid({
  cols,
  children,
  colsSm,
  colsMd,
  colsLg,
  colsXl,
  cols2Xl,
  gap = 4,
  className,
}: GridProps) {
  const gridClassNames = classNames(
    `grid items-start w-full mb-6 grid-cols-${cols} gap-${gap}`,
    colsSm && `sm:grid-cols-${colsSm}`,
    colsMd && `md:grid-cols-${colsMd}`,
    colsLg && `lg:grid-cols-${colsLg}`,
    colsXl && `xl:grid-cols-${colsXl}`,
    cols2Xl && `2xl:grid-cols-${cols2Xl}`,
    className
  );

  return <div className={gridClassNames}>{children}</div>;
}
