import { useEffect, useReducer, useRef } from 'react';

let waitController: AbortController | undefined;

export function assert(
  _condition: boolean,
  _message?: string
): asserts _condition {
  // eslint-disable-next-line no-console, prefer-rest-params
  console.assert(...arguments);
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function wait(ms: number, options: { signal?: AbortSignal } = {}) {
  const { signal } = options;

  return new Promise<void>((resolve, reject) => {
    if (signal?.aborted) reject(signal.reason);

    const id = setTimeout(() => {
      resolve();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      signal?.removeEventListener('abort', abort);
    }, ms);

    function abort() {
      clearTimeout(id);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      reject(signal!.reason);
    }

    signal?.addEventListener('abort', abort);
  });
}

export function useProgressBar({
  trickleMaxWidth = 94,
  trickleIncrementMin = 1,
  trickleIncrementMax = 5,
  dropMinSpeed = 50,
  dropMaxSpeed = 150,
  transitionSpeed = 600,
} = {}) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const widthRef = useRef(0);

  function setWidth(value: number) {
    widthRef.current = value;
    forceUpdate();
  }

  async function trickle() {
    if (widthRef.current < trickleMaxWidth) {
      const inc =
        widthRef.current +
        getRandomInt(trickleIncrementMin, trickleIncrementMax);
      setWidth(inc);
      try {
        await wait(getRandomInt(dropMinSpeed, dropMaxSpeed), {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          signal: waitController!.signal,
        });
        await trickle();
      } catch {
        // Current loop aborted: a new route has been started
      }
    }
  }

  async function start() {
    waitController?.abort();
    waitController = new AbortController();

    setWidth(1);
    await wait(0);

    await trickle();
  }

  async function complete() {
    assert(
      waitController !== undefined,
      'Make sure start() is called before calling complete()'
    );
    setWidth(100);
    try {
      await wait(transitionSpeed, { signal: waitController.signal });
      setWidth(0);
    } catch {
      // Current loop aborted: a new route has been started
    }
  }

  function reset() {
    waitController?.abort();
    setWidth(0);
  }

  useEffect(() => {
    return () => {
      waitController?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    start,
    complete,
    reset,
    width: widthRef.current,
  };
}
