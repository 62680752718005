import { FC } from 'react';
import { format } from 'rut.js';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

interface CardInfoClientProps {
  client: ClientT;
}

interface ClientT {
  name: string;
  dni: string;
  phoneNumber: string;
  email: string;
}

export const CardInfoClient: FC<CardInfoClientProps> = ({ client }) => {
  return (
    <div className="border border-light-gray-600 rounded-lg p-5">
      <div className="flex flex-wrap gap-2 justify-around">
        <p className="text-primary-600 font-bold">{client?.name}</p>
        <span>RUT: {format(client?.dni)}</span>
        <span className="flex items-center gap-2">
          <PhoneIcon width={20} />
          {client?.phoneNumber || 'Sin telefono'}
        </span>
        <span className="flex items-center gap-2">
          <EnvelopeIcon width={20} />
          {client?.email || 'Sin correo'}
        </span>
      </div>
    </div>
  );
};
