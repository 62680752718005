import { useCallback, useEffect } from 'react';
import { parseCookies } from 'nookies';
import { useUI } from '../contexts/GeoContext';
import { COUNTRY_CONFIGS, DEFAULT_COUNTRY_CODE } from '../utils/constants';
import type { Key } from 'react';

const detectClientCountry = async () => {
  const response = await fetch('/api/get-country', {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

const getStoredCountry = async () => {
  const cookies = parseCookies();
  let storedCountry = cookies.country;
  if (!storedCountry) {
    storedCountry = await detectClientCountry();
  }
  return storedCountry;
};

export const useGeoContext = () => {
  const { country, setCountry } = useUI();

  useEffect(() => {
    if (!country) {
      getStoredCountry().then((storedCountry) => {
        if (!storedCountry || !(Object.prototype.hasOwnProperty.call(COUNTRY_CONFIGS, storedCountry))) {
          storedCountry = DEFAULT_COUNTRY_CODE;
        }
        setCountry(storedCountry);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConfigByCode = (code: string) => {
    const config = Object.keys(COUNTRY_CONFIGS).find(
      (key: Key) => COUNTRY_CONFIGS[key].code === code,
    );
    return config ? COUNTRY_CONFIGS[config] : COUNTRY_CONFIGS.CL;
  };

  const handleChangeCountry = useCallback(
    ({ code, href }: { code: string; href: string }) => {
      setCountry(code);
      window.location.href = href;
    },
    [setCountry],
  );

  const countries = Object.keys(COUNTRY_CONFIGS).map((key: Key) => ({
    ...COUNTRY_CONFIGS[key],
    name: COUNTRY_CONFIGS[key].navBarTitle,
  }));

  const currentConfig = getConfigByCode(country);

  return {
    country,
    handleChangeCountry,
    getConfigByCode,
    currentConfig,
    countries,
  };
};
