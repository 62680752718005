import { FolderPlusIcon } from '@heroicons/react/24/outline';
import { Card } from '../Card/Card';
import type { ReactNode } from 'react';

export interface EmptyStateProps {
  title?: string;
  subtitle?: string;
  containerClassName?: string;
  icon?: ReactNode;
  action?: ReactNode;
}

export function EmptyState({
  title,
  subtitle,
  containerClassName,
  icon,
  action,
}: EmptyStateProps) {
  return (
    <Card className={containerClassName}>
      <div className="flex flex-col items-center justify-center text-center">
        {icon || <FolderPlusIcon width={36} />}
        {title && (
          <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
        )}
        {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
        <div className="mt-6">{action}</div>
      </div>
    </Card>
  );
}
