import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/nextjs';

export async function graphqlRequest<TData = any, TVariables = any>(
  apiUrl: string,
  query: string,
  variables?: TVariables,
  accessToken?: string
): Promise<TData> {
  try {
    const response = await axios({
      url: apiUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        query,
        variables,
      },
    });
    return response.data;
  } catch (error: any) {
    let errorMessage = '';
    Sentry.captureException(error);

    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      console.error('Axios Error:', axiosError.toJSON());
      if (axiosError.response) {
        errorMessage =
          (error?.response?.data as any)?.error ||
          JSON.stringify(axiosError?.response?.data) ||
          'An error ocurred';
        console.error('HTTP Error:', axiosError.response.status, errorMessage);
      } else if (axiosError.request) {
        errorMessage =
          JSON.stringify(axiosError?.request) || 'An error ocurred';
        console.error('Request Error:', errorMessage);
      } else {
        errorMessage = axiosError?.message || 'An error ocurred';
        console.error('Unknown Error:', errorMessage);
      }
    } else {
      errorMessage = error?.message || 'An error ocurred';
      console.error('Unknown Error:', errorMessage);
    }

    const errorObj = {
      status: error?.response?.status || 500,
      message: errorMessage || 'An error ocurred',
    };
    throw new Error(JSON.stringify(errorObj));
  }
}
