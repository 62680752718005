export function SellCountCard(props: any) {
  const { profileData } = props;
  return (
    <div className="contentCard mb-6 shadow rounded-lg">
      <div className="text-right text-sm">
        <p>Depas vendidos hasta hoy</p>
      </div>
      <div id="background">
        <p>{profileData?.soldApartments || 0}</p>
      </div>
    </div>
  );
}
