import type { ReactNode } from 'react';

export interface InfoDepaI {
  title: string;
  address: string | null;
  commune: string | null;
  children?: ReactNode;
}

export const CardInfoDepa = ({
  title,
  address,
  commune,
  children,
}: InfoDepaI) => {
  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 w-full border-b-2 pb-5">
      <div>
        <h4 className="text-5xl text-primary-600 ">{title}</h4>
        <p className="text-5xl text-magenta-600 font-bold mt-4 mb-2">
          {address}
        </p>
        <span className="text-2xl text-primary-600 font-medium">{commune}</span>
      </div>
      <div className="flex flex-col lg:items-end justify-end items-start ">
        {children}
      </div>
    </div>
  );
};
