export const getIcon = (name: string, props: any) => {
  switch (name) {
    case 'Email':
      return (
        <svg
          width="30"
          height="25"
          viewBox="0 0 30 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.875 0.16748H25.125C26.3702 0.167399 27.5682 0.643811 28.4733 1.49899C29.3785 2.35416 29.922 3.52327 29.9925 4.76648L30 5.04248V19.2925C30.0001 20.5377 29.5237 21.7357 28.6685 22.6408C27.8133 23.5459 26.6442 24.0895 25.401 24.16L25.125 24.1675H4.875C3.62979 24.1676 2.43176 23.6911 1.52665 22.836C0.62155 21.9808 0.077998 20.8117 0.00750018 19.5685L1.04628e-08 19.2925V5.04248C-8.15201e-05 3.79727 0.476331 2.59924 1.33151 1.69413C2.18668 0.789031 3.35579 0.245478 4.599 0.174981L4.875 0.16748ZM27.75 8.22698L15.525 14.662C15.387 14.7349 15.2353 14.7783 15.0795 14.7894C14.9238 14.8005 14.7675 14.7791 14.6205 14.7265L14.4765 14.6635L2.25 8.22848V19.2925C2.25002 19.9513 2.49776 20.586 2.94402 21.0706C3.39028 21.5552 4.00245 21.8543 4.659 21.9085L4.875 21.9175H25.125C25.784 21.9174 26.4189 21.6695 26.9036 21.2229C27.3882 20.7763 27.6871 20.1638 27.741 19.507L27.75 19.2925V8.22698ZM25.125 2.41748H4.875C4.21622 2.41751 3.58152 2.66524 3.09691 3.1115C2.6123 3.55776 2.31321 4.16993 2.259 4.82648L2.25 5.04248V5.68598L15 12.3955L27.75 5.68448V5.04248C27.7499 4.38346 27.502 3.74856 27.0554 3.26392C26.6089 2.77927 25.9963 2.48033 25.3395 2.42648L25.125 2.41748Z"
            fill={props.color || '#3A5AFF'}
          />
        </svg>
      );

    case 'Instagram':
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.9141 5.9309C17.9141 5.57801 18.0545 5.23958 18.3044 4.99005C18.5543 4.74053 18.8933 4.60034 19.2468 4.60034C19.6003 4.60034 19.9392 4.74053 20.1892 4.99005C20.4391 5.23958 20.5795 5.57801 20.5795 5.9309C20.5795 6.28379 20.4391 6.62222 20.1892 6.87175C19.9392 7.12128 19.6003 7.26146 19.2468 7.26146C18.8933 7.26146 18.5543 7.12128 18.3044 6.87175C18.0545 6.62222 17.9141 6.28379 17.9141 5.9309Z"
            fill={props.color || '#3A5AFF'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5832 6.26294C10.9042 6.26294 9.29404 6.92881 8.10684 8.11407C6.91965 9.29933 6.25269 10.9069 6.25269 12.5831C6.25269 14.2593 6.91965 15.8669 8.10684 17.0521C9.29404 18.2374 10.9042 18.9032 12.5832 18.9032C14.2621 18.9032 15.8723 18.2374 17.0595 17.0521C18.2467 15.8669 18.9136 14.2593 18.9136 12.5831C18.9136 10.9069 18.2467 9.29933 17.0595 8.11407C15.8723 6.92881 14.2621 6.26294 12.5832 6.26294ZM8.25178 12.5831C8.25178 11.4362 8.70813 10.3363 9.52042 9.52534C10.3327 8.71437 11.4344 8.25878 12.5832 8.25878C13.7319 8.25878 14.8336 8.71437 15.6459 9.52534C16.4582 10.3363 16.9145 11.4362 16.9145 12.5831C16.9145 13.73 16.4582 14.8299 15.6459 15.6408C14.8336 16.4518 13.7319 16.9074 12.5832 16.9074C11.4344 16.9074 10.3327 16.4518 9.52042 15.6408C8.70813 14.8299 8.25178 13.73 8.25178 12.5831Z"
            fill={props.color || '#3A5AFF'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5911 0.386598C14.9337 -0.128866 10.2335 -0.128866 5.57609 0.386598C2.89463 0.685974 0.728938 2.79491 0.414413 5.4853C-0.138138 10.2016 -0.138138 14.9661 0.414413 19.6824C0.728938 22.3727 2.8933 24.4817 5.57609 24.7811C10.2335 25.296 14.9337 25.296 19.5911 24.7811C22.2726 24.4817 24.4382 22.3727 24.7528 19.6824C25.3054 14.9661 25.3054 10.2016 24.7528 5.4853C24.4382 2.79491 22.2739 0.685974 19.5911 0.386598ZM5.79732 2.36913C10.3077 1.87 14.8595 1.87 19.3699 2.36913C21.1424 2.56871 22.5618 3.96447 22.7683 5.71815C23.3027 10.2797 23.3027 14.8879 22.7683 19.4495C22.6615 20.3078 22.2679 21.105 21.6513 21.7125C21.0346 22.3199 20.2309 22.7021 19.3699 22.7972C14.8595 23.2964 10.3077 23.2964 5.79732 22.7972C4.9363 22.7021 4.13254 22.3199 3.51589 21.7125C2.89924 21.105 2.5057 20.3078 2.39885 19.4495C1.86449 14.8879 1.86449 10.2797 2.39885 5.71815C2.5057 4.85988 2.89924 4.06264 3.51589 3.4552C4.13254 2.84776 4.9363 2.46428 5.79732 2.36913Z"
            fill={props.color || '#3A5AFF'}
          />
        </svg>
      );
    case 'Linkedin':
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.04673 8.17495C8.08303 8.17495 8.91207 7.33225 8.91207 6.30806C8.91207 5.28386 8.07008 4.44116 7.04673 4.44116C6.02339 4.44116 5.1814 5.28386 5.1814 6.30806C5.1814 7.33225 6.02339 8.17495 7.04673 8.17495ZM10.6867 9.58808V19.9856H13.8993V14.8517C13.8993 13.4904 14.1583 12.181 15.8294 12.181C17.5004 12.181 17.5004 13.7367 17.5004 14.9295V19.9856H20.7259V14.2812C20.7259 11.4809 20.13 9.32879 16.8657 9.32879C15.2983 9.32879 14.249 10.1845 13.8215 11.0012H13.7827V9.57512H10.6997L10.6867 9.58808ZM5.44047 9.58808H8.66595V19.9856H5.44047V9.58808Z"
            fill={props.color || '#3A5AFF'}
          />
          <rect
            x="0.75"
            y="0.75"
            width="23.6673"
            height="23.6673"
            rx="7.25"
            stroke={props.color || '#3A5AFF'}
            strokeWidth="1.5"
          />
        </svg>
      );
    case 'Tik Tok':
      return (
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3394 7.1084C15.7262 6.37029 15.3883 5.4224 15.3886 4.44116H12.617V16.1694C12.5957 16.8041 12.3416 17.4053 11.9083 17.8463C11.4749 18.2874 10.8962 18.5339 10.294 18.534C9.02031 18.534 7.96192 17.4368 7.96192 16.0749C7.96192 14.448 9.45084 13.2279 10.9846 13.7292V10.7404C7.89016 10.3053 5.1814 12.8401 5.1814 16.0749C5.1814 19.2245 7.65696 21.4661 10.285 21.4661C13.1014 21.4661 15.3886 19.0542 15.3886 16.0749V10.1256C16.5125 10.9767 17.8618 11.4333 19.2455 11.4308V8.50822C19.2455 8.50822 17.5592 8.59335 16.3394 7.1084Z"
            fill={props.color || '#3A5AFF'}
          />
          <rect
            x="0.75"
            y="0.75"
            width="23.6673"
            height="23.6673"
            rx="7.25"
            stroke={props.color || '#3A5AFF'}
            strokeWidth="1.5"
          />
        </svg>
      );

    case 'Twitter':
      return (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      );

    case 'Whatsapp':
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.5699 3.52116C19.4606 2.40062 18.1393 1.51217 16.683 0.907631C15.2267 0.303097 13.6646 -0.00542092 12.0879 7.2079e-05C5.48128 7.2079e-05 0.0967999 5.38456 0.0967999 11.9911C0.0967999 14.1086 0.653398 16.1656 1.69399 17.9806L0 24.2L6.35248 22.5302C8.10698 23.4861 10.0793 23.9943 12.0879 23.9943C18.6944 23.9943 24.0789 18.6098 24.0789 12.0032C24.0789 8.79675 22.8326 5.78386 20.5699 3.52116ZM12.0879 21.9615C10.2971 21.9615 8.54257 21.4775 7.00588 20.57L6.64288 20.3522L2.86769 21.3444L3.87199 17.666L3.62999 17.2909C2.63506 15.7022 2.10677 13.8657 2.10539 11.9911C2.10539 6.49775 6.58238 2.02077 12.0758 2.02077C14.7378 2.02077 17.2424 3.06136 19.1179 4.94896C20.0466 5.87335 20.7825 6.97287 21.2831 8.18379C21.7837 9.39471 22.0389 10.6929 22.034 12.0032C22.0582 17.4966 17.5812 21.9615 12.0879 21.9615ZM17.557 14.5079C17.2545 14.3627 15.7784 13.6367 15.5122 13.5278C15.2339 13.431 15.0403 13.3826 14.8346 13.673C14.6289 13.9755 14.0602 14.6531 13.8908 14.8467C13.7214 15.0524 13.5399 15.0766 13.2374 14.9193C12.9349 14.7741 11.9669 14.4474 10.8295 13.431C9.93407 12.6324 9.34117 11.6523 9.15967 11.3498C8.99027 11.0473 9.13547 10.89 9.29277 10.7327C9.42587 10.5996 9.59527 10.3818 9.74047 10.2124C9.88567 10.043 9.94617 9.90994 10.043 9.71634C10.1398 9.51064 10.0914 9.34125 10.0188 9.19605C9.94617 9.05085 9.34117 7.57465 9.09917 6.96965C8.85717 6.38885 8.60307 6.46145 8.42157 6.44935H7.84078C7.63508 6.44935 7.32048 6.52195 7.04218 6.82445C6.77598 7.12695 6.00158 7.85295 6.00158 9.32914C6.00158 10.8053 7.07848 12.2331 7.22368 12.4267C7.36888 12.6324 9.34117 15.6574 12.342 16.9521C13.0559 17.2667 13.6125 17.4482 14.0481 17.5813C14.762 17.8112 15.4154 17.7749 15.9357 17.7023C16.5165 17.6176 17.7143 16.9763 17.9563 16.2745C18.2104 15.5727 18.2104 14.9798 18.1257 14.8467C18.041 14.7136 17.8595 14.6531 17.557 14.5079Z"
            fill={props.color || '#3A5AFF'}
          />
        </svg>
      );

    case 'Facebook':
      return (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      );
    default:
      return null;
  }
};
