const WrenchAndScrewDriver = ({ ...props }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 61 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.0397 39.2763L43.8331 54.0698C45.1019 55.298 46.8025 55.9783 48.5683 55.9639C50.334 55.9496 52.0234 55.2418 53.272 53.9932C54.5207 52.7446 55.2285 51.0552 55.2428 49.2894C55.2571 47.5237 54.5768 45.823 53.3487 44.5543L38.4359 29.6416M29.0397 39.2763L35.3732 31.5878C36.1776 30.6134 37.2509 29.9993 38.4385 29.6441C39.8341 29.2279 41.3896 29.167 42.8613 29.2888C44.8452 29.4592 46.8391 29.1078 48.6452 28.2696C50.4513 27.4313 52.0069 26.1354 53.1575 24.5103C54.3081 22.8852 55.0137 20.9875 55.2044 19.0055C55.395 17.0235 55.0641 15.026 54.2444 13.2114L45.9316 21.5267C44.5408 21.2051 43.2683 20.4995 42.2589 19.4901C41.2496 18.4807 40.5439 17.2082 40.2223 15.8174L48.5351 7.50464C46.7205 6.68494 44.723 6.35401 42.741 6.54467C40.759 6.73534 38.8613 7.44097 37.2362 8.59156C35.6111 9.74215 34.3152 11.2977 33.4769 13.1038C32.6387 14.9099 32.2873 16.9039 32.4576 18.8877C32.6886 21.6181 32.2775 24.6326 30.1638 26.3733L29.9049 26.589M29.0397 39.2763L17.2277 53.6207C16.6553 54.3185 15.943 54.8887 15.1368 55.2947C14.3307 55.7006 13.4484 55.9332 12.5469 55.9776C11.6454 56.022 10.7446 55.8771 9.90247 55.5523C9.06034 55.2275 8.29554 54.73 7.65731 54.0917C7.01907 53.4535 6.52154 52.6887 6.19674 51.8466C5.87194 51.0044 5.72706 50.1036 5.77143 49.2021C5.8158 48.3006 6.04844 47.4184 6.45437 46.6122C6.8603 45.806 7.43053 45.0938 8.12835 44.5213L25.4771 30.2353L15.0557 19.8139H11.4804L5.77104 10.2984L9.57725 6.49219L19.0928 12.2015V15.7768L29.9024 26.5864L25.4745 30.2328M46.6878 47.4089L40.0269 40.7481M12.4116 49.312H12.4319V49.3323H12.4116V49.312Z"
        stroke={props.color ? props.color : '#ffffff'}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WrenchAndScrewDriver;
