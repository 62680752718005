const GreyHex = () => {
  return (
    <svg
      width="200"
      height="212"
      viewBox="0 0 200 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M199.187 71.3827C199.187 69.8954 199.107 68.3928 198.892 66.9209C197.305 55.9749 191.746 47.2177 182.538 40.6081C179.837 38.6703 176.818 37.1242 173.891 35.4859C161.023 28.2748 148.136 21.0892 135.252 13.9012C129.545 10.7167 123.941 7.34282 118.092 4.40923C107.995 -0.654162 97.4175 -1.26597 86.5794 2.10792C82.6373 3.33409 79.1302 5.3871 75.5936 7.38378C57.983 17.3263 40.3832 27.279 22.7458 37.1779C18.5163 39.5509 14.5474 42.1927 11.2847 45.7202C5.81997 51.636 2.55189 58.5246 1.25486 66.2886C0.747325 69.3323 0.768808 72.3939 0.771493 75.4657C0.798347 96.4027 0.768808 117.337 0.75001 138.274C0.747325 142.37 1.26828 146.397 2.49549 150.321C5.52458 160.013 11.4055 167.664 20.5438 172.901C28.9866 177.74 67.3335 199.263 77.9648 205.34C83.8806 208.721 90.1778 210.844 97.0845 211.238C105.793 211.737 113.828 209.73 121.21 205.317C122.359 204.615 169.382 177.914 178.655 172.681C185.854 168.621 191.279 162.962 194.979 155.822C197.861 150.262 199.198 144.349 199.198 138.154C199.198 115.896 199.206 93.6381 199.19 71.3801L199.187 71.3827Z"
        fill="#E9E9FF"
        fillOpacity="0.45"
      />
      <path
        d="M87.0003 91.0083L87.7667 91.6506L87.0002 91.0083C86.2195 91.9398 84.7682 92.1228 83.749 91.3868L77.7775 87.0745C76.7953 86.3653 76.5977 85.0786 77.2827 84.1413L76.483 83.5569L77.2827 84.1413C80.024 80.3898 83.1085 77.505 86.8169 75.5525C90.5233 73.601 94.9098 72.5508 100.3 72.5508C105.942 72.5508 111.799 74.6537 116.233 78.1435C120.666 81.6318 123.591 86.4288 123.591 91.8008C123.591 95.4165 122.572 98.0935 121.07 100.182C119.555 102.289 117.513 103.846 115.396 105.153C114.36 105.792 113.322 106.363 112.323 106.912L112.256 106.949C111.247 107.504 110.265 108.045 109.416 108.596C107.789 109.652 106.251 110.989 106.251 112.977V113.738C106.251 114.888 105.257 115.902 103.928 115.902H93.8964C92.5673 115.902 91.5741 114.888 91.5741 113.738V112.448C91.5741 105.098 97.2421 102.074 102.199 99.4295C102.278 99.3869 102.358 99.3444 102.438 99.302C104.516 98.1921 106.323 97.1956 107.597 96.0695C108.921 94.8993 109.749 93.5199 109.749 91.6746C109.749 89.2181 108.105 87.3131 106.109 86.0778C104.097 84.833 101.532 84.1315 99.2225 84.1315C96.3486 84.1315 94.1608 84.7689 92.228 85.9953C90.332 87.1984 88.7369 88.9363 87.0003 91.0083ZM90.3234 129.919C90.3234 125.481 94.1308 121.786 98.9124 121.786C103.694 121.786 107.501 125.48 107.501 129.918C107.501 134.356 103.694 138.051 98.9124 138.051C94.1308 138.051 90.3234 134.356 90.3234 129.919Z"
        stroke="#7483AB"
        strokeWidth="2"
      />
    </svg>
  );
};

export default GreyHex;
