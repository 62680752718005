const LogoItau = ({...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 57"
    fill="none"
    {...props}
  >
    <path
      fill="#005697"
      d="M56.002 8.1v40.39a1.6 1.6 0 0 0 0 .21 9.169 9.169 0 0 1-2 4.48 8.9 8.9 0 0 1-7.15 3.5H9.142a8.25 8.25 0 0 1-2.21-.31 9.41 9.41 0 0 1-6.93-9.15V9.46c-.014-.7.063-1.4.23-2.08a9.3 9.3 0 0 1 7-7.15A11.14 11.14 0 0 0 8.382 0h39.34c0 .08.11 0 .16.06a9.25 9.25 0 0 1 7.85 6.81c.06.37.13.82.27 1.23Zm-25 37.68c.13.42.28.84.4 1.26a.27.27 0 0 0 .31.22h3c.25 0 .3-.07.3-.31V39.2c0-.51 0-1-.08-1.52a5.22 5.22 0 0 0-4.35-4.81 12.438 12.438 0 0 0-4.9.08 9.779 9.779 0 0 0-2.72 1c-.09 0-.2.08-.15.24.25.83.5 1.66.74 2.49 0 .15.08.14.2.07a8.69 8.69 0 0 1 3.72-1 4.25 4.25 0 0 1 2.56.52 1.8 1.8 0 0 1 .85 1.27c0 .23 0 .33-.29.33a17.202 17.202 0 0 0-3.36.29 8.47 8.47 0 0 0-3.67 1.5 4.33 4.33 0 0 0-1.74 4.39 4 4 0 0 0 3.07 3.39 5.91 5.91 0 0 0 6.11-1.66Zm16.39-.52c.16.61.32 1.18.47 1.75 0 .17.1.26.31.25h3c.2 0 .27 0 .27-.26V33.32c0-.2-.06-.24-.25-.24h-3.77c-.24 0-.32.05-.31.31v7.95a2.65 2.65 0 0 1-1.63 2.52 2.421 2.421 0 0 1-3.5-1.59 4.618 4.618 0 0 1-.18-1.22v-7.68c0-.24-.07-.3-.31-.3h-3.68c-.2 0-.28 0-.28.27v8.17a8.43 8.43 0 0 0 .39 2.69 4.399 4.399 0 0 0 2.44 2.89 6.001 6.001 0 0 0 5.87-.6c.431-.36.808-.782 1.12-1.25l.04.02Zm-27.3-1.36a3.39 3.39 0 0 1-1.09.1 1.5 1.5 0 0 1-1.51-1.18 5.283 5.283 0 0 1-.16-1.31v-5c0-.23.07-.26.27-.25h2.21c.18 0 .23 0 .23-.23v-2.77c0-.2-.06-.24-.25-.24h-2.19c-.21 0-.28 0-.27-.27v-4.18c0-.22 0-.3-.28-.3h-3.69c-.22 0-.27.06-.27.28v4.18c0 .22-.05.3-.28.29h-1.53c-.14 0-.19 0-.19.18v2.85c0 .17.06.2.21.19h1.52c.23 0 .27.07.27.27v5.38c-.016.721.045 1.442.18 2.15a4 4 0 0 0 1.69 2.74 4.809 4.809 0 0 0 2.29.74 6.08 6.08 0 0 0 2.67-.33.22.22 0 0 0 .16-.24l.01-3.05Zm-11.08-6.1v-9.14c0-.24 0-.33-.3-.33h-3.8c-.21 0-.27.06-.27.28v18.37c0 .24.09.28.3.28h3.8c.24 0 .29-.07.29-.29-.03-3.06-.03-6.11-.03-9.17h.01Zm40.58-9.47h-4a.318.318 0 0 0-.28.17c-.41.59-.82 1.18-1.22 1.78l-1.29 1.86h2.8a.53.53 0 0 0 .41-.17c.85-.89 1.71-1.78 2.57-2.66l1.01-.98Z"
    />
    <path
      fill="#FDEF00"
      d="M31.002 45.78a5.909 5.909 0 0 1-6.09 1.57 4 4 0 0 1-3.07-3.39 4.33 4.33 0 0 1 1.74-4.39 8.47 8.47 0 0 1 3.67-1.5 17.195 17.195 0 0 1 3.36-.29c.27 0 .33-.1.29-.33a1.8 1.8 0 0 0-.85-1.27 4.25 4.25 0 0 0-2.56-.52 8.69 8.69 0 0 0-3.72 1c-.12.07-.16.08-.2-.07-.24-.83-.49-1.66-.74-2.49-.05-.16.06-.19.15-.24a9.78 9.78 0 0 1 2.72-1 12.44 12.44 0 0 1 4.9-.08 5.22 5.22 0 0 1 4.35 4.81c0 .51.08 1 .08 1.52v7.75c0 .24-.05.32-.3.31h-3a.27.27 0 0 1-.31-.22c-.18-.33-.33-.71-.42-1.17Zm0-4.21v-.83c0-.27 0-.23-.21-.23a11.73 11.73 0 0 0-2.44.23 3.51 3.51 0 0 0-1.77.86 1.871 1.871 0 0 0-.46 1.87 1.59 1.59 0 0 0 1.23 1.19 3.09 3.09 0 0 0 3.24-1.21 3 3 0 0 0 .41-1.88ZM47.352 45.24a5.846 5.846 0 0 1-1.2 1.23 5.999 5.999 0 0 1-5.87.6 4.4 4.4 0 0 1-2.44-2.89 8.43 8.43 0 0 1-.39-2.69v-8.17c0-.24.08-.27.28-.27h3.68c.24 0 .31.06.31.3v7.68c.005.413.065.823.18 1.22a2.421 2.421 0 0 0 3.5 1.59 2.65 2.65 0 0 0 1.63-2.52v-7.95c0-.26.07-.31.31-.31h3.77c.19 0 .25 0 .25.24v13.68c0 .22-.07.26-.27.26h-3c-.21 0-.27-.08-.31-.25-.11-.55-.27-1.12-.43-1.75ZM20.052 43.9v3.11a.22.22 0 0 1-.16.24 6.08 6.08 0 0 1-2.67.33 4.811 4.811 0 0 1-2.29-.74 4 4 0 0 1-1.69-2.74 10.357 10.357 0 0 1-.18-2.15v-5.38c0-.2 0-.28-.27-.27h-1.52c-.15 0-.21 0-.21-.19v-2.85c0-.15.05-.19.19-.18h1.53c.23 0 .28-.07.28-.29v-4.18c0-.22.05-.28.27-.28h3.67c.24 0 .28.08.28.3v4.18c0 .23.06.28.27.27h2.19c.19 0 .26 0 .25.24v2.77c0 .19-.05.23-.23.23h-2.21c-.2 0-.28 0-.27.25v5c-.002.442.052.882.16 1.31a1.5 1.5 0 0 0 1.51 1.18c.372.008.744-.046 1.1-.16ZM9.001 37.8v9.17c0 .22-.05.29-.29.29h-3.8c-.21 0-.3 0-.3-.28V28.61c0-.22.06-.28.27-.28h3.8c.27 0 .31.09.3.33l.02 9.14ZM49.551 28.33l-1 1c-.86.88-1.72 1.77-2.57 2.66a.53.53 0 0 1-.41.17h-2.8l1.29-1.86c.4-.6.81-1.19 1.22-1.78a.32.32 0 0 1 .28-.17l3.99-.02Z"
    />
    <path
      fill="#005697"
      d="M31.001 41.57a3 3 0 0 1-.41 1.88 3.09 3.09 0 0 1-3.24 1.21 1.59 1.59 0 0 1-1.23-1.19 1.87 1.87 0 0 1 .46-1.87 3.51 3.51 0 0 1 1.77-.86c.803-.161 1.621-.238 2.44-.23.19 0 .22.07.21.23-.01.16 0 .55 0 .83Z"
    />
  </svg>
)
export default LogoItau
