const AreaIcon = ({ ...props }) => (
  <svg
    width={props.width ? props.width : '24'}
    height={props.height ? props.height : '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.684 20.9444V21.2444H17.984H20.5532H20.8532V20.9444V14.5556V14.2556H20.5532H9.29202V3.05556V2.75556H8.99202H2.56915H2.26915V3.05556V5.61111V5.91111H2.56915H4.8383V7.86667H2.56915H2.26915V8.16667V10.7222V11.0222H2.56915H6.12287V12.9778H2.56915H2.26915V13.2778V15.8333V16.1333H2.56915H4.8383V18.0889H2.56915H2.26915V18.3889V20.9444V21.2444H2.56915H5.1383H5.4383V20.9444V18.6889H7.40745V20.9444V21.2444H7.70745H10.2766H10.5766V20.9444V17.4111H12.5457V20.9444V21.2444H12.8457H15.4149H15.7149V20.9444V18.6889H17.684V20.9444ZM11.2612 12V12.3H11.5612H21.8378C22.0994 12.3 22.35 12.4034 22.5345 12.5869C22.719 12.7704 22.8223 13.019 22.8223 13.2778V22.2222C22.8223 22.481 22.719 22.7296 22.5345 22.9131C22.35 23.0966 22.0994 23.2 21.8378 23.2H1.28457C1.02294 23.2 0.772333 23.0966 0.587812 22.9131C0.403343 22.7296 0.3 22.481 0.3 22.2222V1.77778C0.3 1.51897 0.403343 1.27044 0.587812 1.08695C0.772334 0.9034 1.02294 0.8 1.28457 0.8H10.2766C10.5382 0.8 10.7888 0.9034 10.9734 1.08695C11.1578 1.27044 11.2612 1.51897 11.2612 1.77778V12Z"
      fill={props.color ? props.color : '#7483AB'}
      stroke="white"
      strokeWidth="0.6"
    />
  </svg>
);

export default AreaIcon;
