const RoundColombianFLag = ({ ...props }) => (
  <svg
    className="w-[15px] h-[15px] md:w-[31px] md:h-[31px]"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3190_4948)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.0942383 -0.527344H30.9058V30.4727H-0.0942383V-0.527344Z"
        fill="#FFE800"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.0942383 14.9727H30.9058V30.4727H-0.0942383V14.9727Z"
        fill="#00148E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.0942383 22.7227H30.9058V30.4727H-0.0942383V22.7227Z"
        fill="#DA0010"
      />
    </g>
    <defs>
      <clipPath id="clip0_3190_4948">
        <rect
          x="-0.0942383"
          y="-0.527344"
          width="31"
          height="31"
          rx="15.5"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
export default RoundColombianFLag;
