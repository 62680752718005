const CreditCard = ({ ...props }) => {
  return (
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.87085 23.6135H67.8186M3.87085 26.0577H67.8186M13.709 43.167H33.3852M13.709 50.4996H23.5471M11.2494 60.2763H60.44C62.3969 60.2763 64.2737 59.5038 65.6574 58.1287C67.0412 56.7535 67.8186 54.8885 67.8186 52.9437V18.7251C67.8186 16.7804 67.0412 14.9154 65.6574 13.5402C64.2737 12.1651 62.3969 11.3926 60.44 11.3926H11.2494C9.29251 11.3926 7.41574 12.1651 6.03199 13.5402C4.64823 14.9154 3.87085 16.7804 3.87085 18.7251V52.9437C3.87085 54.8885 4.64823 56.7535 6.03199 58.1287C7.41574 59.5038 9.29251 60.2763 11.2494 60.2763Z"
        stroke={props.color ? props.color : '#7483AB'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreditCard;
