const IconClock = ({ ...props }) => (
  <svg
    width={props.size || '22'}
    height={props.size || '20'}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.15 6V10L14.3 13M20.6 10C20.6 11.1819 20.3556 12.3522 19.8807 13.4442C19.4058 14.5361 18.7097 15.5282 17.8322 16.364C16.9546 17.1997 15.9129 17.8626 14.7664 18.3149C13.6198 18.7672 12.391 19 11.15 19C9.909 19 8.68016 18.7672 7.53364 18.3149C6.38711 17.8626 5.34535 17.1997 4.46784 16.364C3.59032 15.5282 2.89424 14.5361 2.41934 13.4442C1.94443 12.3522 1.7 11.1819 1.7 10C1.7 7.61305 2.69562 5.32387 4.46784 3.63604C6.24006 1.94821 8.6437 1 11.15 1C13.6563 1 16.0599 1.94821 17.8322 3.63604C19.6044 5.32387 20.6 7.61305 20.6 10Z"
      stroke="#060C41"
      strokeOpacity="0.65"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconClock;
