import { useState, useMemo } from 'react';

const DEFAULT_CURRENT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 15;

export const usePagination = (size?: number) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(size || DEFAULT_PAGE_SIZE);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeData = (data: any) => {
    const total = data?.meta?.pagination?.total || data?.pagination?.total
    const pages = Math.ceil(total / pageSize);
    setTotalPages(pages);
  };

  const getOffset = (): number => {
    return currentPage * pageSize;
  };

  const onPaginationChange = (page: number, size?: number): void => {
    setCurrentPage(page);
    if (size) {
      setPageSize(size);
    }
  };

  const pages = useMemo(
    () => Array.from({ length: totalPages }, (_, index) => index),
    [totalPages]
  );

  return {
    totalPages,
    currentPage,
    pageSize,
    setPageSize,
    setCurrentPage,
    onPaginationChange,
    onChangeData,
    getOffset,
    pages,
  };
};
