import { ChangeEvent, DragEvent, FC, useEffect, useRef, useState } from 'react';

import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { Button } from '../Button/Button';
import IconPdf from '../../icons/IconPdf';
import { MAX_FILE_SIZE } from '../../utils/constants';

export interface IErrorFile {
  code: 'MAX_FILE_SIZE' | string;
  message: string;
}
interface UploadFileProps {
  multiply?: false;
  maxFileSize?: number;
  isLoaded?: string | null;
  onFileSelected: (file: File) => void;
  onError: (error: IErrorFile) => void;
  isUploadCancel?: boolean;
  fileUpload?: string;
  isUpload?: boolean;
  customText?: string;
}

export const UploadFile: FC<UploadFileProps> = ({
  multiply = false,
  isLoaded,
  maxFileSize = MAX_FILE_SIZE,
  onFileSelected,
  onError,
  isUploadCancel,
  isUpload,
  customText
}) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null | string>(null);
  const [fileName, setFileName] = useState<string>();

  const errorFile = (file: File) => {
    if (file.size >= maxFileSize) {
      onError({
        code: 'MAX_FILE_SIZE',
        message:
          'El archivo es muy pesado para ser subido, máximo debería ser 10Mb',
      });
    } else {
      onError({ code: '', message: '' });
    }
  };

  const fileSelected = (file: File) => {
    errorFile(file);
    setFile(file);
    onFileSelected(file);
    setFileName(file.name);
  };
  const handleFileSelected = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };
  const handleChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    fileSelected(event.target.files[0]);
  };
  const handleDrag = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!event.dataTransfer.files && !event.dataTransfer.files[0]) {
      return;
    }
    fileSelected(event.dataTransfer.files[0]);
  };

  useEffect(() => {
    if (isLoaded) {
      setFile(isLoaded);
    } else {
      setFile(null);
      setFileName('');
    }
  }, [isLoaded, isUploadCancel, isUpload]);

  return (
    <div
      onDragEnter={handleDrag}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className="relative block mt-14"
    >
      <input
        type="file"
        ref={inputFile}
        hidden={true}
        multiple={multiply}
        accept=".pdf, image/jpeg, image/png"
        id="input-file"
        onChange={handleChangeFile}
      />
      <label
        htmlFor="input-file"
        className="w-full flex lg:flex-row flex-col items-center justify-center lg:justify-between gap-4 rounded-[30px] bg-buydepa-blue-light p-4 block relative"
      >
        <div className="flex lg:flex-row flex-col items-center">
          {!file && (
            <>
              <div className="flex  items-center mx-auto">
                <IconPdf
                  width={35}
                  height={35}
                  color="#3A5AFF"
                  className="block mx-auto mr-4"
                />
              </div>
              <p className="text-blue-600 text-base text-center lg:text-left  font-semibold">
                {customText || 'Adjunta tu comprobante'}{' '}
                <span className="lg:inline-flex hidden text-primary-300 text-sm ml-2">
                  Arrastra y suelta o busca en tu ordenador
                </span>
              </p>
            </>
          )}
          {file && (
            <>
              <IconPdf
                width={35}
                height={35}
                className="fill-blue-600 mx-auto items-center"
              />
              <p className="text-blue-600 text-base font-semibold ml-2">
                Archivo cargado
              </p>
            </>
          )}
        </div>
        <div className="flex justify-center">
          <Button
            type="button"
            variant="border"
            color="primary"
            className="rounded-md"
            onClick={handleFileSelected}
          >
            Seleccionar archivo
          </Button>
        </div>
      </label>
    </div>
  );
};
