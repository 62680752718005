const IconKitchen = (props: any) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Vector"
        d="M13.9999 0.16H14C15.0186 0.16 15.85 0.974309 15.85 2V18C15.85 19.0172 15.0172 19.85 14 19.85H2C0.982843 19.85 0.15 19.0172 0.15 18V2C0.15 1.50935 0.34491 1.03879 0.691852 0.691852C1.03878 0.344927 1.5093 0.150018 1.99993 0.15C1.99995 0.15 1.99998 0.15 2 0.15L13.9999 0.16ZM14 18.15H14.15V18V8.98V8.83H14H2H1.85V8.98V18V18.15H2H14ZM14 7.15H14.15V7V2V1.85H14H2H1.85V2V7V7.15H2H14ZM4.15 3.15H5.85V5.85H4.15V3.15ZM4.15 10.15H5.85V14.85H4.15V10.15Z"
        fill="#F2006E"
        stroke="#F5F5F5"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default IconKitchen;
