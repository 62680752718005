import { useState } from 'react';
import { Spinner } from '../Spinner/Spinner';
import { EmptyState } from '../EmptyState/EmptyState';
import { BuildingOfficeIcon } from '@heroicons/react/20/solid';
import Pagination from '../Pagination/Pagination';
import type { ReactNode } from 'react';

type PaginationT = {
  pageSize: number;
  currentPageChange: (page: number) => void;
  totalCount: number;
};

interface TableListProps {
  columns: { accesor: string; label: string }[];
  pagination: PaginationT | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  title?: string;
  emptyTitle?: string;
  emptySubtitle?: string;
  headerAction?: ReactNode;
  loading?: boolean;
  onClickRow?: (row: any) => void;
}

const TableOverlay = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 opacity-75 z-50">
      <Spinner />
    </div>
  );
};

export function TableList({
  columns,
  pagination,
  data,
  title,
  emptyTitle,
  emptySubtitle,
  headerAction,
  loading,
  onClickRow,
}: TableListProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const currentPageChange = (page: number) => {
    if (pagination) {
      let start = 0;
      let end = pagination.pageSize;
      if (page !== 1) {
        end = pagination.pageSize * page;
        start = end - pagination.pageSize;
      }
      pagination.currentPageChange(start);
      setCurrentPage(page);
    }
  };

  return (
    <div className="relative">
      <div className="flex items-center justify-between mb-6">
        <div>
          <span className="text-xl">{title}</span>
        </div>
        <div>{headerAction}</div>
      </div>

      <table className="relative min-w-full divide-y divide-gray-300 text-center">
        {loading ? (
          <TableOverlay />
        ) : data?.length > 0 ? (
          <>
            <thead className="shadow-md py-4 px-8 rounded-md">
              <tr>
                {columns?.map((column, idx) => (
                  <th
                    key={idx}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-sm font-semibold sm:pl-6"
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((entry: any, idx: number) => (
                <tr
                  key={idx}
                  className={`shadow-md py-4 px-8 rounded-md ${onClickRow && 'cursor-pointer'}`}
                  onClick={() => onClickRow && onClickRow(entry)}
                >
                  {columns?.map((column, idx) => (
                    <td
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-800 sm:pl-6 "
                      key={idx}
                    >
                      {entry[column.accesor]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </>
        ) : (
          <div className="flex items-center justify-center shadow rounded-lg">
            <EmptyState
              icon={<BuildingOfficeIcon width={32} />}
              containerClassName="shadow-none"
              title={emptyTitle || 'Sin datos'}
              subtitle={emptySubtitle}
            />
          </div>
        )}
      </table>
      <div className="pb-10">
        {pagination && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={pagination.totalCount}
            pageSize={pagination.pageSize}
            onPageChange={(page: number) => currentPageChange(page)}
          />
        )}
      </div>
    </div>
  );
}
