import { useState } from 'react';
import { Button } from '../Button/Button';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';

export function Banner(props: any) {
  const { text, link } = props;
  const [isVisible, setIsVisible] = useState(true);

  const handleDismiss = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;
  return (
    <div
      className="relative isolate gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1
        w-full h-full bg-gradient-to-l from-blue-300 to-indigo-500"
    >
      <div className="absolute right-0 items-center pr-8">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={handleDismiss}
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-x-4 gap-y-2 mt-8 my-4 sm:m-0">
        <p className="text-sm text-center leading-6 text-gray-900">
          <strong className="font-semibold text-white">
            {text}
          </strong>
        </p>
        <Link href={link}>
          <Button size="small" color="primary" variant="fill">
            Leer más
          </Button>
        </Link>
      </div>
    </div>
  );
}
