import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useMemo } from 'react';

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

interface BarChartProps {
  data: number[];
}

const MONTH_LABELS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export function BarChart({ data }: BarChartProps) {
  const max = useMemo(
    () => (data?.some((x) => x > 0) ? Math.max(...data) : 100),
    [data]
  );

  const chartData: any = useMemo(
    () => ({
      labels: MONTH_LABELS,
      datasets: [
        {
          data,
          yAxisID: 'A',
          label: 'Ventas',
          backgroundColor: '#3A5AFF73',
          borderRadius: 100,
          barThickness: 30,
          datalabels: {
            color: '#3A5AFF80',
            align: 'end',
            anchor: 'center',
            formatter: (value: number) => value,
          },
        },
        {
          yAxisID: 'B',
          label: 'Max',
          data: new Array(12).fill(max),
          backgroundColor: '#E9E9FF40',
          borderRadius: 100,
          barThickness: 30,
          datalabels: {
            align: 'end',
            anchor: 'start',
          },
        },
      ],
    }),
    [data, max]
  );

  const chartOptions: any = useMemo(
    () => ({
      scales: {
        x: {
          stacked: true,
          display: false,
          grid: {
            display: false,
          },
        },
        A: {
          type: 'linear',
          position: 'left',
          display: false,
          grid: {
            drawOnChartArea: false,
            display: false,
          },
        },
        B: {
          type: 'linear',
          position: 'left',
          ticks: {
            beginAtZero: true,
          },
          display: false,
          grid: {
            drawOnChartArea: false,
            display: false,
          },
        },
      },
      plugins: {
        datalabels: {
          color: '#000',
          display: function (context: {
            dataset: { data: number[] };
            dataIndex: number;
          }) {
            return context.dataset.data[context.dataIndex] > 0;
          },
          font: {
            size: 18,
          },
          formatter: function (value: string, context: any) {
            return context.chart.data.labels[context.dataIndex];
          },
          rotation: 270,
        },
      },
    }),
    []
  );

  return (
    <div className="barChartContainer">
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
}
