import { calculateDateDifferenceInDays } from './date';

const WEB_PRIORITY_WEIGHTS = {
  category: 1000,
  createdAt: 1,
};

const AVAILABLE = 'available';
const RESERVED = 'reserved';

export const calculateWebPriority = ({
  packageState,
  hasImages,
  promotionsCount,
  hasRentPrice,
  hasApartmentOrientation,
  createdAt,
}: {
  packageState: string;
  hasImages: boolean;
  promotionsCount: number;
  hasRentPrice: boolean;
  hasApartmentOrientation: boolean;
  createdAt: string;
}) => {
  let categoryPriority = 0;

  if (
    packageState === AVAILABLE &&
    hasImages &&
    promotionsCount > 0 &&
    hasRentPrice &&
    hasApartmentOrientation
  ) {
    categoryPriority = 1;
  } else if (
    packageState === AVAILABLE &&
    hasImages &&
    (promotionsCount === 0 || !hasRentPrice || !hasApartmentOrientation)
  ) {
    categoryPriority = 2;
  } else if (packageState === AVAILABLE && !hasImages) {
    categoryPriority = 3;
  } else if (packageState === RESERVED) {
    categoryPriority = 4;
  }

  const createdAtDifference =
    calculateDateDifferenceInDays(new Date().toISOString(), createdAt) || 0;
  const createdAtPriority =
    (100 - createdAtDifference / 365) * WEB_PRIORITY_WEIGHTS.createdAt;

  return categoryPriority * WEB_PRIORITY_WEIGHTS.category + createdAtPriority;
};
