import React from 'react';
import type { ReactNode } from 'react';
import { Spinner } from '../Spinner/Spinner';

interface CardProps {
  title?: string;
  children?: ReactNode;
  className?: string;
  loading?: boolean;
}

export function Card({ title, children, className, loading }: CardProps) {
  return (
    <div
      className={`overflow-hidden rounded-lg shadow min-h-[100px] mb-4 ${className}`}
    >
      <div className="bg-[#060C41] flex items-center justify-center text-white w-full uppercase text-sm">
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full z-20 flex items-center justify-center bg-white/80">
            <i className="pl-2 m-0 flex">
              <Spinner size={'large'} />
            </i>
          </div>
        )}
        {title}
      </div>
      <div className="flex bg-white px-4 py-6 h-full w-full">{children}</div>
    </div>
  );
}
