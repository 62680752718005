const StorageIcon = ({ ...props }) => (
  <svg
    width={props.width ? props.width : '24'}
    height={props.height ? props.height : '25'}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.24512 6.13158V18.8684L12.0512 23.5M1.24512 6.13158L6.64817 8.44737M1.24512 6.13158L12.9043 1.5L17.7387 3.81579M12.0512 10.7632L22.2886 6.13158M12.0512 10.7632V23.5M12.0512 10.7632L6.64817 8.44737M22.2886 6.13158V19.4474L12.0512 23.5M22.2886 6.13158L17.7387 3.81579M6.64817 8.44737L17.7387 3.81579"
      stroke={props.color ? props.color : '#7483AB'}
      strokeWidth="1.5"
    />
  </svg>
);

export default StorageIcon;
