import Image, { ImageProps } from 'next/image'
import { useState } from 'react'
import { LoadingSpin } from '../../icons';

interface PictureProps extends ImageProps {
  className?: string
  getSize?: boolean
}
export function Picture ({
  className,
  getSize,
  ...rest
}: PictureProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleLoadingComplete = () => {
    setIsLoading(false);
  };

  const handleLoadingError = () => {
    setIsLoading(false);
    setHasError(true);
  };
  return (
    <>
      {isLoading && 
        <div className="bg-gray-100 w-full absolute z-10 h-full flex justify-center items-center">
          <LoadingSpin className="animate-spin h-5 w-5 text-primary-600"/>
        </div>
      }
      {hasError && <div>Error loading image</div>}
      <Image
        loading="lazy"
        className={className}
        onLoadingComplete={handleLoadingComplete}
        onError={handleLoadingError}
        {...rest}
      />
    </>
  ) 
}

export default Picture