const MexicanFlag = ({ ...props }) => (
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.319458 0.486084H19.3194V12.1312H0.319458V0.486084Z"
      fill="white"
    />
    <path
      d="M0.319458 0.486084H6.75494V12.1312H0.319458V0.486084Z"
      fill="#5C9E31"
    />
    <path
      d="M12.884 0.486084H19.3195V12.1312H12.884V0.486084Z"
      fill="#D22F27"
    />
    <path
      d="M9.81941 7.07485C10.4118 7.07485 10.892 6.59464 10.892 6.00227C10.892 5.4099 10.4118 4.92969 9.81941 4.92969C9.22704 4.92969 8.74683 5.4099 8.74683 6.00227C8.74683 6.59464 9.22704 7.07485 9.81941 7.07485Z"
      fill="#A57939"
      stroke="#A57939"
      strokeWidth="2"
      stroke-miterlimit="10"
    />
    <path
      d="M7.98071 7.37695C8.19404 7.66351 8.47139 7.89625 8.79063 8.05658C9.10988 8.21692 9.46218 8.30042 9.81942 8.30042C10.1767 8.30042 10.529 8.21692 10.8482 8.05658C11.1675 7.89625 11.4448 7.66351 11.6581 7.37695"
      stroke="#5C9E31"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default MexicanFlag;
