const LensBookHex = () => {
  return (
    <svg
      width="199"
      height="212"
      viewBox="0 0 199 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M198.437 71.3827C198.437 69.8954 198.357 68.3928 198.142 66.9209C196.555 55.9749 190.996 47.2177 181.788 40.6081C179.087 38.6703 176.068 37.1242 173.141 35.4859C160.273 28.2748 147.386 21.0892 134.502 13.9012C128.795 10.7167 123.191 7.34282 117.342 4.40923C107.245 -0.654162 96.6675 -1.26597 85.8294 2.10792C81.8873 3.33409 78.3802 5.3871 74.8436 7.38378C57.233 17.3263 39.6332 27.279 21.9958 37.1779C17.7663 39.5509 13.7974 42.1927 10.5347 45.7202C5.06997 51.636 1.80189 58.5246 0.504858 66.2886C-0.00267502 69.3323 0.0188078 72.3939 0.0214932 75.4657C0.0483468 96.4027 0.0188078 117.337 1.03252e-05 138.274C-0.00267503 142.37 0.518284 146.397 1.74549 150.321C4.77458 160.013 10.6555 167.664 19.7938 172.901C28.2366 177.74 66.5835 199.263 77.2148 205.34C83.1306 208.721 89.4278 210.844 96.3345 211.238C105.043 211.737 113.078 209.73 120.46 205.317C121.609 204.615 168.632 177.914 177.905 172.681C185.104 168.621 190.529 162.962 194.229 155.822C197.111 150.262 198.448 144.349 198.448 138.154C198.448 115.896 198.456 93.6381 198.44 71.3801L198.437 71.3827Z"
        fill="#E9E9FF"
        fillOpacity="0.45"
      />
      <path
        d="M110.218 104.434L109.642 105.247L110.366 105.933L121.838 116.799L121.838 116.799C122.672 117.589 122.672 118.842 121.838 119.632L121.838 119.632C121.052 120.377 119.794 120.453 118.912 119.834C118.912 119.834 118.912 119.834 118.911 119.834L118.665 119.632L107.193 108.766L106.542 108.15L105.786 108.631C103.216 110.262 100.118 111.219 96.78 111.219C87.8019 111.219 80.6317 104.34 80.6317 95.9842C80.6317 87.6286 87.8019 80.75 96.78 80.75C105.758 80.75 112.928 87.6286 112.928 95.9842C112.928 99.1061 111.932 102.012 110.218 104.434ZM67.8681 137.85H66.7181V139C66.7181 142.123 69.3725 144.55 72.5135 144.55H134.066C135.414 144.55 136.4 145.571 136.4 146.7C136.4 147.829 135.414 148.85 134.066 148.85H72.5135C66.675 148.85 62.0499 144.382 62.0499 139V73C62.0499 67.618 66.675 63.15 72.5135 63.15H125.936C131.775 63.15 136.4 67.618 136.4 73V135.7C136.4 136.829 135.414 137.85 134.066 137.85H67.8681ZM130.582 133.55H131.732V132.4V73C131.732 69.8768 129.077 67.45 125.936 67.45H72.5135C69.3724 67.45 66.7181 69.8768 66.7181 73V132.4V133.55H67.8681H130.582ZM108.504 95.9842C108.504 89.7595 103.196 84.8184 96.78 84.8184C90.3642 84.8184 85.0554 89.7595 85.0554 95.9842C85.0554 102.209 90.3642 107.15 96.78 107.15C103.196 107.15 108.504 102.209 108.504 95.9842Z"
        fill="#7483AB"
        stroke="#F5F5FF"
        strokeWidth="2.3"
      />
    </svg>
  );
};

export default LensBookHex;
