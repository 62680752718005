const LogoBci = ({...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={123}
    height={57}
    viewBox="0 0 123 57"
    fill="none"
    {...props}
  >
    <path
      fill="#FE1338"
      d="M30.86 30.75c-.07.23-.29.28-.46.38-4.13 2.46-8.2 5-12.08 7.83a19 19 0 0 0-6.36 7.77 10.85 10.85 0 0 0-.74 4.15 5.81 5.81 0 0 1-3.15 5.29c-.557.235-1.128.432-1.71.59H4.72c-.08-.18-.26-.14-.4-.18a6 6 0 0 1-4.26-6.6 12.19 12.19 0 0 1 2.89-5.82 35.822 35.822 0 0 1 8.27-7.26 49.024 49.024 0 0 1 5.29-3.09 70.648 70.648 0 0 0 8.18-4.92c.26-.1.42.09.6.2.632.439 1.301.823 2 1.15a5.65 5.65 0 0 0 2.9.41.7.7 0 0 1 .67.1Z"
    />
    <path
      fill="#000"
      d="M122.949 12.96c-.17.16-.15.4-.22.6a3.925 3.925 0 0 1-3.26 2.75 3.65 3.65 0 0 1-3.68-1.74 4.07 4.07 0 0 1-.19-4.44 3.861 3.861 0 0 1 4-2 4 4 0 0 1 3.24 3.3c.1.17 0 .37.09.53l.02 1Z"
    />
    <path fill="#3B3B3B" d="M122.949 11.93a.564.564 0 0 1-.09-.53h.09v.53Z" />
    <path
      fill="#000"
      d="M85.1 35.63a16.231 16.231 0 0 1 7.18-13.14 14.76 14.76 0 0 1 18.339 1.15c.37.32.71.68 1.09 1 .23.2.23.34 0 .57-1.49 1.63-3 3.28-4.46 4.93-.21.24-.31.18-.5 0a10.296 10.296 0 0 0-2.85-2.14 7.81 7.81 0 0 0-9.62 2.4 10.11 10.11 0 0 0 .8 12.94 7.7 7.7 0 0 0 10.68.45c.28-.23.57-.67.81-.64.24.03.55.46.81.72l4.06 4.11c.18.18.27.29 0 .52a14.675 14.675 0 0 1-11 4.66 14.87 14.87 0 0 1-12.35-6.8c-2.04-2.94-2.98-6.26-2.99-10.73Z"
    />
    <path
      fill="#0072BD"
      d="M30.86 30.75c-.81.105-1.63.105-2.44 0a8.511 8.511 0 0 1-3-1.35l-.78-.48a56.254 56.254 0 0 1-7.67-6.27 33.429 33.429 0 0 1-6.81-9 12.23 12.23 0 0 1-1.4-6.2 7.65 7.65 0 0 1 15.17-1.19 10.84 10.84 0 0 1-.14 4.5 20.61 20.61 0 0 0 0 9 11.86 11.86 0 0 0 4 6.44c.08.066.167.123.26.17a.35.35 0 0 1 .24.06 12.273 12.273 0 0 0 2.93 1.55 2.44 2.44 0 0 1 1 .76.78.78 0 0 1-.16 1.29c-.355.308-.762.552-1.2.72Z"
    />
    <path
      fill="#000"
      d="M122.619 36.19v15.8c0 .47-.13.56-.57.55h-6.19c-.35 0-.46-.09-.46-.45V20.23c0-.39.13-.43.46-.43h6.3c.41 0 .45.14.45.49.007 5.3.01 10.6.01 15.9Z"
    />
    <path
      fill="#FE1338"
      d="M32.149 30.03c.46-.66.41-1-.21-1.52a4.09 4.09 0 0 0-1.13-.6 8.63 8.63 0 0 1-2.68-1.54 30 30 0 0 0 4.38-4.49 12.11 12.11 0 0 0 2.88-7.89 5.69 5.69 0 0 1 5.5-5.74 5.79 5.79 0 0 1 5.64 5 7.56 7.56 0 0 1-.86 4.49 20.288 20.288 0 0 1-4.23 5.55 45.56 45.56 0 0 1-8.92 6.64c-.11.03-.21.15-.37.1Z"
    />
    <path
      fill="#FED400"
      d="M21.27 49.91a9.29 9.29 0 0 1-2.38-.21c-2.49-.59-3.05-1.79-2.54-4.22a5.58 5.58 0 0 1 1.55-2.55 32.334 32.334 0 0 1 5.85-4.7 17.392 17.392 0 0 1 5.64-2.7 5.27 5.27 0 0 1 2.92 0A4.73 4.73 0 0 1 35.46 40c.17 3.23-1.42 5.49-3.94 7.23a15.14 15.14 0 0 1-6.57 2.37 21.08 21.08 0 0 1-3.68.31Z"
    />
    <path
      fill="#00983C"
      d="M46.619 44.21c0 3-1.72 5-4 5.3a4.61 4.61 0 0 1-5-4 10.997 10.997 0 0 1 .27-3 10.88 10.88 0 0 0-.43-5.49 6.551 6.551 0 0 0-3.9-3.94 1.28 1.28 0 0 1-.7-1.85.9.9 0 0 1 1.07-.47 7.319 7.319 0 0 1 1.93.93 30.062 30.062 0 0 1 9.06 8.3 8.5 8.5 0 0 1 1.7 4.22Z"
    />
    <path
      fill="#000"
      d="M82.31 37.3a10.42 10.42 0 0 0-3.82-6.94c-.23-.19-.12-.29 0-.44a10.313 10.313 0 0 0 1.72-2.65 13.07 13.07 0 0 0 .81-7.9 10 10 0 0 0-7-8.26 19.12 19.12 0 0 0-6.1-.87H54.6c-.46 0-.59.1-.59.58V52c0 .43.11.53.53.53h13.78c1.877.03 3.75-.188 5.57-.65 3.64-1 6.38-3 7.73-6.66a15.61 15.61 0 0 0 .69-7.92Zm-21.08-15v-4.19c0-.3.07-.41.39-.4h7.22a6.54 6.54 0 0 1 2 .32 3.43 3.43 0 0 1 2.52 2.73 6.632 6.632 0 0 1 0 2.84 3.81 3.81 0 0 1-3.68 3.2c-2.7.19-5.42 0-8.13.07-.34 0-.28-.21-.28-.4l-.04-4.17Zm13.39 18.78a4.55 4.55 0 0 1-4.23 3.81c-.664.083-1.332.123-2 .12h-6.55c-.52 0-.6-.15-.59-.62v-9.56c0-.32.07-.43.41-.43h7.58c.77.009 1.535.113 2.28.31a4 4 0 0 1 3.1 3.29 7.782 7.782 0 0 1 0 3.08Z"
    />
  </svg>
)
export default LogoBci
