import { createClient } from '@vercel/kv';
import * as Sentry from '@sentry/nextjs';

const kvKey = 'BUYDEPA_PUBLIC_AUTH0_CORE_TOKEN';
const timeLimit = 20 * 60 * 60 * 1000; // 20 hours in milliseconds
const redisUrl = process.env.REDIS_REST_API_URL;
const redisToken = process.env.REDIS_REST_API_TOKEN;

const baseUrl = process.env.CORE_AUTH0_ISSUER_BASE_URL;
const client_id = process.env.CORE_AUTH0_CLIENT_ID;
const client_secret = process.env.CORE_AUTH0_CLIENT_SECRET;
const audience = process.env.CORE_AUTH0_CLIENT_AUDIENCE;
const grant_type = process.env.CORE_AUTH0_GRANT_TYPE;

export async function getM2MToken(): Promise<string | null> {
  try {
    if (!redisUrl || !redisToken)
      throw new Error(
        'Redis credentials are not set in the environment variables.'
      );

    if (!baseUrl || !client_id || !client_secret || !audience)
      throw new Error(
        'Auth0 credentials are not set in the environment variables.'
      );

    const kv = createClient({
      url: redisUrl,
      token: redisToken,
    });

    let cache = await kv.get<{ data: any; timestamp: number }>(kvKey);

    if (cache && Date.now() - cache.timestamp < timeLimit)
      return cache.data.access_token;

    const response = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: client_id,
        client_secret: client_secret,
        audience: audience,
        grant_type: grant_type,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        JSON.stringify(errorData) || `Fetch error: ${response.statusText}`
      );
    }

    const data = await response.json();
    await kv.set(kvKey, { data, timestamp: Date.now() });
    return data.access_token;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    return null;
  }
}
