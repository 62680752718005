export const Settings = () => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19.5" cy="19.5" r="19" fill="white" stroke="#060C41" />
      <path
        d="M18.325 11.317C18.751 9.561 21.249 9.561 21.675 11.317C21.7389 11.5808 21.8642 11.8258 22.0407 12.032C22.2172 12.2382 22.4399 12.3999 22.6907 12.5037C22.9414 12.6076 23.2132 12.6509 23.4838 12.6299C23.7544 12.6089 24.0162 12.5243 24.248 12.383C25.791 11.443 27.558 13.209 26.618 14.753C26.4769 14.9847 26.3924 15.2463 26.3715 15.5168C26.3506 15.7872 26.3938 16.0588 26.4975 16.3094C26.6013 16.56 26.7627 16.7826 26.9687 16.9591C27.1747 17.1355 27.4194 17.2609 27.683 17.325C29.439 17.751 29.439 20.249 27.683 20.675C27.4192 20.7389 27.1742 20.8642 26.968 21.0407C26.7618 21.2172 26.6001 21.4399 26.4963 21.6907C26.3924 21.9414 26.3491 22.2132 26.3701 22.4838C26.3911 22.7544 26.4757 23.0162 26.617 23.248C27.557 24.791 25.791 26.558 24.247 25.618C24.0153 25.4769 23.7537 25.3924 23.4832 25.3715C23.2128 25.3506 22.9412 25.3938 22.6906 25.4975C22.44 25.6013 22.2174 25.7627 22.0409 25.9687C21.8645 26.1747 21.7391 26.4194 21.675 26.683C21.249 28.439 18.751 28.439 18.325 26.683C18.2611 26.4192 18.1358 26.1742 17.9593 25.968C17.7828 25.7618 17.5601 25.6001 17.3093 25.4963C17.0586 25.3924 16.7868 25.3491 16.5162 25.3701C16.2456 25.3911 15.9838 25.4757 15.752 25.617C14.209 26.557 12.442 24.791 13.382 23.247C13.5231 23.0153 13.6076 22.7537 13.6285 22.4832C13.6494 22.2128 13.6062 21.9412 13.5025 21.6906C13.3987 21.44 13.2373 21.2174 13.0313 21.0409C12.8253 20.8645 12.5806 20.7391 12.317 20.675C10.561 20.249 10.561 17.751 12.317 17.325C12.5808 17.2611 12.8258 17.1358 13.032 16.9593C13.2382 16.7828 13.3999 16.5601 13.5037 16.3093C13.6076 16.0586 13.6509 15.7868 13.6299 15.5162C13.6089 15.2456 13.5243 14.9838 13.383 14.752C12.443 13.209 14.209 11.442 15.753 12.382C16.749 12.99 18.049 12.452 18.325 11.317Z"
        stroke="#060C41"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 19C23 19.7956 22.6839 20.5587 22.1213 21.1213C21.5587 21.6839 20.7956 22 20 22C19.2044 22 18.4413 21.6839 17.8787 21.1213C17.3161 20.5587 17 19.7956 17 19C17 18.2044 17.3161 17.4413 17.8787 16.8787C18.4413 16.3161 19.2044 16 20 16C20.7956 16 21.5587 16.3161 22.1213 16.8787C22.6839 17.4413 23 18.2044 23 19Z"
        stroke="#060C41"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
