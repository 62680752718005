import { format, parseISO } from 'date-fns';
import moment from 'moment-timezone';

export const countryToTimeZone: { [key: string]: string } = {
  CL: 'America/Santiago',
  CO: 'America/Bogota'
};

export const formatDate = (dateString?: string, dateFormat?: string) => {
  if (!dateString) return '';
  const date = parseISO(dateString);
  return format(date, dateFormat || 'dd/MM/yyyy HH:mm');
};

export function calculateDateDifferenceInDays(
  dateString1: string,
  dateString2: string
): number | null {
  const oneDayInMilliseconds: number = 1000 * 60 * 60 * 24;
  const date1: Date = new Date(dateString1);
  const date2: Date = new Date(dateString2);

  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) return null;
  const differenceInMilliseconds: number = date2.getTime() - date1.getTime();
  const differenceInDays: number =
    differenceInMilliseconds / oneDayInMilliseconds;
  return differenceInDays;
}

export function fromUTC(utcDate: string, countryCode: string): string | null {
  if (!utcDate || !countryCode) return '';
  const timezone = countryToTimeZone[countryCode];
  if (!timezone) {
    throw new Error(JSON.stringify({ error: 'Invalid country code' }));
  }

  return moment.utc(utcDate).tz(timezone).format('DD/MM/YYYY HH:mm');
}

export const getFormattedDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript comienzan desde 0
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export function toUTC(date: string, countryCode: string): string | null {
  if (!date || !countryCode) return '';
  const timezone = countryToTimeZone[countryCode];
  if (!timezone) {
    throw new Error(JSON.stringify({ error: 'Invalid country code' }));
  }

  const localDate = moment.tz(date, 'YYYY-MM-DD HH:mm', timezone);
  return localDate.utc().toISOString();
}
