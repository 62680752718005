import { useState } from 'react';
import type { ReactNode } from 'react';
import cn from 'classnames';

type BDColors =
  | 'white'
  | 'blue'
  | 'magenta'
  | 'primary'
  | 'lightGray'
  | 'ghostBlue'
  | 'black'
  | 'alert'
  | 'success'
  | 'warning';
interface TooltipProps {
  position?: 'top' | 'bottom' | 'left' | 'right';
  defaultOpen?: boolean;
  color?: BDColors;
  children: ReactNode;
  className?: string;
  title: ReactNode;
}

function Tooltip({
  className = '',
  children,
  title,
  defaultOpen = false,
  color = 'primary',
  position = 'top',
}: TooltipProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const colorToolTip: { [P in BDColors]: string } = {
    white: 'bg-white text-black',
    blue: 'bg-blue-600 text-white',
    magenta:'bg-magenta-600 text-white',
    primary:'bg-primary-600 text-white',
    lightGray: 'bg-light-gray-600 text-white',
    ghostBlue: 'bg-ghost-blue-600 text-primary-600',
    black: 'bg-black-100 text-white',
    alert: '',
    success: '',
    warning: '',
  };

  const rootClassName = cn(
    'absolute p-4 rounded-md text-white text-sm z-50',
    colorToolTip[color],
    {
      'top-full': position === 'bottom',
      'bottom-full': position === 'top',
      'right-full': position === 'right',
      'left-full': position === 'left',
      'transform -translate-x-1/2': position === 'top' || position === 'bottom',
      'transform -translate-y-1/2': position === 'left' || position === 'right',
    },
    className
  );

  return (
    <div
      className="relative z-[100] inline-block "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isOpen && (
        <div
          className={`${rootClassName}`}
        >
          {title}
        </div>
      )}
    </div>
  );
}

export default Tooltip;
