const IconCards = ({ ...props }) => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="11.75"
      y="0.75"
      width="14.5"
      height="19.5"
      rx="2.25"
      stroke={props.color || '#060C41'}
      strokeOpacity="0.85"
      strokeWidth="1.5"
    />
    <rect
      x="0.973835"
      y="6.32459"
      width="14.5"
      height="19.5"
      rx="2.25"
      transform="rotate(-21.6558 0.973835 6.32459)"
      stroke={props.color || '#060C41'}
      strokeOpacity="0.85"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconCards;
