const LogoHmc = ({ ...props }) => (
  <svg
    width="197"
    height="58"
    viewBox="0 0 197 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M87.858 0.574219V18.1859H73.4383V0.574219H61.3639V45.4716H73.4383V27.8604H87.858V45.4716H99.9324V0.574219H87.858Z"
      fill="#939392"
    />
    <path
      d="M140.072 0.660081L131.462 26.9807L123.219 0.574219H105.851V45.4716H117.925V19.1941L125.867 43.9918H135.874L145.882 18.6147V45.4716H157.956V0.574219L140.072 0.660081Z"
      fill="#939392"
    />
    <path
      d="M189.228 37.1746C180.576 37.1746 173.559 31.0612 173.559 23.5318C173.559 16.0024 180.576 9.88893 189.228 9.88893C192.047 9.88893 194.673 10.5327 196.955 11.6695V0.814966C194.695 0.300278 192.349 0 189.895 0C169.578 0 161.206 11.1975 161.206 24.1971C161.206 37.1967 169.6 47.0856 189.895 47.0856C192.026 47.0856 194.093 46.8496 196.072 46.4635V35.8023C194.006 36.682 191.682 37.1751 189.228 37.1751"
      fill="#939392"
    />
    <path
      d="M48.8805 29.0671V45.5846C48.8805 48.5662 46.4487 50.9905 43.4566 50.9905H12.2712C11.7548 50.9905 11.2379 50.9046 10.7648 50.7761L42.6828 18.5349V29.0675H48.8814V7.18727H27.8757V0.644531H12.2712C5.68558 0.644531 0.304565 6.00727 0.304565 12.5927V45.6062C0.304565 52.1916 5.6851 57.5332 12.2712 57.5332H43.4571C50.0644 57.5332 55.4238 52.1705 55.4238 45.6062V29.0886H48.881V29.0671H48.8805ZM6.86853 12.5927C6.86853 9.61105 9.30035 7.18679 12.2924 7.18679H27.8747V13.7295H38.2484L6.86901 45.4129L6.86853 12.5927Z"
      fill="#496785"
    />
    <path
      d="M65.3459 55.2787L66.5081 55.6432C66.3358 56.2869 66.0346 56.7584 65.6255 57.0592C65.2164 57.36 64.7 57.5312 64.0542 57.5312C63.2793 57.5312 62.6335 57.2736 62.1171 56.7373C61.6219 56.2011 61.3639 55.4715 61.3639 54.5496C61.3639 53.5845 61.6223 52.8122 62.1171 52.2759C62.6118 51.7396 63.2793 51.4609 64.1186 51.4609C64.829 51.4609 65.4315 51.6754 65.8618 52.1047C66.1202 52.3622 66.3353 52.7052 66.4643 53.1772L65.2804 53.4559C65.2159 53.1556 65.0653 52.9196 64.8502 52.7268C64.635 52.5551 64.355 52.4692 64.0537 52.4692C63.6234 52.4692 63.2572 52.6193 62.9992 52.9412C62.7196 53.2631 62.5902 53.7562 62.5902 54.4642C62.5902 55.2149 62.7191 55.73 62.9992 56.0514C63.2577 56.3733 63.6234 56.5234 64.0325 56.5234C64.3554 56.5234 64.6134 56.416 64.8502 56.2231C65.087 56.0298 65.2376 55.7084 65.3454 55.2791"
      fill="#496785"
    />
    <path
      d="M89.9241 57.4462H88.6329L88.1165 56.116H85.7704L85.2751 57.4462H84.0267L86.3079 51.5898H87.5563L89.9237 57.4462H89.9241ZM87.7503 55.1293L86.9326 52.9627L86.1361 55.1293H87.7503Z"
      fill="#496785"
    />
    <path
      d="M107.465 57.4462V51.5898H109.38C110.112 51.5898 110.564 51.6114 110.801 51.6757C111.145 51.7616 111.425 51.9544 111.64 52.2547C111.877 52.5549 111.985 52.9411 111.985 53.3915C111.985 53.756 111.92 54.0563 111.791 54.2923C111.662 54.5283 111.49 54.7211 111.296 54.8713C111.103 55.0214 110.887 55.1073 110.672 55.15C110.392 55.2142 109.983 55.2358 109.445 55.2358H108.67V57.4452H107.465L107.465 57.4462ZM108.649 52.5765V54.2496H109.294C109.768 54.2496 110.069 54.228 110.242 54.1638C110.392 54.0995 110.521 54.0136 110.607 53.8851C110.694 53.7565 110.736 53.6064 110.736 53.4347C110.736 53.2202 110.672 53.0485 110.543 52.8984C110.414 52.7698 110.263 52.6624 110.069 52.6408C109.919 52.6192 109.639 52.5981 109.208 52.5981H108.649V52.5765Z"
      fill="#496785"
    />
    <path
      d="M130.687 51.5898H129.503V57.4462H130.687V51.5898Z"
      fill="#496785"
    />
    <path
      d="M149.95 57.4457V52.5765H148.207V51.5898H152.898V52.5765H151.155V57.4457H149.95Z"
      fill="#496785"
    />
    <path
      d="M176.315 57.4462H175.024L174.508 56.116H172.162L171.666 57.4462H170.396L172.678 51.5898H173.926L176.315 57.4462H176.315ZM174.142 55.1293L173.324 52.9627L172.527 55.1293H174.142Z"
      fill="#496785"
    />
    <path
      d="M190.369 57.446V51.6328H191.574V56.4593H194.523V57.446H190.369Z"
      fill="#496785"
    />
  </svg>
);
export default LogoHmc;
