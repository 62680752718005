interface ProgressBarProps {
  progress: number;
}

function ProgressBar({ progress }: ProgressBarProps) {
  const progressWidth = Math.max(0, Math.min(100, progress));

  return (
    <div className="w-full h-4 bg-blue-100 rounded-full">
      <div
        className="h-full bg-blue-500 rounded-full transition-all duration-500"
        style={{ width: `${progressWidth}%` }}
      />
    </div>
  );
}

export default ProgressBar;
