const ChileanFlag = ({ ...props }) => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1009_4194)">
      <mask
        id="mask0_1009_4194"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="14"
      >
        <path d="M0 0H19.0009V14H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1009_4194)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.125 0H21.375V7H7.125V0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H7.125V7H0V0Z"
          fill="#0039A6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.67021 5.2418L3.56807 4.42969L2.4687 5.25L2.87783 3.91562L1.78125 3.09258L3.13667 3.07891L3.55693 1.75L3.98833 3.07617L5.34375 3.07891L4.25273 3.91016L4.67021 5.2418Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 7H21.375V14H0V7Z"
          fill="#D52B1E"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1009_4194">
        <rect width="19" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ChileanFlag;
