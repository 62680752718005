const LogoEmpresasB = ({ ...props }) => (
  <svg
    version="1.1"
    id="svg2"
    width={180}
    height={170}
    viewBox="0 0 639.64398 941.81866"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs6">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath16">
        <path d="M 0,706.364 H 479.733 V 0 H 0 Z" id="path14" />
      </clipPath>
    </defs>
    <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,941.81867)">
      <g id="g10">
        <g id="g12" clipPath="url(#clipPath16)">
          <g id="g18" transform="translate(193.8706,299.3625)">
            <path
              d="m 0,0 h 65.624 c 22.595,0 36.937,13.763 36.937,35.231 0,25.607 -20.675,32.765 -42.737,32.765 L 0,67.996 Z m 0,90.018 h 52.399 c 29.757,0 41.898,10.735 41.898,31.387 0,27.257 -19.288,31.103 -41.898,31.103 H 0 Z m -26.171,84.514 h 71.386 c 17.374,0 40.251,0 52.104,-7.156 12.956,-7.709 23.146,-21.75 23.146,-41.025 0,-20.918 -11.013,-36.609 -29.21,-44.037 v -0.555 c 24.267,-4.95 37.486,-23.124 37.486,-47.355 0,-28.894 -20.677,-56.429 -59.813,-56.429 h -95.099 z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path20"
            />
          </g>
          <g id="g22" transform="translate(239.8486,547.8322)">
            <path
              d="m 0,0 c -95.22,0 -172.422,-77.103 -172.422,-172.215 0,-95.105 77.202,-172.214 172.422,-172.214 95.251,0 172.455,77.109 172.455,172.214 C 172.455,-77.103 95.251,0 0,0 M 149.716,-172.297 C 149.716,-254.875 82.7,-321.813 0,-321.813 c -82.668,0 -149.688,66.938 -149.688,149.516 0,82.566 67.02,149.493 149.688,149.493 82.7,0 149.716,-66.927 149.716,-149.493"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path24"
            />
          </g>
          <path
            d="M 411.948,144.484 H 67.784 v 22.595 h 344.164 z"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id="path26"
          />
          <g id="g28" transform="translate(391.8428,195.3752)">
            <path
              d="m 0,0 c 0.552,1.235 1.299,2.282 2.231,3.227 0.939,0.923 2.046,1.61 3.304,2.146 1.243,0.53 2.597,0.77 3.974,0.77 1.432,0 2.759,-0.24 4.03,-0.77 C 14.765,4.837 15.848,4.15 16.77,3.227 17.701,2.282 18.474,1.235 19,0 c 0.571,-1.252 0.829,-2.611 0.829,-4.071 0,-1.477 -0.258,-2.83 -0.829,-4.066 -0.526,-1.271 -1.299,-2.344 -2.23,-3.268 -0.922,-0.95 -2.005,-1.642 -3.231,-2.171 -1.271,-0.508 -2.598,-0.759 -4.03,-0.759 -1.377,0 -2.731,0.251 -3.974,0.759 -1.258,0.529 -2.365,1.221 -3.304,2.171 -0.932,0.924 -1.679,1.997 -2.231,3.268 -0.576,1.236 -0.804,2.589 -0.804,4.066 0,1.46 0.228,2.819 0.804,4.071 m 1.703,-7.53 c 0.402,-1.088 0.995,-2.021 1.772,-2.807 0.744,-0.781 1.646,-1.405 2.695,-1.857 1.016,-0.444 2.157,-0.645 3.339,-0.645 1.211,0 2.331,0.201 3.339,0.645 1.023,0.452 1.917,1.076 2.697,1.857 0.735,0.786 1.343,1.719 1.762,2.807 0.442,1.055 0.664,2.225 0.664,3.459 0,1.258 -0.222,2.379 -0.664,3.445 C 16.888,0.449 16.28,1.361 15.545,2.147 14.765,2.916 13.871,3.526 12.848,3.979 11.84,4.41 10.72,4.651 9.509,4.651 8.327,4.651 7.186,4.41 6.17,3.979 5.121,3.526 4.219,2.916 3.475,2.147 2.698,1.361 2.105,0.449 1.703,-0.626 1.261,-1.692 1.051,-2.813 1.051,-4.071 c 0,-1.234 0.21,-2.404 0.652,-3.459 m 8.461,9.435 c 1.433,0 2.48,-0.285 3.167,-0.84 0.693,-0.585 1.05,-1.431 1.05,-2.586 0,-1.09 -0.323,-1.86 -0.908,-2.385 -0.625,-0.493 -1.366,-0.795 -2.283,-0.898 l 3.442,-5.274 h -1.988 l -3.287,5.162 h -1.97 v -5.162 H 5.497 V 1.905 Z M 9.34,-3.425 c 0.424,0 0.824,0.023 1.202,0.044 0.349,0.019 0.708,0.1 0.991,0.226 0.307,0.149 0.539,0.342 0.706,0.595 0.18,0.28 0.248,0.629 0.248,1.119 0,0.401 -0.068,0.736 -0.228,0.948 -0.152,0.255 -0.361,0.424 -0.608,0.546 -0.253,0.145 -0.513,0.229 -0.844,0.289 -0.321,0.017 -0.624,0.053 -0.95,0.053 h -2.47 v -3.82 z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path30"
            />
          </g>
          <g id="g32" transform="translate(113.3155,638.5797)">
            <path
              d="m 0,0 v -11.1 h -31.703 v -12.869 h 29.1 v -10.256 h -29.1 V -48.946 H 0.675 V -60.045 H -44.91 V 0 Z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path34"
            />
          </g>
          <g id="g36" transform="translate(133.2486,622.0128)">
            <path
              d="m 0,0 v -5.889 h 0.163 c 1.574,2.247 3.466,3.978 5.681,5.214 2.219,1.238 4.749,1.856 7.61,1.856 2.745,0 5.254,-0.535 7.525,-1.606 2.271,-1.062 4.001,-2.937 5.171,-5.63 1.295,1.9 3.04,3.589 5.255,5.047 2.218,1.458 4.843,2.189 7.864,2.189 2.298,0 4.428,-0.282 6.396,-0.848 1.959,-0.56 3.639,-1.456 5.041,-2.691 1.404,-1.227 2.497,-2.841 3.285,-4.828 0.78,-1.992 1.174,-4.392 1.174,-7.196 V -43.477 H 43.224 v 24.639 c 0,1.459 -0.057,2.831 -0.167,4.116 -0.113,1.297 -0.423,2.417 -0.924,3.37 -0.507,0.952 -1.25,1.708 -2.233,2.269 -0.983,0.564 -2.306,0.844 -3.994,0.844 -1.684,0 -3.042,-0.321 -4.076,-0.973 -1.039,-0.643 -1.853,-1.486 -2.443,-2.519 -0.586,-1.039 -0.98,-2.215 -1.18,-3.532 -0.192,-1.317 -0.289,-2.652 -0.289,-3.993 V -43.477 H 15.973 v 24.387 c 0,1.289 -0.029,2.563 -0.079,3.827 -0.059,1.26 -0.296,2.422 -0.713,3.489 -0.425,1.06 -1.126,1.919 -2.104,2.562 -0.981,0.652 -2.431,0.973 -4.335,0.973 -0.561,0 -1.305,-0.125 -2.225,-0.381 C 5.588,-8.872 4.691,-9.348 3.829,-10.048 2.954,-10.751 2.212,-11.761 1.596,-13.08 0.978,-14.4 0.674,-16.121 0.674,-18.25 V -43.477 H -11.272 V 0 Z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path38"
            />
          </g>
          <g id="g40" transform="translate(223.021,587.5358)">
            <path
              d="m 0,0 c 1.317,0.782 2.384,1.808 3.198,3.065 0.811,1.26 1.387,2.737 1.724,4.415 0.329,1.679 0.505,3.396 0.505,5.13 0,1.74 -0.182,3.453 -0.551,5.131 -0.368,1.681 -0.962,3.187 -1.806,4.502 -0.844,1.311 -1.919,2.38 -3.236,3.198 -1.319,0.808 -2.931,1.214 -4.838,1.214 -1.962,0 -3.603,-0.406 -4.918,-1.214 -1.32,-0.818 -2.389,-1.869 -3.198,-3.164 -0.812,-1.288 -1.387,-2.771 -1.723,-4.45 -0.335,-1.684 -0.51,-3.423 -0.51,-5.217 0,-1.734 0.19,-3.451 0.552,-5.13 0.367,-1.678 0.949,-3.155 1.766,-4.415 0.809,-1.257 1.893,-2.283 3.238,-3.065 1.348,-0.789 2.974,-1.179 4.876,-1.179 1.964,0 3.605,0.39 4.921,1.179 m -15.516,34.477 v -5.55 h 0.163 c 1.466,2.356 3.312,4.063 5.556,5.132 2.245,1.064 4.712,1.599 7.401,1.599 3.421,0 6.364,-0.656 8.829,-1.933 2.463,-1.297 4.513,-3.005 6.143,-5.13 1.623,-2.137 2.829,-4.617 3.62,-7.447 0.78,-2.83 1.17,-5.789 1.17,-8.877 0,-2.915 -0.39,-5.714 -1.17,-8.404 -0.791,-2.69 -1.983,-5.075 -3.582,-7.152 -1.596,-2.073 -3.582,-3.733 -5.968,-4.96 -2.382,-1.237 -5.177,-1.851 -8.367,-1.851 -2.689,0 -5.172,0.545 -7.448,1.642 -2.27,1.094 -4.133,2.703 -5.593,4.834 h -0.163 v -20.606 h -11.944 v 58.703 z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path42"
            />
          </g>
          <g id="g44" transform="translate(257.2061,622.0128)">
            <path
              d="m 0,0 v -8.072 h 0.169 c 0.558,1.345 1.312,2.591 2.271,3.746 0.95,1.145 2.044,2.126 3.275,2.937 1.236,0.811 2.556,1.443 3.955,1.891 1.4,0.452 2.862,0.679 4.371,0.679 0.79,0 1.657,-0.146 2.606,-0.423 v -11.106 c -0.559,0.114 -1.233,0.215 -2.015,0.3 -0.787,0.08 -1.542,0.126 -2.271,0.126 -2.186,0 -4.04,-0.366 -5.549,-1.096 -1.516,-0.731 -2.734,-1.721 -3.658,-2.985 -0.929,-1.26 -1.588,-2.734 -1.976,-4.412 -0.392,-1.68 -0.588,-3.51 -0.588,-5.466 V -43.477 H -11.353 V 0 Z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path46"
            />
          </g>
          <g id="g48" transform="translate(303.336,611.8361)">
            <path
              d="m 0,0 c -1.434,1.572 -3.605,2.354 -6.518,2.354 -1.911,0 -3.49,-0.32 -4.753,-0.97 -1.263,-0.643 -2.275,-1.442 -3.026,-2.396 -0.756,-0.951 -1.294,-1.965 -1.598,-3.022 -0.314,-1.069 -0.493,-2.023 -0.545,-2.86 H 2.984 C 2.42,-3.866 1.43,-1.57 0,0 m -13.5,-22.872 c 1.793,-1.738 4.373,-2.607 7.733,-2.607 2.413,0 4.486,0.597 6.224,1.806 1.74,1.211 2.803,2.476 3.199,3.825 h 10.515 c -1.686,-5.215 -4.267,-8.942 -7.74,-11.181 -3.476,-2.246 -7.686,-3.361 -12.615,-3.361 -3.419,0 -6.505,0.538 -9.252,1.638 -2.748,1.092 -5.07,2.642 -6.977,4.665 -1.91,2.017 -3.381,4.425 -4.418,7.231 -1.034,2.801 -1.558,5.884 -1.558,9.253 0,3.25 0.534,6.278 1.6,9.082 1.065,2.798 2.575,5.226 4.544,7.279 1.958,2.037 4.305,3.658 7.023,4.836 2.716,1.167 5.732,1.762 9.038,1.762 3.703,0 6.923,-0.718 9.671,-2.15 2.746,-1.426 5.006,-3.353 6.771,-5.751 1.767,-2.418 3.041,-5.165 3.83,-8.25 0.781,-3.08 1.058,-6.309 0.838,-9.667 H -16.44 c 0.162,-3.874 1.142,-6.674 2.94,-8.41"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path50"
            />
          </g>
          <g id="g52" transform="translate(335.3731,589.3434)">
            <path
              d="m 0,0 c 0.532,-0.922 1.223,-1.684 2.063,-2.276 0.835,-0.591 1.804,-1.019 2.901,-1.302 1.09,-0.278 2.223,-0.424 3.399,-0.424 0.846,0 1.73,0.1 2.654,0.298 0.927,0.196 1.763,0.511 2.521,0.923 0.758,0.422 1.388,0.982 1.893,1.688 0.507,0.696 0.758,1.585 0.758,2.649 0,1.793 -1.196,3.132 -3.573,4.033 -2.383,0.89 -5.706,1.791 -9.966,2.688 -1.745,0.399 -3.439,0.861 -5.092,1.39 -1.652,0.535 -3.125,1.233 -4.412,2.104 -1.292,0.867 -2.327,1.966 -3.116,3.276 -0.784,1.325 -1.174,2.938 -1.174,4.838 0,2.801 0.55,5.103 1.636,6.896 1.097,1.796 2.544,3.212 4.337,4.248 1.791,1.034 3.806,1.766 6.053,2.187 2.241,0.423 4.541,0.629 6.894,0.629 2.36,0 4.641,-0.227 6.858,-0.67 2.211,-0.452 4.188,-1.207 5.928,-2.271 1.734,-1.061 3.178,-2.477 4.331,-4.245 1.146,-1.765 1.831,-3.998 2.062,-6.687 H 15.6 c -0.169,2.298 -1.039,3.854 -2.608,4.665 -1.57,0.818 -3.418,1.218 -5.551,1.218 -0.672,0 -1.399,-0.039 -2.185,-0.122 C 4.473,25.649 3.756,25.466 3.114,25.187 2.464,24.901 1.921,24.498 1.471,23.964 1.023,23.435 0.8,22.715 0.8,21.825 c 0,-1.068 0.392,-1.94 1.176,-2.608 0.784,-0.677 1.808,-1.222 3.068,-1.644 1.26,-0.416 2.708,-0.8 4.331,-1.137 1.627,-0.331 3.278,-0.693 4.96,-1.094 1.741,-0.387 3.435,-0.87 5.094,-1.427 1.65,-0.564 3.123,-1.3 4.416,-2.231 1.282,-0.921 2.322,-2.078 3.11,-3.443 0.779,-1.382 1.175,-3.07 1.175,-5.087 0,-2.861 -0.572,-5.264 -1.722,-7.193 -1.148,-1.932 -2.647,-3.487 -4.501,-4.671 -1.851,-1.17 -3.967,-2.002 -6.348,-2.475 -2.389,-0.483 -4.805,-0.712 -7.277,-0.712 -2.522,0 -4.987,0.251 -7.4,0.75 -2.412,0.509 -4.555,1.345 -6.431,2.524 -1.88,1.178 -3.42,2.733 -4.629,4.668 -1.205,1.931 -1.865,4.358 -1.978,7.274 H -0.798 C -0.798,2.03 -0.535,0.924 0,0"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path54"
            />
          </g>
          <g id="g56" transform="translate(395.6709,598.757)">
            <path
              d="m 0,0 c -0.758,-0.25 -1.57,-0.458 -2.438,-0.624 -0.872,-0.173 -1.782,-0.315 -2.737,-0.427 -0.947,-0.112 -1.902,-0.251 -2.856,-0.412 -0.898,-0.173 -1.779,-0.401 -2.648,-0.673 -0.874,-0.286 -1.627,-0.668 -2.273,-1.141 -0.642,-0.477 -1.16,-1.083 -1.555,-1.805 -0.39,-0.729 -0.589,-1.661 -0.589,-2.782 0,-1.057 0.199,-1.957 0.589,-2.684 0.395,-0.733 0.928,-1.308 1.597,-1.726 0.671,-0.421 1.458,-0.717 2.355,-0.883 0.893,-0.173 1.823,-0.256 2.776,-0.256 2.354,0 4.176,0.399 5.467,1.178 1.288,0.783 2.242,1.728 2.86,2.823 0.616,1.083 0.994,2.191 1.134,3.313 0.135,1.121 0.21,2.024 0.21,2.694 V 1.053 C 1.386,0.608 0.752,0.254 0,0 m -23.591,16.871 c 1.23,1.847 2.802,3.328 4.712,4.455 1.902,1.116 4.046,1.914 6.432,2.391 2.383,0.48 4.781,0.72 7.194,0.72 2.183,0 4.397,-0.157 6.639,-0.466 2.246,-0.309 4.288,-0.913 6.138,-1.808 1.852,-0.901 3.364,-2.147 4.543,-3.745 1.182,-1.599 1.768,-3.711 1.768,-6.352 v -22.614 c 0,-1.965 0.111,-3.844 0.334,-5.642 0.226,-1.789 0.615,-3.138 1.181,-4.032 H 3.238 c -0.225,0.673 -0.406,1.356 -0.544,2.061 -0.144,0.704 -0.244,1.413 -0.299,2.143 -1.908,-1.961 -4.149,-3.335 -6.726,-4.12 -2.578,-0.787 -5.212,-1.179 -7.908,-1.179 -2.074,0 -4.006,0.251 -5.803,0.756 -1.794,0.504 -3.364,1.294 -4.706,2.361 -1.348,1.061 -2.4,2.408 -3.152,4.035 -0.758,1.625 -1.136,3.555 -1.136,5.804 0,2.461 0.431,4.495 1.301,6.093 0.87,1.593 1.99,2.876 3.366,3.824 1.375,0.951 2.937,1.668 4.708,2.149 1.766,0.477 3.549,0.856 5.34,1.134 1.796,0.276 3.56,0.503 5.299,0.672 1.734,0.166 3.278,0.419 4.626,0.758 1.345,0.333 2.408,0.829 3.194,1.466 0.782,0.65 1.148,1.583 1.094,2.822 0,1.29 -0.21,2.317 -0.63,3.07 -0.416,0.758 -0.984,1.347 -1.687,1.767 -0.695,0.418 -1.513,0.7 -2.436,0.841 -0.924,0.141 -1.923,0.208 -2.983,0.208 -2.356,0 -4.209,-0.503 -5.55,-1.511 -1.345,-1.008 -2.129,-2.693 -2.357,-5.049 h -11.942 c 0.167,2.804 0.867,5.132 2.102,6.988"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path58"
            />
          </g>
          <g id="g60" transform="translate(101.3633,104.9923)">
            <path
              d="m 0,0 c -0.645,1.035 -1.441,1.931 -2.403,2.711 -0.965,0.776 -2.051,1.393 -3.265,1.823 -1.214,0.439 -2.485,0.655 -3.811,0.655 -2.431,0 -4.492,-0.473 -6.187,-1.403 -1.695,-0.945 -3.07,-2.201 -4.118,-3.786 -1.06,-1.58 -1.828,-3.382 -2.31,-5.391 -0.481,-2.016 -0.718,-4.108 -0.718,-6.262 0,-2.051 0.237,-4.064 0.718,-6.002 0.482,-1.954 1.25,-3.71 2.31,-5.265 1.048,-1.561 2.423,-2.806 4.118,-3.743 1.695,-0.938 3.756,-1.413 6.187,-1.413 3.294,0 5.874,1.014 7.729,3.027 1.853,2.01 2.99,4.67 3.4,7.971 h 10.448 c -0.279,-3.067 -0.986,-5.842 -2.133,-8.316 -1.147,-2.474 -2.66,-4.583 -4.535,-6.319 -1.882,-1.743 -4.082,-3.072 -6.6,-3.985 -2.514,-0.911 -5.285,-1.376 -8.309,-1.376 -3.76,0 -7.139,0.653 -10.142,1.956 -3,1.309 -5.534,3.106 -7.591,5.405 -2.062,2.283 -3.642,4.972 -4.742,8.067 -1.097,3.091 -1.65,6.422 -1.65,9.993 0,3.671 0.553,7.07 1.65,10.212 1.1,3.135 2.68,5.871 4.742,8.207 2.057,2.339 4.591,4.165 7.591,5.495 3.003,1.329 6.382,1.991 10.142,1.991 2.702,0 5.252,-0.385 7.661,-1.158 C 0.587,12.311 2.733,11.175 4.638,9.691 6.541,8.197 8.109,6.355 9.341,4.152 10.586,1.959 11.363,-0.557 11.68,-3.402 H 1.24 C 1.056,-2.165 0.639,-1.027 0,0"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path62"
            />
          </g>
          <g id="g64" transform="translate(139.8775,96.2296)">
            <path
              d="m 0,0 c -1.164,1.286 -2.94,1.924 -5.327,1.924 -1.553,0 -2.847,-0.26 -3.879,-0.79 -1.028,-0.53 -1.856,-1.178 -2.472,-1.96 -0.619,-0.775 -1.056,-1.603 -1.307,-2.468 -0.253,-0.871 -0.403,-1.652 -0.445,-2.342 H 2.444 C 1.98,-3.167 1.167,-1.284 0,0 m -11.027,-18.688 c 1.465,-1.422 3.575,-2.135 6.321,-2.135 1.97,0 3.666,0.501 5.084,1.479 1.424,0.989 2.294,2.029 2.611,3.126 h 8.591 c -1.372,-4.26 -3.484,-7.302 -6.317,-9.14 -2.848,-1.83 -6.281,-2.747 -10.31,-2.747 -2.795,0 -5.314,0.447 -7.563,1.338 -2.246,0.899 -4.144,2.166 -5.699,3.817 -1.566,1.648 -2.758,3.618 -3.609,5.908 -0.85,2.291 -1.269,4.815 -1.269,7.561 0,2.654 0.429,5.132 1.306,7.412 0.867,2.298 2.103,4.276 3.709,5.951 1.604,1.67 3.514,2.995 5.736,3.95 2.218,0.967 4.686,1.442 7.389,1.442 3.018,0 5.653,-0.582 7.9,-1.746 2.245,-1.174 4.09,-2.742 5.531,-4.709 1.447,-1.969 2.487,-4.218 3.127,-6.734 0.639,-2.52 0.874,-5.159 0.693,-7.9 H -13.43 c 0.135,-3.163 0.934,-5.453 2.403,-6.873"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path66"
            />
          </g>
          <g id="g68" transform="translate(164.374,104.5451)">
            <path
              d="m 0,0 v -6.597 h 0.143 c 0.457,1.101 1.075,2.119 1.855,3.056 0.776,0.942 1.673,1.746 2.681,2.408 1.002,0.657 2.083,1.185 3.227,1.542 1.148,0.368 2.338,0.549 3.572,0.549 0.643,0 1.352,-0.109 2.13,-0.336 v -9.07 c -0.462,0.088 -1.006,0.169 -1.646,0.239 -0.642,0.068 -1.263,0.097 -1.86,0.097 -1.786,0 -3.297,-0.297 -4.529,-0.89 -1.243,-0.599 -2.236,-1.405 -2.992,-2.439 -0.758,-1.03 -1.292,-2.236 -1.618,-3.609 -0.32,-1.377 -0.478,-2.864 -0.478,-4.465 v -16.018 h -9.76 l 0,35.533 z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path70"
            />
          </g>
          <g id="g72" transform="translate(204.0005,104.5451)">
            <path
              d="m 0,0 v -6.525 h -7.146 v -17.6 c 0,-1.643 0.276,-2.739 0.826,-3.296 0.547,-0.55 1.646,-0.819 3.299,-0.819 0.548,0 1.073,0.018 1.58,0.068 0.504,0.044 0.978,0.109 1.441,0.201 v -7.562 c -0.825,-0.13 -1.741,-0.219 -2.749,-0.268 -1.007,-0.043 -1.994,-0.071 -2.951,-0.071 -1.514,0 -2.945,0.106 -4.298,0.31 -1.351,0.206 -2.541,0.607 -3.574,1.199 -1.028,0.602 -1.844,1.455 -2.435,2.551 -0.6,1.095 -0.899,2.546 -0.899,4.323 v 20.964 h -5.906 V 0 h 5.906 v 10.65 h 9.76 l 0,-10.65 z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path74"
            />
          </g>
          <path
            d="m 208.952,118.084 h 9.751 v -8.047 h -9.751 z"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id="path76"
          />
          <path
            d="m 218.707,69.023 h -9.753 v 35.524 h 9.753 z"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id="path78"
          />
          <g id="g80" transform="translate(222.6924,98.0207)">
            <path
              d="m 0,0 v 6.524 h 5.838 v 2.749 c 0,3.162 0.987,5.749 2.955,7.764 1.974,2.017 4.944,3.03 8.933,3.03 0.868,0 1.744,-0.042 2.612,-0.109 0.868,-0.07 1.717,-0.122 2.543,-0.168 v -7.287 c -1.148,0.141 -2.339,0.204 -3.571,0.204 -1.331,0 -2.286,-0.304 -2.85,-0.923 -0.576,-0.621 -0.862,-1.666 -0.862,-3.128 V 6.524 h 6.731 V 0 H 15.598 V -28.998 H 5.838 L 5.838,0 Z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path82"
            />
          </g>
          <path
            d="m 249.56,118.084 h 9.755 v -8.047 h -9.755 z"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id="path84"
          />
          <path
            d="m 259.315,69.023 h -9.755 v 35.524 h 9.755 z"
            style={{
              fill: '#1d1d1b',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            id="path86"
          />
          <g id="g88" transform="translate(281.2364,98.1584)">
            <path
              d="m 0,0 c -1.558,0 -2.862,-0.363 -3.911,-1.069 -1.058,-0.706 -1.916,-1.628 -2.579,-2.745 -0.666,-1.126 -1.137,-2.365 -1.412,-3.711 -0.275,-1.358 -0.414,-2.694 -0.414,-4.025 0,-1.288 0.139,-2.584 0.414,-3.918 0.275,-1.329 0.723,-2.534 1.34,-3.601 0.615,-1.079 1.449,-1.957 2.51,-2.653 1.054,-0.684 2.331,-1.03 3.846,-1.03 2.336,0 4.136,0.656 5.395,1.959 1.258,1.306 2.048,3.056 2.37,5.26 h 9.416 c -0.645,-4.722 -2.471,-8.312 -5.496,-10.786 -3.029,-2.475 -6.9,-3.715 -11.615,-3.715 -2.663,0 -5.102,0.447 -7.322,1.34 -2.223,0.897 -4.11,2.14 -5.664,3.748 -1.56,1.599 -2.772,3.518 -3.649,5.738 -0.867,2.217 -1.303,4.66 -1.303,7.319 0,2.743 0.4,5.303 1.202,7.657 0.803,2.359 1.979,4.403 3.538,6.118 1.556,1.714 3.463,3.063 5.707,4.017 2.247,0.967 4.809,1.443 7.694,1.443 2.111,0 4.138,-0.269 6.079,-0.825 1.951,-0.55 3.69,-1.382 5.227,-2.507 1.53,-1.12 2.784,-2.516 3.743,-4.194 0.959,-1.671 1.51,-3.65 1.65,-5.937 H 7.215 C 6.571,-2.042 4.17,0 0,0"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path90"
            />
          </g>
          <g id="g92" transform="translate(322.9092,85.5441)">
            <path
              d="M 0,0 C -0.618,-0.205 -1.279,-0.375 -1.989,-0.515 -2.7,-0.656 -3.445,-0.763 -4.223,-0.854 -5.002,-0.952 -5.781,-1.065 -6.56,-1.199 -7.293,-1.34 -8.013,-1.527 -8.721,-1.753 c -0.712,-0.228 -1.335,-0.54 -1.862,-0.93 -0.521,-0.385 -0.947,-0.876 -1.271,-1.47 -0.318,-0.596 -0.48,-1.354 -0.48,-2.274 0,-0.868 0.162,-1.605 0.48,-2.194 0.324,-0.6 0.757,-1.067 1.307,-1.409 0.548,-0.343 1.193,-0.585 1.928,-0.722 0.73,-0.139 1.488,-0.203 2.265,-0.203 1.924,0 3.412,0.317 4.467,0.957 1.053,0.644 1.829,1.414 2.34,2.302 0.501,0.89 0.812,1.795 0.927,2.717 0.108,0.913 0.169,1.646 0.169,2.197 V 0.864 C 1.137,0.49 0.618,0.205 0,0 m -19.271,13.779 c 1.004,1.515 2.288,2.72 3.848,3.643 1.551,0.917 3.307,1.566 5.254,1.956 1.95,0.393 3.908,0.58 5.873,0.58 1.791,0 3.6,-0.121 5.433,-0.375 1.832,-0.25 3.504,-0.743 5.013,-1.471 1.519,-0.733 2.75,-1.758 3.714,-3.059 0.958,-1.311 1.443,-3.039 1.443,-5.194 v -18.48 c 0,-1.604 0.088,-3.143 0.273,-4.611 0.189,-1.461 0.504,-2.562 0.965,-3.289 H 2.648 c -0.182,0.549 -0.334,1.107 -0.443,1.681 -0.123,0.569 -0.196,1.151 -0.241,1.753 -1.562,-1.606 -3.394,-2.731 -5.501,-3.373 -2.106,-0.638 -4.259,-0.96 -6.462,-0.96 -1.693,0 -3.272,0.202 -4.739,0.621 -1.466,0.412 -2.747,1.051 -3.85,1.931 -1.097,0.86 -1.953,1.961 -2.576,3.291 -0.614,1.324 -0.925,2.907 -0.925,4.741 0,2.016 0.354,3.673 1.064,4.977 0.713,1.311 1.629,2.349 2.75,3.133 1.123,0.781 2.402,1.359 3.84,1.751 1.454,0.383 2.905,0.697 4.371,0.929 1.47,0.233 2.908,0.413 4.327,0.55 1.424,0.138 2.682,0.341 3.779,0.617 1.103,0.272 1.974,0.674 2.609,1.206 0.649,0.526 0.948,1.292 0.898,2.291 0,1.058 -0.169,1.897 -0.515,2.514 -0.343,0.619 -0.806,1.107 -1.374,1.44 -0.575,0.345 -1.238,0.578 -1.995,0.693 -0.755,0.111 -1.563,0.168 -2.436,0.168 -1.928,0 -3.439,-0.408 -4.542,-1.233 -1.097,-0.823 -1.737,-2.202 -1.923,-4.124 h -9.756 c 0.139,2.295 0.713,4.193 1.721,5.703"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path94"
            />
          </g>
          <g id="g96" transform="translate(363.8379,91.1125)">
            <path
              d="m 0,0 c -0.277,1.352 -0.747,2.54 -1.414,3.574 -0.662,1.029 -1.523,1.865 -2.576,2.513 -1.051,0.632 -2.383,0.959 -3.988,0.959 -1.601,0 -2.952,-0.327 -4.051,-0.959 -1.103,-0.648 -1.982,-1.494 -2.648,-2.546 -0.662,-1.053 -1.148,-2.266 -1.443,-3.615 -0.294,-1.352 -0.444,-2.75 -0.444,-4.22 0,-1.383 0.157,-2.755 0.481,-4.129 0.322,-1.369 0.835,-2.593 1.545,-3.679 0.713,-1.074 1.602,-1.947 2.679,-2.604 1.079,-0.666 2.37,-1.001 3.881,-1.001 1.605,0 2.949,0.324 4.022,0.969 1.076,0.638 1.936,1.499 2.579,2.575 0.637,1.075 1.1,2.299 1.377,3.676 0.27,1.379 0.406,2.797 0.406,4.259 C 0.406,-2.759 0.27,-1.347 0,0 m 0.406,-17.558 c -1.146,-1.924 -2.641,-3.307 -4.503,-4.163 -1.849,-0.84 -3.948,-1.269 -6.286,-1.269 -2.656,0 -4.995,0.52 -7.008,1.548 -2.02,1.028 -3.676,2.435 -4.981,4.197 -1.304,1.764 -2.296,3.784 -2.955,6.082 -0.662,2.292 -0.994,4.662 -0.994,7.142 0,2.38 0.332,4.682 0.994,6.905 0.659,2.217 1.651,4.182 2.955,5.88 1.305,1.694 2.942,3.049 4.917,4.086 1.965,1.03 4.261,1.541 6.868,1.541 2.107,0 4.108,-0.439 6.011,-1.341 1.904,-0.886 3.403,-2.207 4.502,-3.949 h 0.137 v 17.874 h 9.76 v -49.064 h -9.28 v 4.531 z"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path98"
            />
          </g>
          <g id="g100" transform="translate(400.1221,85.5441)">
            <path
              d="m 0,0 c -0.621,-0.205 -1.284,-0.375 -1.992,-0.515 -0.713,-0.141 -1.459,-0.248 -2.236,-0.339 -0.777,-0.098 -1.554,-0.211 -2.332,-0.345 -0.734,-0.141 -1.458,-0.328 -2.166,-0.554 -0.711,-0.228 -1.33,-0.54 -1.857,-0.93 -0.523,-0.385 -0.952,-0.876 -1.272,-1.47 -0.322,-0.596 -0.48,-1.354 -0.48,-2.274 0,-0.868 0.158,-1.605 0.48,-2.194 0.32,-0.6 0.759,-1.067 1.308,-1.409 0.549,-0.343 1.19,-0.585 1.923,-0.722 0.734,-0.139 1.487,-0.203 2.269,-0.203 1.923,0 3.413,0.317 4.465,0.957 1.052,0.644 1.831,1.414 2.335,2.302 0.507,0.89 0.811,1.795 0.93,2.717 0.11,0.913 0.17,1.646 0.17,2.197 V 0.864 C 1.132,0.49 0.617,0.205 0,0 m -19.276,13.779 c 1.006,1.515 2.291,2.72 3.851,3.643 1.553,0.917 3.307,1.566 5.253,1.956 1.951,0.393 3.907,0.58 5.878,0.58 1.786,0 3.597,-0.121 5.426,-0.375 1.832,-0.25 3.507,-0.743 5.017,-1.471 1.512,-0.733 2.751,-1.758 3.714,-3.059 0.96,-1.311 1.439,-3.039 1.439,-5.194 v -18.48 c 0,-1.604 0.093,-3.143 0.278,-4.611 0.181,-1.461 0.501,-2.562 0.964,-3.289 H 2.647 c -0.184,0.549 -0.334,1.107 -0.447,1.681 -0.119,0.569 -0.197,1.151 -0.243,1.753 -1.558,-1.606 -3.389,-2.731 -5.497,-3.373 -2.104,-0.638 -4.259,-0.96 -6.458,-0.96 -1.693,0 -3.275,0.202 -4.741,0.621 -1.47,0.412 -2.75,1.051 -3.85,1.931 -1.1,0.86 -1.958,1.961 -2.575,3.291 -0.616,1.324 -0.929,2.907 -0.929,4.741 0,2.016 0.355,3.673 1.067,4.977 0.712,1.311 1.625,2.349 2.748,3.133 1.117,0.781 2.404,1.359 3.847,1.751 1.446,0.383 2.896,0.697 4.366,0.929 1.463,0.233 2.906,0.413 4.326,0.55 1.424,0.138 2.682,0.341 3.78,0.617 1.103,0.272 1.966,0.674 2.611,1.206 0.643,0.526 0.942,1.292 0.893,2.291 0,1.058 -0.17,1.897 -0.517,2.514 -0.34,0.619 -0.799,1.107 -1.376,1.44 -0.562,0.345 -1.227,0.578 -1.987,0.693 -0.757,0.111 -1.57,0.168 -2.437,0.168 -1.925,0 -3.443,-0.408 -4.542,-1.233 -1.097,-0.823 -1.738,-2.202 -1.919,-4.124 h -9.76 c 0.14,2.295 0.707,4.193 1.717,5.703"
              style={{
                fill: '#1d1d1b',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none',
              }}
              id="path102"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default LogoEmpresasB;
