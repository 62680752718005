import { FC, ReactNode, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

export interface IAccordionProps {
  title: string | ReactNode;
  textColor?: string;
  children?: ReactNode;
  className?: string;
  collapse?: boolean;
}
const Accordion: FC<IAccordionProps> = ({
  title,
  textColor,
  children,
  className,
  collapse = false,
}) => {
  const [isActive, setIsActive] = useState(collapse);

  const rootClassName = classNames(
    'flex justify-between pb-1 items-center',
    className
  );

  if (!children) {
    return (
      <div className={rootClassName}>
        <div className="text-blue-600 font-semibold text-sm">
          {title}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`${rootClassName} cursor-pointer`}
        onClick={() => setIsActive(!isActive)}
      >
        <div className={`${textColor ? textColor : 'text-blue-600'} uppercase`}>
          {typeof title === 'string'
            ? <span>{title}</span>
            : title}
        </div>
        <ChevronDownIcon
          className={`flex-shrink-0 h-8 w-8 text-light-gray-600 transition-all ${
            isActive ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
        />
      </div>
      {children && isActive && <div>{children}</div>}
    </>
  );
};



export default Accordion;
