const IconMap = ({ ...props }) => (
  <svg
    width="25"
    height="23"
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1727_21766)">
      <path
        d="M18.229 6.22884L23.9582 8.62467V21.083L16.6665 18.208L8.33317 21.083L1.0415 18.208V5.74967L6.24984 7.66634M16.6665 18.208V11.4997M8.33317 21.083V11.4997M12.4998 15.5946C12.4998 15.5946 6.24984 11.0205 6.24984 6.70801C6.24984 3.11426 9.37484 0.958008 12.4998 0.958008C15.6248 0.958008 18.7498 3.11426 18.7498 6.70801C18.7498 11.0205 12.4998 15.5946 12.4998 15.5946ZM13.5415 6.70801C13.5415 6.45384 13.4318 6.21009 13.2364 6.03036C13.0411 5.85064 12.7761 5.74967 12.4998 5.74967C12.2236 5.74967 11.9586 5.85064 11.7633 6.03036C11.5679 6.21009 11.4582 6.45384 11.4582 6.70801C11.4582 6.96217 11.5679 7.20593 11.7633 7.38565C11.9586 7.56537 12.2236 7.66634 12.4998 7.66634C12.7761 7.66634 13.0411 7.56537 13.2364 7.38565C13.4318 7.20593 13.5415 6.96217 13.5415 6.70801Z"
        stroke={props.color || '#060C41'}
        strokeOpacity="0.85"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1727_21766">
        <rect width="25" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconMap;
