const BdLike = ({...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={268}
    height={299}
    fill="none"
    {...props}
  >
    <path
      fill="#3A5AFF"
      d="M267.983 101.004c0-2.104-.109-4.23-.399-6.313-2.143-15.488-9.65-27.88-22.085-37.232-3.649-2.742-7.725-4.93-11.678-7.248-17.378-10.203-34.781-20.37-52.181-30.541-7.706-4.506-15.275-9.28-23.173-13.431-13.636-7.165-27.921-8.03-42.557-3.256-5.324 1.735-10.06 4.64-14.836 7.465-23.783 14.068-47.55 28.15-71.37 42.157-5.711 3.358-11.071 7.096-15.477 12.087C6.847 73.063 2.433 82.81.682 93.796c-.686 4.307-.657 8.639-.653 12.985.036 29.626-.004 59.247-.029 88.872-.004 5.796.7 11.493 2.357 17.046 4.09 13.713 12.033 24.54 24.374 31.951 11.401 6.845 63.188 37.3 77.545 45.899 7.989 4.785 16.493 7.787 25.821 8.345 11.76.707 22.611-2.133 32.58-8.378 1.552-.992 65.055-38.774 77.578-46.178 9.722-5.745 17.048-13.753 22.045-23.855 3.891-7.867 5.697-16.234 5.697-25 0-31.494.011-62.988-.011-94.483l-.003.004Z"
      opacity={0.05}
    />
    <path
      fill="#3A5AFF"
      d="M234.665 113.128c0-1.578-.082-3.173-.299-4.735-1.608-11.616-7.238-20.91-16.564-27.924-2.736-2.056-5.794-3.697-8.758-5.435-13.034-7.653-26.087-15.279-39.136-22.907-5.78-3.38-11.456-6.96-17.38-10.073-10.227-5.373-20.941-6.023-31.918-2.442-3.993 1.301-7.545 3.48-11.127 5.599C91.646 55.762 73.82 66.324 55.956 76.829c-4.284 2.518-8.304 5.322-11.608 9.065-5.535 6.278-8.845 13.589-10.159 21.828-.514 3.23-.492 6.479-.49 9.739.028 22.219-.002 44.435-.021 66.654-.003 4.347.525 8.62 1.768 12.784 3.068 10.285 9.024 18.405 18.28 23.963 8.551 5.134 47.391 27.975 58.159 34.425 5.992 3.588 12.37 5.84 19.365 6.259 8.821.529 16.959-1.6 24.435-6.284 1.165-.744 48.792-29.081 58.184-34.633 7.292-4.309 12.786-10.315 16.534-17.892 2.918-5.9 4.273-12.176 4.273-18.75 0-23.62.008-47.241-.008-70.862l-.003.003Z"
      opacity={0.08}
    />
    <path
      fill="#3A5AFF"
      d="M201.348 125.251c0-1.052-.055-2.115-.2-3.157-1.071-7.744-4.825-13.939-11.042-18.615-1.824-1.371-3.862-2.465-5.839-3.624-8.689-5.102-17.391-10.186-26.091-15.271-3.853-2.253-7.637-4.64-11.586-6.716-6.818-3.582-13.96-4.015-21.279-1.628-2.662.868-5.03 2.32-7.418 3.733-11.891 7.034-23.775 14.075-35.684 21.079-2.856 1.679-5.536 3.548-7.74 6.043-3.69 4.186-5.896 9.059-6.772 14.552-.342 2.153-.328 4.319-.326 6.493.018 14.812-.002 29.623-.014 44.436-.002 2.897.35 5.746 1.178 8.522 2.045 6.857 6.016 12.27 12.187 15.976 5.7 3.423 31.594 18.65 38.772 22.949 3.995 2.393 8.247 3.894 12.911 4.173 5.88.353 11.305-1.067 16.29-4.189.776-.496 32.528-19.387 38.789-23.089 4.861-2.872 8.524-6.877 11.023-11.928 1.945-3.933 2.848-8.117 2.848-12.499 0-15.748.006-31.495-.005-47.242l-.002.002Z"
      opacity={0.1}
    />
    <path
      stroke="#060C41"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M120.95 147.631c2.011 0 3.824-1.111 5.067-2.691a22.555 22.555 0 0 1 7.137-5.98c1.803-.956 3.368-2.382 4.123-4.273a11.2 11.2 0 0 0 .804-4.166v-1.577a1.871 1.871 0 0 1 1.871-1.869 5.608 5.608 0 0 1 5.613 5.606c0 2.871-.649 5.589-1.804 8.019-.663 1.39.267 3.194 1.809 3.194h7.798c2.56 0 4.852 1.729 5.124 4.273.112 1.052.17 2.118.17 3.202a29.757 29.757 0 0 1-6.609 18.74c-.967 1.201-2.462 1.816-4.003 1.816h-10.019c-1.205 0-2.405-.194-3.55-.573l-7.768-2.591a11.215 11.215 0 0 0-3.55-.573h-4.032m20.821-24.294h5.613m-26.434 24.294c.207.51.432 1.009.674 1.5.491.996-.195 2.237-1.305 2.237h-2.265c-2.218 0-4.274-1.291-4.92-3.408a29.853 29.853 0 0 1-1.299-8.739c0-3.869.736-7.564 2.073-10.958.763-1.929 2.709-3.057 4.787-3.057h2.627c1.177 0 1.858 1.385 1.247 2.392a22.308 22.308 0 0 0-3.248 11.623c0 2.975.579 5.813 1.632 8.41h-.003Z"
    />
  </svg>
)
export default BdLike