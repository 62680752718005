import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useProgressBar } from '../../hooks/useProgressBar';

import './RouterProgressBar.css';

const transitionSpeed = 600;

export function RouterProgressBar(
  props?: Parameters<typeof useProgressBar>[0]
) {
  const { events } = useRouter();

  const { width, start, complete, reset } = useProgressBar({
    transitionSpeed,
    ...props,
  });

  useEffect(() => {
    events.on('routeChangeStart', start);
    events.on('routeChangeComplete', complete);
    events.on('routeChangeError', reset);

    return () => {
      events.off('routeChangeStart', start);
      events.off('routeChangeComplete', complete);
      events.off('routeChangeError', reset);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return width > 0 ? (
    <div className="w-full">
      <div className="h-1 w-full bg-blue-100 overflow-hidden">
        <div className="progress w-full h-full bg-buydepa-blue left-right" />
      </div>
    </div>
  ) : null;
}
