const CircleCheck = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#F7F7FF" />
      <path
        d="M12.8726 7.15526C12.9208 7.1061 12.9783 7.06705 13.0417 7.04039C13.1052 7.01373 13.1733 7 13.2421 7C13.311 7 13.3791 7.01373 13.4426 7.04039C13.506 7.06705 13.5635 7.1061 13.6117 7.15526C13.8136 7.35927 13.8164 7.68894 13.6188 7.89649L9.44459 12.831C9.3972 12.883 9.33969 12.9248 9.27557 12.9539C9.21146 12.9829 9.1421 12.9986 9.07172 12.9999C9.00135 13.0012 8.93144 12.9882 8.86628 12.9616C8.80113 12.9349 8.74208 12.8953 8.69277 12.8451L6.15283 10.2712C6.05487 10.1713 6 10.037 6 9.8971C6 9.75719 6.05487 9.62285 6.15283 9.52295C6.20101 9.4738 6.25851 9.43475 6.32196 9.40809C6.38542 9.38143 6.45356 9.3677 6.52238 9.3677C6.59121 9.3677 6.65935 9.38143 6.72281 9.40809C6.78626 9.43475 6.84376 9.4738 6.89194 9.52295L9.04644 11.7064L12.8585 7.17079C12.8629 7.16533 12.8676 7.16015 12.8726 7.15526Z"
        fill="#3A5AFF"
      />
    </svg>
  );
};

export default CircleCheck