const Stickhouse = ({ ...props }) => {
  return (
    <svg
      width="62"
      height="58"
      viewBox="0 0 62 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4615 57V41.8333C19.4615 39.9013 21.0123 38.3333 22.9231 38.3333H29.8462C31.7569 38.3333 33.3077 39.9013 33.3077 41.8333V57M33.3077 57H47.1538V2.69556M33.3077 57H56.3846V25.1111M47.1538 2.69556L51.7692 1M47.1538 2.69556L14.8462 14.5769M56.3846 25.1111L47.1538 22M56.3846 25.1111L61 26.6667M1 57H5.61538M5.61538 57H61M5.61538 57V1H14.8462V14.5769M1 19.6667L14.8462 14.5769"
        stroke={props.color ? props.color : '#ffffff'}
        strokeOpacity="0.85"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Stickhouse
