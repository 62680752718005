import Image from 'next/image';
import { FileUploader } from '../../../../assets/FileUploader';
import { Card } from '../Card/Card';
import { useMemo } from 'react';
import type { UserProfile } from '@auth0/nextjs-auth0/client';

export interface ProfileCardProps {
  onEditAccount: () => void;
  profileData: {
    name: string;
    profilePictureUrl?: string;
    company?: string;
  };
  auth0User?: UserProfile;
}

export function ProfileCard({
  onEditAccount,
  profileData,
  auth0User,
}: ProfileCardProps) {
  const profilePhoto = useMemo(
    () => profileData?.profilePictureUrl || auth0User?.picture || '',
    [profileData, auth0User]
  );
  const brokerCompany = useMemo(() => {
    if (
      profileData &&
      profileData.company &&
      profileData.company.toLocaleLowerCase() === 'independiente'
    ) {
      return 'Broker independiente';
    } else if (profileData && profileData.company) {
      return profileData.company;
    }
    return 'Broker independiente';
  }, [profileData]);

  return (
    <Card>
      <div className="flex flex-col w-full">
        <div className="flex justify-end">
          <button onClick={onEditAccount}>
            <FileUploader />
          </button>
        </div>
        <div className="relative w-36 h-36 bg-cover flex items-center justify-center overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 m-auto">
          <Image
            src={profilePhoto}
            alt={profileData?.name}
            className="w-full h-full object-cover"
            width={144}
            height={144}
          />
        </div>
        <div className="-ml-5 -mr-5 mt-10 mb-5 bg-primary-600 text-white uppercase text-center text-sm py-1">
          <span>{brokerCompany}</span>
        </div>
      </div>
    </Card>
  );
}

export default ProfileCard;
