import { type FC, InputHTMLAttributes, useRef } from 'react';
import { useField } from 'formik';
import cn from 'classnames';

export type Currency = 'UF' | 'CLP' | 'USD' | 'EUR';

interface InputProps {
  label: string;
  name: string;
  currency: Currency;
  className?: string;
}
const InputAmount: FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
  className = '',
  currency = 'CLP',
  label,
  name,
  ...props
}) => {
  const inputRef = useRef(null);
  const [field, { error, touched }] = useField({
    name,
    type: name,
  });

  const inputClassName = cn('w-full pl-8 text-right', {
    'rounded-3xl border border-primary-600': !props.disabled,
    'border-x-0 border-t-0 border-b border-primary-600 outline-none disabled':
      props.disabled,
  });

  const labelClassName = cn({
    'block text-sm font-base text-primary-600 font-semibold ml-4':
      !props.disabled,
    'block text-sm font-base text-primary-600 font-semibold': props.disabled,
  });

  const valueCurrency: { [P in Currency]: string } = {
    UF: 'UF',
    CLP: '$',
    USD: 'USD',
    EUR: '€',
  };

  const handleFocus = (e: any) => {
    if (e.currentTarget.value === '0') {
      e.currentTarget.value = '';
    }
  };

  return (
    <div className={className}>
      <div className="flex flex-col">
        <div>
          <label
            className={`block text-sm font-base text-primary-600 font-semibold ${labelClassName} `}
          >
            {label}
          </label>
          <div className="relative">
            <span className="absolute top-1/2 transform -translate-y-1/2 left-4">
              {valueCurrency[currency]}
            </span>
            <input
              {...field}
              {...props}
              ref={inputRef}
              type="number"
              className={inputClassName}
              onFocus={handleFocus}
            />
          </div>
        </div>
        {touched && error && (
          <div className="error text-red-500 text-sm">{error}</div>
        )}
      </div>
    </div>
  );
};

InputAmount.displayName = 'InputAmount';

export default InputAmount;
