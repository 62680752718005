export const downloadFileFromUrl = async (
  url: string,
  name: string,
  blob?: boolean
) => {
  try {
    if (blob) {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.target = '_blank'; // Open in a new tab/window
      anchor.click();
    }
  } catch (error) {
    console.error(error);
    throw new Error(`Error when downloading file from url: ${url}`);
  }
};
