import * as Yup from 'yup';

export const homeFiltersValidationSchema = Yup.object().shape({
  commune: Yup.array(),
  bedrooms: Yup.string(),
  bathrooms: Yup.string(),
  view: Yup.string().when(['commune', 'bedrooms', 'bathrooms'], {
    is: (commune: string[], bedrooms: string, bathrooms: string) =>
      !commune.length && !bedrooms && !bathrooms,
    then: Yup.string().required('At least one filter is required'),
    otherwise: Yup.string().notRequired(),
  }),
});
