import type { ReactNode } from 'react';

export interface ProgressCardProps {
  title?: string;
  totalSteps: number;
  completedSteps: number;
  children?: ReactNode;
}

export function ProgressCard({
  title,
  totalSteps,
  completedSteps,
  children,
}: ProgressCardProps) {
  return (
    <div className="shadow rounded-lg mb-5 mt-3 py-4 px-6">
      <div className="flex justify-around">
        <div>
          <span className="uppercase text-md">{title}</span>
        </div>
        <div>
          {completedSteps === 3 ? 2 : completedSteps}/{totalSteps}
        </div>
      </div>
      <div className="flex items-center justify-center gap-3 mt-6">
        {Array(totalSteps)
          .fill(1)
          .map((_, idx) => (
            <i
              key={`*-${idx}`}
              className={`w-[90px] h-[18px] rounded-bl-2xl rounded-tr-2xl ${
                idx + 1 > completedSteps ? 'pending-step' : 'completed-step'
              }`}
            />
          ))}
      </div>
      {children && <div className="mt-3">{children}</div>}
    </div>
  );
}
