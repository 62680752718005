import { FC, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

type AlertType = 'success' | 'error' | 'warning' | 'info' | 'default';

export interface AlertProps {
  className?: string;
  message: string;
  showIcon?: boolean;
  type?: AlertType;
  actions?: React.ReactNode;
}

export const Alert: FC<AlertProps> = ({
  className,
  message,
  showIcon,
  type = 'default',
  actions,
}) => {
  const [showAlert, setShowAlert] = useState(true);
  const handleClose = () => {
    setShowAlert(false);
  };
  const rootClassName = cn(
    'text-primary-600',
    {
      'bg-ghost-blue-600': type === 'default',
      'bg-green-100 ': type === 'success',
      'bg-red-100': type === 'error',
      'bg-yellow-100': type === 'warning',
      'bg-primary-600 text-white': type === 'info',
    },
    className
  );
  return (
    <div>
      {showAlert && (
        <div
          className={`${rootClassName} md:flex md:justify-between p-4 items-center rounded-md overflow-hidden`}
        >
          <div className="text-sm mb-4 md:mb-0">{message}</div>
          <div className="flex items-center">
            {actions}
            {showIcon && (
              <button onClick={handleClose} className="ml-4">
                <XMarkIcon width={14} />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
