import React from 'react';

export const LogoBuydepaWhite: React.FC<{
  width?: string;
  height?: string;
  className?: string;
}> = ({ width = 600, height = 116, className = '' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 600 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M122.99 94.2936V13.5889H130.439V46.0182C133.527 42.1488 136.979 39.2007 140.794 37.3581C144.609 35.5155 148.787 34.41 153.329 34.41C161.504 34.41 168.408 37.3581 174.221 43.4386C180.034 49.3348 182.759 56.7051 182.759 65.181C182.759 73.6568 179.853 80.8429 174.039 86.7391C168.226 92.6353 161.322 95.7677 153.147 95.7677C148.424 95.7677 144.246 94.6622 140.43 92.6353C136.615 90.6085 133.345 87.4761 130.439 83.4225V94.1094H122.99V94.2936ZM152.602 88.5817C156.781 88.5817 160.414 87.4761 164.047 85.4493C167.499 83.4225 170.224 80.4743 172.404 76.7892C174.403 73.104 175.493 69.2346 175.493 64.9967C175.493 60.943 174.403 56.8894 172.404 53.2042C170.406 49.5191 167.499 46.571 164.047 44.3599C160.596 42.333 156.781 41.2275 152.784 41.2275C148.787 41.2275 144.972 42.333 141.339 44.3599C137.705 46.3867 134.98 49.3348 132.982 52.8357C130.984 56.3366 130.075 60.3903 130.075 64.8124C130.075 71.63 132.255 77.1577 136.615 81.7641C140.794 86.3706 146.062 88.5817 152.602 88.5817Z"
      fill="white"
    />
    <path
      d="M182.395 36.0693H189.843V63.3394C189.843 69.9727 190.206 74.5791 190.933 76.9745C192.023 80.4754 194.021 83.4235 196.928 85.4503C199.835 87.4772 203.468 88.5827 207.647 88.5827C211.825 88.5827 215.277 87.6614 218.183 85.6346C221.09 83.6078 223.088 81.0282 224.178 77.7115C224.905 75.5004 225.268 70.7097 225.268 63.3394V36.0693H232.899V64.6292C232.899 72.7366 231.99 78.6328 230.174 82.8707C228.357 86.9244 225.632 90.0568 221.817 92.4521C218.183 94.6632 213.46 95.953 207.828 95.953C202.196 95.953 197.473 94.8475 193.84 92.4521C190.025 90.241 187.3 86.9244 185.483 82.8707C183.666 78.8171 182.758 72.5523 182.758 64.2607V36.0693H182.395Z"
      fill="white"
    />
    <path
      d="M229.814 36.0693H237.626L257.428 80.8439L276.503 36.0693H284.315L249.98 115.669H242.168L253.25 89.8725L229.814 36.0693Z"
      fill="white"
    />
    <path
      d="M321.007 17.8242H334.814V94.6596H321.007V88.7634C318.282 91.343 315.557 93.1855 313.013 94.2911C310.288 95.3966 307.382 95.9494 304.293 95.9494C297.208 95.9494 291.031 93.1855 285.945 87.6578C280.858 82.1301 278.133 75.1283 278.133 67.021C278.133 58.5451 280.676 51.3591 285.581 46.0156C290.486 40.4879 296.663 37.724 303.748 37.724C307.018 37.724 310.107 38.2768 313.013 39.5666C315.92 40.8564 318.463 42.699 321.007 45.2786V17.8242V17.8242ZM306.655 50.8063C302.476 50.8063 298.843 52.2804 296.118 55.4128C293.393 58.5451 291.94 62.4145 291.94 67.2052C291.94 71.9959 293.393 75.8653 296.3 78.9977C299.206 82.1301 302.658 83.6041 306.837 83.6041C311.197 83.6041 314.648 82.1301 317.555 78.9977C320.462 75.8653 321.733 71.9959 321.733 67.021C321.733 62.2303 320.28 58.3609 317.555 55.2285C314.467 52.2804 311.015 50.8063 306.655 50.8063Z"
      fill="white"
    />
    <path
      d="M395.124 71.0713H350.979C351.705 74.9407 353.34 78.0731 356.065 80.4684C358.79 82.8638 362.424 83.9693 366.602 83.9693C371.689 83.9693 376.049 82.1268 379.864 78.4416L391.491 83.9693C388.584 88.2073 385.132 91.1554 381.136 93.1822C377.139 95.209 372.416 96.1303 366.784 96.1303C358.245 96.1303 351.16 93.3665 345.892 87.8387C340.442 82.311 337.717 75.4935 337.717 67.2019C337.717 58.7261 340.442 51.7243 345.71 46.1966C351.16 40.6688 357.882 37.7207 365.876 37.7207C374.414 37.7207 381.499 40.4846 386.949 46.1966C392.399 51.7243 395.124 59.0946 395.124 68.3075V71.0713ZM381.499 60.2001C380.591 57.0677 378.774 54.6724 376.049 52.6456C373.324 50.6187 370.236 49.6974 366.784 49.6974C362.969 49.6974 359.517 50.803 356.792 53.0141C354.975 54.3039 353.34 56.6992 351.705 60.2001H381.499V60.2001Z"
      fill="white"
    />
    <path
      d="M411.66 39.3852V45.4657C414.204 42.8861 416.747 41.0435 419.654 39.7537C422.561 38.4639 425.649 37.9111 428.919 37.9111C436.004 37.9111 442.181 40.675 447.086 46.2027C451.991 51.7304 454.534 58.7322 454.534 67.2081C454.534 75.4997 451.991 82.3172 446.723 87.8449C441.636 93.3726 435.459 96.1365 428.374 96.1365C425.286 96.1365 422.379 95.5837 419.654 94.4782C416.929 93.3726 414.204 91.5301 411.479 88.9505V103.875H397.854V39.3852H411.66V39.3852ZM426.194 50.8092C421.834 50.8092 418.2 52.2832 415.475 55.2313C412.569 58.1795 411.115 62.0489 411.115 67.0238C411.115 71.9988 412.569 75.8682 415.475 79.0006C418.382 82.1329 421.834 83.607 426.194 83.607C430.372 83.607 433.824 82.1329 436.731 79.0006C439.637 75.8682 441.091 71.9988 441.091 67.2081C441.091 62.4174 439.637 58.548 436.912 55.4156C434.006 52.2832 430.372 50.8092 426.194 50.8092Z"
      fill="white"
    />
    <path
      d="M498.132 39.3803H511.939V94.8418H498.132V88.9456C495.407 91.5252 492.682 93.3678 490.138 94.4733C487.413 95.5788 484.507 96.1316 481.418 96.1316C474.333 96.1316 468.156 93.3678 463.07 87.84C457.983 82.3123 455.258 75.3105 455.258 67.2032C455.258 58.7273 457.801 51.5413 462.706 46.1978C467.611 40.6701 473.788 37.9062 480.873 37.9062C484.143 37.9062 487.232 38.459 490.138 39.7488C493.045 41.0386 495.588 42.8812 498.132 45.4608V39.3803ZM483.78 50.8043C479.601 50.8043 475.968 52.2783 473.243 55.4107C470.518 58.5431 469.065 62.4125 469.065 67.2032C469.065 71.9939 470.518 75.8633 473.425 78.9957C476.331 82.1281 479.783 83.6021 483.962 83.6021C488.322 83.6021 491.773 82.1281 494.68 78.9957C497.587 75.8633 498.858 71.9939 498.858 67.0189C498.858 62.2282 497.405 58.3588 494.68 55.2265C491.592 52.2783 488.14 50.8043 483.78 50.8043Z"
      fill="white"
    />
    <path d="M516.852 89.3232H521.938V94.6667H516.852V89.3232Z" fill="white" />
    <path
      d="M523.758 84.5323C523.758 78.6361 528.3 73.6611 534.476 73.6611C538.291 73.6611 540.653 75.1352 542.651 77.162L539.745 80.2944C538.291 78.8203 536.838 77.7148 534.476 77.7148C531.025 77.7148 528.481 80.6629 528.481 84.3481C528.481 88.0332 531.025 90.9813 534.658 90.9813C536.838 90.9813 538.473 89.8758 539.926 88.4017L542.833 91.1656C540.835 93.3767 538.473 95.035 534.476 95.035C528.3 95.2193 523.758 90.4286 523.758 84.5323Z"
      fill="white"
    />
    <path
      d="M543.371 84.5323C543.371 78.4518 548.094 73.6611 554.271 73.6611C560.448 73.6611 565.171 78.4518 565.171 84.3481C565.171 90.2443 560.448 95.2193 554.271 95.2193C548.094 95.2193 543.371 90.4286 543.371 84.5323ZM560.448 84.5323C560.448 80.8472 557.905 77.7148 554.271 77.7148C550.456 77.7148 548.094 80.6629 548.094 84.3481C548.094 88.0332 550.638 90.9813 554.271 90.9813C558.086 91.1656 560.448 88.0332 560.448 84.5323Z"
      fill="white"
    />
    <path
      d="M568.078 74.0296H572.802V77.162C574.073 75.3195 575.89 73.6611 578.978 73.6611C581.885 73.6611 584.065 75.1352 585.155 77.3463C586.79 75.1352 588.97 73.6611 592.058 73.6611C596.418 73.6611 599.143 76.6093 599.143 81.5842V94.8507H594.42V83.0583C594.42 79.7416 592.967 78.0833 590.242 78.0833C587.698 78.0833 585.882 79.9259 585.882 83.2425V94.8507H581.158V83.0583C581.158 79.9259 579.523 78.0833 576.98 78.0833C574.437 78.0833 572.62 80.1101 572.62 83.2425V94.8507H567.896V74.0296H568.078Z"
      fill="white"
    />
    <path d="M61.5893 40.6729H52.6875V48.5959H61.5893V40.6729Z" fill="white" />
    <path d="M61.5893 53.5742H52.6875V61.4973H61.5893V53.5742Z" fill="white" />
    <path d="M61.5893 66.4717H52.6875V74.3948H61.5893V66.4717Z" fill="white" />
    <path d="M47.5932 53.5742H38.6914V61.4973H47.5932V53.5742Z" fill="white" />
    <path d="M47.5932 66.4717H38.6914V74.3948H47.5932V66.4717Z" fill="white" />
    <path
      d="M24.707 41.9646C37.4239 19.6695 65.4009 12.1149 87.3829 24.8287C94.1046 28.8824 99.7364 34.2258 103.37 40.6748V39.5693C103.37 31.8305 99.373 24.6444 92.6513 20.775L62.1309 2.90206C55.5908 -0.967352 47.4157 -0.967352 40.8756 2.90206L10.3551 20.9593C3.81505 24.8287 0 32.0147 0 39.7536V75.6838C0 83.4226 3.81505 90.6086 10.3551 94.478L40.8756 112.535C47.4157 116.405 55.5908 116.405 62.1309 112.535L63.0392 111.982C55.7724 111.798 48.324 109.771 41.6022 105.718C19.6203 92.6354 11.9902 64.0755 24.707 41.9646Z"
      fill="white"
    />
    <path
      d="M92.833 20.9593L62.1309 2.90206C55.5908 -0.967352 47.4157 -0.967352 40.8756 2.90206L10.3551 20.9593C3.81505 24.8287 0 32.0147 0 39.7536V40.8591C1.81669 34.5943 9.08346 29.0666 15.8052 25.013C37.7872 12.1149 65.7643 19.8537 78.4811 42.1489C91.198 64.4441 83.5678 92.8197 61.5859 105.718C58.3158 107.56 55.0458 109.034 51.5941 110.14C55.4091 111.245 59.2242 111.982 63.0392 111.982L92.833 94.478C99.3731 90.6086 103.37 83.4226 103.37 75.6838V39.5693C103.551 31.8305 99.3731 24.8287 92.833 20.9593Z"
      fill="white"
    />
    <path d="M11.0783 20.4023L10.1699 20.7709L11.0783 20.4023Z" fill="white" />
    <path
      d="M92.8318 20.9551L91.9235 20.4023C95.3752 27.0356 97.3735 34.4059 97.3735 42.329C97.3735 68.125 76.8449 88.9461 51.4112 88.9461C39.0577 88.9461 27.9759 84.1554 19.8008 76.0481C22.7075 88.0248 30.1559 98.7118 41.4194 105.529C48.1412 109.583 55.5896 111.61 62.8564 111.794L61.948 112.347L92.6501 94.2896C99.1902 90.4202 103.369 83.2342 103.369 75.4953V39.5651C103.55 31.8263 99.3719 24.8245 92.8318 20.9551Z"
      fill="white"
    />
  </svg>
);
