import { useMemo, type ReactNode } from 'react';

export const LAYOUT_TOP = 'top';
export const LAYOUT_BOTTOM = 'bottom';

export type Step = {
  title: string;
  content: ReactNode;
};

export type LayoutT = 'top' | 'bottom';

export interface StepperProps {
  steps: Step[];
  currentStep: number;
  hideProgress?: boolean;
  layout?: LayoutT;
  titleClassName?: string;
}

interface StepperTitleProps {
  currentStep: number;
  totalSteps: number;
  progress: number;
  hideProgress?: boolean;
  className?: string;
}

function StepperTitle({
  currentStep,
  totalSteps,
  progress,
  hideProgress,
  className,
}: StepperTitleProps) {
  return (
    <div className={className}>
      <p className="text-sm font-light text-gray-900">
        Paso {currentStep} de {totalSteps}
      </p>
      {!hideProgress && (
        <div className="mt-2" aria-hidden="true">
          <div className="overflow-hidden rounded-full bg-blue-100 mb-4">
            <div
              className="h-2 rounded-full bg-blue-500 transition-all duration-400"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default function Stepper({
  steps,
  currentStep,
  hideProgress,
  layout = 'top',
  titleClassName,
}: StepperProps) {
  const progress = useMemo(() => {
    return (currentStep / steps.length) * 100;
  }, [currentStep, steps]);

  return (
    <>
      <h4 className="sr-only">Steps</h4>
      {layout === LAYOUT_TOP && (
        <StepperTitle
          className={titleClassName}
          currentStep={currentStep}
          totalSteps={steps.length}
          progress={progress}
          hideProgress={hideProgress}
        />
      )}
      <span className="font-bold">{steps[currentStep - 1].title}</span>
      <div className="p-2 mt-4">{steps[currentStep - 1].content}</div>
      {layout === LAYOUT_BOTTOM && (
        <StepperTitle
          className={titleClassName}
          currentStep={currentStep}
          totalSteps={steps.length}
          progress={progress}
          hideProgress={hideProgress}
        />
      )}
    </>
  );
}
