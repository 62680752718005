import NumericFormat from 'react-number-format';

export function CommissionsCard(props: any) {
  const { profileData } = props;
  return (
    <div className="contentCard mb-6 shadow rounded-lg">
      <div className="text-right text-sm">
        <p>Comisiones por cobrar</p>
      </div>
      <div id="background" className="commission">
        <p>
          <NumericFormat
            value={profileData?.recievableComission || 0}
            displayType={'text'}
            prefix="$"
            thousandSeparator="."
            decimalSeparator=","
          />
        </p>
      </div>
    </div>
  );
}
