import { useState, Fragment, FC, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export type ModalPositionT = 'left' | 'center' | 'right' | 'bottom';
export type ModalSizeT = 'xsmall' | 'small' | 'medium' | 'large' | 'full';

interface ModalProps {
  title: string;
  titleClassName?: string;
  titleHolderClassName?: string;
  open: boolean;
  position?: ModalPositionT;
  size?: ModalSizeT;
  onClose?: () => void;
  children: React.ReactNode;
}

const Modal: FC<ModalProps> = ({
  title,
  titleClassName,
  titleHolderClassName,
  open = false,
  position = 'center',
  size = 'small',
  onClose,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  function closeModal() {
    onClose && onClose();
  }

  const modalSize: { [k in ModalSizeT]: string } = {
    xsmall: 'w-[20rem]',
    small: 'w-[30rem]',
    medium: 'w-[40rem]',
    large: 'w-[50rem]',
    full: 'w-full',
  };

  const modalTransition: { [k in ModalPositionT]: any } = {
    left: {
      enter: 'transition ease-in-out duration-300 transform',
      enterFrom: '-translate-x-full',
      enterTo: 'translate-x-0',
      leave: 'transition ease-in-out duration-300 transform',
      leaveFrom: '-translate-x-0',
      leaveTo: 'translate-x-full',
    },
    center: {
      enter: 'transition ease-in-out duration-300 transform',
      enterFrom: '-translate-y-full',
      enterTo: 'translate-y-0',
      leave: 'transition ease-in-out duration-300 transform',
      leaveFrom: '-translate-y-0',
      leaveTo: 'translate-y-full',
    },
    right: {
      enter: 'transition ease-in-out duration-300 transform',
      enterFrom: 'translate-x-full',
      enterTo: '-translate-x-0',
      leave: 'transition ease-in-out duration-300 transform',
      leaveFrom: 'translate-x-0',
      leaveTo: '-translate-x-full',
    },
    bottom: {
      enter: 'transition ease-in-out duration-300 transform',
      enterFrom: 'translate-y-full',
      enterTo: '-translate-y-0',
      leave: 'transition ease-in-out duration-300 transform',
      leaveFrom: 'translate-y-0',
      leaveTo: '-translate-y-full',
    },
  };

  const dialogContainerClassNames = classNames(
    'fixed flex w-full h-screen top-0 bottom-0 right-0 left-0',
    position === 'center' && 'justify-center items-center',
    position === 'left' && 'md:h-screen items-start justify-start',
    position === 'right' && 'md:h-screen items-start justify-end'
  );

  const dialogClassNames = classNames(
    'bg-white h-full md:h-auto overflow-y-auto md:pb-6',
    modalSize[size],
    position === 'center' && 'md:rounded-md md:max-h-[90%]',
    position === 'left' && 'md:h-full',
    position === 'right' && 'md:h-full',
    position === 'bottom' && 'absolute bottom-0 left-0 w-full max-h-[35%]'
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <Transition.Child as={Fragment} {...modalTransition[position]}>
          <div className={`overflow-y-auto ${dialogContainerClassNames}`}>
            <Dialog.Panel className={dialogClassNames}>
              <Dialog.Title
                as="h3"
                className={`flex justify-between font-medium leading-6 text-primary-600 w-full px-6 py-5 drop-shadow-sm border-b border-primary-100 ${titleHolderClassName} ${
                  position === 'center' && 'md:rounded-t-md'
                }`}
              >
                <div className={`${titleClassName} md:text-2xl`}>{title}</div>
                {onClose && (
                  <div className="flex justify-center mr-4">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => closeModal()}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-primary-600"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                )}
              </Dialog.Title>
              <div className="mt-2 p-4">{children}</div>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
export { Modal };
