const CardinalDirection = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.color ? props.color : '#7483AB'}
      stroke="#fff"
      strokeWidth={0.2}
      d="m19.923 13.885.092-.04-.092.04 2.78 6.488c.632 1.473-.857 2.962-2.33 2.33l-6.489-2.78a7.025 7.025 0 0 1-3.758-3.856l-2.408-6.043c-.577-1.447.858-2.882 2.306-2.306l6.042 2.408a7.025 7.025 0 0 1 3.857 3.759Zm-9.848-3.94L9.86 9.86l.086.216 2.085 5.232a4.975 4.975 0 0 0 2.662 2.73l5.627 2.412.23.099-.1-.23-2.41-5.627a4.975 4.975 0 0 0-2.732-2.661l-5.232-2.085ZM15 2.15C7.903 2.15 2.15 7.903 2.15 15c0 7.097 5.753 12.85 12.85 12.85 7.097 0 12.85-5.753 12.85-12.85 0-7.097-5.753-12.85-12.85-12.85ZM.1 15C.1 6.77 6.771.1 15 .1S29.9 6.771 29.9 15 23.229 29.9 15 29.9C6.77 29.9.1 23.229.1 15Z"
    />
  </svg>
);
export default CardinalDirection;
