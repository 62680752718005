import BathIcon from '../../icons/BathIcon';
import BedIcon from '../../icons/BedIcon';
import { Button } from '../Button/Button';
import { Form, Formik, FormikValues } from 'formik';
import ComboboxFormik from '../ComboboxFormik/ComboboxFormik';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { homeFiltersValidationSchema } from './FiltersValidationSchema';

export interface HomeFilterProps {
  communes: string[];
  bedrooms: string[];
  bathrooms: string[];
  loading?: boolean;
  onSubmit: (values: FormikValues) => void;
}

export function HomeFilter({
  communes,
  bedrooms,
  bathrooms,
  loading,
  onSubmit,
}: HomeFilterProps) {
  return (
    <Formik
      initialValues={{
        commune: [],
        bedrooms: '',
        bathrooms: '',
        view: '',
      }}
      onSubmit={onSubmit}
      validationSchema={homeFiltersValidationSchema}
    >
      {({ setFieldValue, handleSubmit, isValid, touched }) => (
        <Form>
          <div className="z-40 relative lg:-top-8 flex flex-col lg:flex-row p-4 lg:p-0 lg:px-4 bg-white rounded-lg border border-slate-500 items-center w-max mx-auto max-w-7xl px-6 justify-evenly gap-6 lg:gap-0">
            <ComboboxFormik
              multiple
              hideErrors
              loading={loading}
              startIcon={<MagnifyingGlassIcon width={24} stroke="#6D54F4" />}
              placeholder="Buscar por comunas"
              name="commune"
              selectedOptionClassName="bg-primary-600 text-txLight"
              optionsClassName="!w-72 right-0"
              className="py-5 rounded-none border-0 border-b-2 lg:border-b-0 lg:border-r-2 border-slate-400 p-6 !bg-transparent !w-64 cursor-pointer"
              options={
                communes?.map((commune) => ({
                  value: commune,
                  label: commune,
                })) || []
              }
            />
            {Object.keys(touched).length > 0 && !isValid && (
              <span className="absolute whitespace-nowrap top-20 lg:top-20 left-1/2 -translate-x-1/2 lg:translate-x-0 lg:left-5 px-4 py-2 font-semibold bg-primary text-white rounded-full">
                <div className="relative">
                  <div className="absolute -top-5 left-1 w-0 h-0 border-l-[10px] border-l-transparent border-b-[15px] border-b-primary border-r-[10px] border-r-transparent" />
                </div>
                Selecciona un filtro para buscar
              </span>
            )}
            <div className="hidden lg:block">
              <ComboboxFormik
                loading={loading}
                hideErrors
                startIcon={<BedIcon color="#6D54F4" />}
                placeholder="Dormitorios"
                singleDisplayName={(value) =>
                  `Dormitorio${value !== '1' ? 's' : ''}`
                }
                name="bedrooms"
                className="py-5 pl-5 rounded-none border-0 border-b-2 lg:border-b-0 lg:border-r-2 border-slate-400 p-6 !bg-transparent !w-44 cursor-pointer"
                options={
                  bedrooms?.map((bedroom) => ({
                    value: bedroom,
                    label: bedroom,
                  })) || []
                }
              />
            </div>
            <div className="hidden lg:block">
              <ComboboxFormik
                loading={loading}
                hideErrors
                startIcon={<BathIcon color="#6D54F4" />}
                singleDisplayName={(value) => `Baño${value !== '1' ? 's' : ''}`}
                placeholder="Baños"
                name="bathrooms"
                className="py-5 pl-5 rounded-none border-0 border-b-2 lg:border-b-0 lg:border-r-2 border-slate-400 p-6 !bg-transparent !w-44 cursor-pointer"
                options={
                  bathrooms?.map((bathroom) => ({
                    value: bathroom,
                    label: bathroom,
                  })) || []
                }
              />
            </div>
            <div className="flex py-4 lg:p-0 flex-row justify-between gap-4 lg:gap-0">
              <Button
                color="primary"
                variant="fill"
                type="submit"
                className="drop-shadow-md lg:mx-2 rounded-md"
              >
                Buscar
              </Button>
              <Button
                color="primary"
                type="button"
                className='whitespace-nowrap rounded-md'
                onClick={(e: any) => {
                  setFieldValue('view', 'map');
                  handleSubmit(e);
                }}
              >
                Ver en mapa
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
