import classNames from 'classnames';
import type { ToastOptions } from 'react-toastify';

export interface ToastContentProps {
  title?: string;
  subtitle?: string;
  description?: string;
  type?: ToastOptions['type'];
}

export default function ToastContent({
  title,
  subtitle,
  description,
  type = 'default',
}: ToastContentProps) {
  const titleClasses = classNames('text-md font-semibold', {
    'text-red-600': type === 'error',
    'text-green-600': type === 'success',
    'text-yellow-600': type === 'warning',
    'text-blue-600': type === 'info',
  });

  return (
    <>
      {title && <h1 className={titleClasses}>{title}</h1>}
      {subtitle && <p className="text-sm font-medium">{subtitle}</p>}
      {description && <p className="text-xs font-light">{description}</p>}
    </>
  );
}
