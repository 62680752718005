import Script from 'next/script';

export const GlobalHeadScripts = () => {
  return (
    <>
      {/* GOOGLE TAG MANAGER */}
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataGtm','GTM-W54GN94');`}
      </Script>

      {/* GOOGLE TAG MANAGER */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W54GN94"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />

      {/* CLIENTIFY ANALYTICS */}
      <Script id="clientify-tracker" strategy="beforeInteractive">
        {`if (typeof trackerCode ==='undefined'){
            (function (d, w, u, o) {
                w[o] = w[o] || function () {
                    (w[o].q = w[o].q || []).push(arguments)
                };
                a = d.createElement('script'),
                    m = d.getElementsByTagName('script')[0];
                a.async = 1; a.src = u;
                m.parentNode.insertBefore(a, m)
            })(document, window, 'https://analytics.clientify.net/tracker.js', 'ana');
            ana('setTrackerUrl', 'https://analytics.clientify.net');
            ana('setTrackingCode', 'CF-34173-34173-Q8NQ7');
            ana('trackPageview');
        }`}
      </Script>

      {/* METRICOOL ANALYTICS */}
      <Script id="metrocool-tracker" strategy="afterInteractive">
        {
          'function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"14d4b901155f69fd865558dffdc80f5b"})});'
        }
      </Script>

      {/* FACEBOOK PIXEL */}
      <Script strategy="afterInteractive" id="facebook-pixel-script">
        {`
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      if (typeof fbq === 'undefined') {
        fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
      }
      fbq('track', 'PageView');
      `}{' '}
      </Script>
      {/* <!-- Hotjar Tracking Code for https://buydepa.com --> */}
      <Script
        id="hotjar-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3192085,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display: none" src="https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1" />`,
        }}
      />
      {/* FACEBOOK DOMAIN VERIFICATION */}
      <meta
        name="facebook-domain-verification"
        content="fhy1lc9p8j2v0uas63mheh50awtui3"
      />
    </>
  );
};
