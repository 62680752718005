/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState, useRef } from 'react';
import NumericFormat from 'react-number-format';
import styles from './MultiRangeSlider.module.css';
import cn from 'classnames';

interface MultiRangeSliderProps {
  minValue: number;
  maxValue: number;
  min: number;
  max: number;
  minName?: string;
  maxName?: string;
  disabled?: boolean;
  label?: string;
  symbol?: string;
  step?: number;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function MultiRangeSlider({
  minValue,
  maxValue,
  min,
  max,
  disabled,
  label,
  symbol,
  step = 1,
  minName,
  maxName,
  handleChange,
}: MultiRangeSliderProps) {
  const [minVal, setMinVal] = useState(minValue);
  const [maxVal, setMaxVal] = useState(maxValue);
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLInputElement>(null);

  const getPercent = useCallback(
    (value: any) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  const minRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      minName === 'min_bedrooms' || minName === 'min_bathrooms'
        ? Math.min(+e.target.value, maxVal)
        : Math.min(+e.target.value, maxVal - step);
    setMinVal(value);
    e.target.value = value.toString();
    handleChange && handleChange(e);
  };

  const maxRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      maxName === 'max_bedrooms' || maxName === 'max_bathrooms'
        ? Math.max(+e.target.value, minVal)
        : Math.max(+e.target.value, minVal + step);
    setMaxVal(value);
    e.target.value = value.toString();
    handleChange && handleChange(e);
  };

  useEffect(() => {
    if (minValue) {
      setMinVal(minValue);
    }
    if (maxValue) {
      setMaxVal(maxValue);
    }
  }, [minValue, maxValue]);

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
        range.current.style.left = `${minPercent}%`;
      }
    }
  }, [getPercent, maxVal, minVal]);

  const labelClassName = cn('block text-sm text-primary-600 font-semibold', {
    ' ml-4': !disabled,
  });

  return (
    <div className="flex items-start justify-center flex-col w-full m-auto mt-2 relative">
      <label className={labelClassName}>{label}</label>
      <div className="flex items-center justify-center h-8 w-full">
        <input
          value={minVal}
          type="range"
          name={minName}
          min={min}
          max={max}
          step={step}
          ref={minValRef}
          onChange={minRange}
          className={`${styles.thumb} ${
            minVal === max ? 'z-10' : styles.thumb__zindex3
          }`}
        />
        <input
          value={maxVal}
          type="range"
          name={maxName}
          min={min}
          max={max}
          step={step}
          ref={maxValRef}
          onChange={maxRange}
          className={`${styles.thumb} ${styles.thumb__zindex4}`}
        />
        <div className="relative w-full">
          <div className="absolute rounded h-[4px] bg-light-gray-100 z-1 w-full" />
          <div
            ref={range}
            className="absolute rounded h-[4px] bg-primary-600 z-2"
          />
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="text-sm text-primary-600">
          <NumericFormat
            value={minVal}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            displayType={'text'}
            suffix={symbol}
          />
        </div>
        <div className="text-sm text-primary-600">
          <NumericFormat
            value={maxVal}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            displayType={'text'}
            suffix={symbol}
          />
        </div>
      </div>
    </div>
  );
}

export default MultiRangeSlider;
