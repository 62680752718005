import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';

const PAGE_SIZE_OPTIONS = [15, 30, 50, 100, 200];

const Pagination = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    onChangePageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage =
    paginationRange && paginationRange[paginationRange?.length - 1];

  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <div className="flex flex-wrap items-center justify-center">
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
        {paginationRange?.map((pageNumber: any, idx: any) => {
          if (pageNumber === DOTS) {
            return (
              <li key={idx} className="pagination-item dots">
                &#8230;
              </li>
            );
          }
          return (
            <li
              key={idx}
              className={classnames('pagination-item', {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <div className="arrow right" />
        </li>
        {onChangePageSize && (
          <select
            onChange={(e) => onChangePageSize(e.target.value)}
            defaultValue={pageSize}
            className="mx-auto border border-gray-300 text-gray-600 h-10 mt-2 md:mt-0 md:pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none rounded-full"
          >
            {PAGE_SIZE_OPTIONS.map((option, index) => (
              <option key={index} value={option}>
                Mostrar {option}
              </option>
            ))}
          </select>
        )}
      </div>
    </ul>
  );
};

export default Pagination;
