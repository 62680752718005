export const LogoBuydepaIcon: React.FC<{
  width?: number;
  height?: number;
  className?: string;
}> = ({ width = 56, height = 63, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 56 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="logo">
        <g id="Group">
          <path
            id="Vector"
            d="M33.3632 22.0381H28.541V26.3301H33.3632V22.0381Z"
            fill="#2C45C9"
          />
          <path
            id="Vector_2"
            d="M33.3632 29.0273H28.541V33.3194H33.3632V29.0273Z"
            fill="#768CFB"
          />
          <path
            id="Vector_3"
            d="M33.3632 36.0127H28.541V40.3047H33.3632V36.0127Z"
            fill="#768CFB"
          />
          <path
            id="Vector_4"
            d="M25.7812 29.0273H20.959V33.3194H25.7812V29.0273Z"
            fill="#2C45C9"
          />
          <path
            id="Vector_5"
            d="M25.7812 36.0127H20.959V40.3047H25.7812V36.0127Z"
            fill="#3A5AFF"
          />
          <g id="Group_2">
            <g id="Group_3">
              <path
                id="Vector_6"
                d="M13.3841 22.7329C20.273 10.6552 35.4286 6.56283 47.3366 13.4501C50.9779 15.646 54.0287 18.5406 55.9969 22.0342V21.4353C55.9969 17.243 53.8318 13.3503 50.1906 11.2541L33.6572 1.57209C30.1143 -0.524029 25.6858 -0.524029 22.1429 1.57209L5.60953 11.354C2.06667 13.4501 0 17.3429 0 21.5351V40.999C0 45.1912 2.06667 49.084 5.60953 51.1801L22.1429 60.962C25.6858 63.0581 30.1143 63.0581 33.6572 60.962L34.1493 60.6625C30.2127 60.5627 26.1778 59.4648 22.5365 57.2688C10.6286 50.182 6.49525 34.7107 13.3841 22.7329Z"
                fill="url(#paint0_linear_1310_8245)"
              />
              <path
                id="Vector_7"
                d="M50.289 11.354L33.6572 1.57209C30.1143 -0.524029 25.6858 -0.524029 22.1429 1.57209L5.60953 11.354C2.06667 13.4501 0 17.3429 0 21.5351V22.134C0.984129 18.7403 4.92064 15.7458 8.56192 13.5499C20.4699 6.56283 35.6255 10.7551 42.5144 22.8327C49.4033 34.9103 45.2699 50.2818 33.362 57.2688C31.5905 58.267 29.8191 59.0655 27.9493 59.6644C30.0159 60.2633 32.0826 60.6625 34.1493 60.6625L50.289 51.1801C53.8318 49.084 55.9969 45.1912 55.9969 40.999V21.4353C56.0953 17.243 53.8318 13.4501 50.289 11.354Z"
                fill="url(#paint1_linear_1310_8245)"
              />
              <g id="Group_4">
                <path
                  id="Vector_8"
                  d="M6.00183 11.0547L5.50977 11.2543L6.00183 11.0547Z"
                  fill="url(#paint2_linear_1310_8245)"
                />
                <path
                  id="Vector_9"
                  d="M50.2885 11.3541L49.7965 11.0547C51.6663 14.648 52.7488 18.6406 52.7488 22.9327C52.7488 36.9068 41.6282 48.1859 27.8504 48.1859C21.1583 48.1859 15.1551 45.5907 10.7266 41.1988C12.3012 47.6868 16.3361 53.476 22.4377 57.1692C26.079 59.3651 30.1139 60.4631 34.0504 60.5629L33.5583 60.8624L50.1901 51.0805C53.733 48.9844 55.9965 45.0916 55.9965 40.8994V21.4354C56.0949 17.2432 53.8314 13.4502 50.2885 11.3541Z"
                  fill="url(#paint3_linear_1310_8245)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1310_8245"
          x1="42.0469"
          y1="55.9079"
          x2="13.2671"
          y2="6.76003"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2475" stopColor="#060C41" />
          <stop offset="0.6748" stopColor="#2039B9" />
          <stop offset="0.931" stopColor="#3A5AFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1310_8245"
          x1="46.7732"
          y1="53.1274"
          x2="17.999"
          y2="3.9891"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3154" stopColor="#3A5AFF" />
          <stop offset="0.578" stopColor="#2039B9" />
          <stop offset="0.996" stopColor="#060C41" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1310_8245"
          x1="5.92212"
          y1="10.9963"
          x2="5.59266"
          y2="11.321"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5119" stopColor="#6F3185" />
          <stop offset="0.8696" stopColor="#DB972E" />
          <stop offset="0.9797" stopColor="#E5C13A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1310_8245"
          x1="57.9911"
          y1="18.5622"
          x2="20.0824"
          y2="55.9384"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2475" stopColor="#060C41" />
          <stop offset="0.6748" stopColor="#2039B9" />
          <stop offset="0.931" stopColor="#3A5AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
