import Link from 'next/link';
import { classNames } from '../../../../utils/class-names';
import { useGeoContext } from '../../hooks/useGeoContext';
import {
  COLOMBIA_INTERNAL_RECIPIENTS,
  MEXICO_INTERNAL_RECIPIENTS,
} from '../../utils/constants';
import { getIcon } from '../../utils/getIcon';

const socialMediaLinks = [
  {
    countryCode: 'CL',
    social: [
      {
        name: 'Email',
        href: 'mailto:contacto@buydepa.com',
        icon: (props: any) => getIcon('Email', props),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/buydepa/',
        icon: (props: any) => getIcon('Instagram', props),
      },
      {
        name: 'Linkedin',
        href: 'https://www.linkedin.com/company/buydepa/',
        icon: (props: any) => getIcon('Linkedin', props),
      },
      {
        name: 'Tik Tok',
        href: 'https://www.tiktok.com/@buydepa',
        icon: (props: any) => getIcon('Tik Tok', props),
      },
      // {
      //   name: 'Twitter',
      //   href: 'https://twitter.com/buydepa',
      //   icon: (props: any) => getIcon('Twitter', props),
      // },
      // {
      //   name: 'Whatsapp',
      //   href: '',
      //   icon: (props: any) => getIcon('Whatsapp', props),
      // },
      // {
      //   name: 'Facebook',
      //   href: 'https://www.facebook.com/Buydepacom-131091422472256',
      //   icon: (props: any) => getIcon('Facebook', props),
      // },
    ],
  },
  {
    countryCode: 'CO',
    social: [
      {
        name: 'Email',
        href: `https://mail.google.com/mail/?view=cm&source=mailto&to=${COLOMBIA_INTERNAL_RECIPIENTS}`,
        icon: (props: any) => getIcon('Email', { ...props }),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/buydepa_co/',
        icon: (props: any) => getIcon('Instagram', props),
      },
      {
        name: 'Whatsapp',
        href: 'https://wa.me/573107270212',
        icon: (props: any) => getIcon('Whatsapp', props),
      },
    ],
  },
  {
    countryCode: 'MX',
    social: [
      {
        name: 'Email',
        href: `https://mail.google.com/mail/?view=cm&source=mailto&to=${MEXICO_INTERNAL_RECIPIENTS}`,
        icon: (props: any) => getIcon('Email', { ...props }),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/buydepa_mx/',
        icon: (props: any) => getIcon('Instagram', props),
      },
    ],
  },
];

type Props = {
  size?: 'small' | 'medium' | 'large';
  color?: string;
};

export default function SocialMediaNav({ size = 'medium', color }: Props) {
  const { currentConfig } = useGeoContext();

  const socialLinks = socialMediaLinks.find(
    (item) => item.countryCode === currentConfig.code
  )?.social;

  const getSizeClasses = () => {
    switch (size) {
      case 'small':
        return 'w-6 h-6';
      case 'medium':
        return 'w-8 h-8';
      case 'large':
        return 'w-10 h-10';
      // deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
      default:
        return 'w-8 h-8';
    }
  };

  return (
    <div className="flex space-x-6 justify-center">
      {socialLinks?.map((item) => (
        <Link
          key={item.name}
          href={item.href}
          rel="noopener noreferrer"
          target="_blank"
          className="text-buydepa-blue hover:text-primary-100"
        >
          <span className="sr-only">{item.name}</span>
          <item.icon
            className={classNames(getSizeClasses())}
            aria-hidden="true"
            color={color}
          />
        </Link>
      ))}
    </div>
  );
}
