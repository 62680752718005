const BedIcon = ({ ...props }) => (
  <svg
    width={props.width ? props.width : '30'}
    height={props.height ? props.height : '24'}
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.40746 19.7V22.5M27.7415 19.7V22.5M3.8149 3.6C3.8149 3.04305 4.03733 2.5089 4.43325 2.11508C4.82917 1.72125 5.36616 1.5 5.92607 1.5H24.2229C24.7828 1.5 25.3198 1.72125 25.7157 2.11508C26.1116 2.5089 26.3341 3.04305 26.3341 3.6V11.3H3.8149V3.6Z"
      stroke={props.color ? props.color : '#7483AB'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5937 7.80078H11.5565C10.9966 7.80078 10.4596 8.02203 10.0637 8.41586C9.66774 8.80968 9.44531 9.34383 9.44531 9.90078V11.3008H20.7049V9.90078C20.7049 9.34383 20.4825 8.80968 20.0865 8.41586C19.6906 8.02203 19.1536 7.80078 18.5937 7.80078Z"
      stroke={props.color ? props.color : '#7483AB'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 13.4008C1 12.8438 1.22243 12.3097 1.61835 11.9159C2.01427 11.522 2.55125 11.3008 3.11117 11.3008H27.0378C27.5977 11.3008 28.1347 11.522 28.5306 11.9159C28.9265 12.3097 29.1489 12.8438 29.1489 13.4008V19.7008H1V13.4008Z"
      stroke={props.color ? props.color : '#7483AB'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BedIcon;
