import { FC } from 'react';

interface HexagonPinProps {
  count: number;
  className?: string;
}

const HexagonPin: FC<HexagonPinProps> = ({ count, className }) => {
  return (
    <div className={`${className}`}>
      <div className="relative lg:hover:scale-110 transition-all duration-150">
        <svg
          width="54"
          height="69"
          viewBox="0 0 54 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.107 0.776569C26.7259 0.419303 27.4882 0.419303 28.107 0.776569L49.278 12.9996C49.8968 13.3569 50.278 14.0171 50.278 14.7317V39.1777C50.278 39.8923 49.8968 40.5525 49.278 40.9098L28.107 53.1328C27.4882 53.4901 26.7259 53.4901 26.107 53.1328L4.93612 40.9098C4.31731 40.5525 3.93612 39.8923 3.93612 39.1777V14.7317C3.93612 14.0171 4.31732 13.3569 4.93612 12.9996L26.107 0.776569Z"
            fill="#060C41"
          />
          <path
            d="M27.3302 68.3047L34.3388 57.7721H20.3215L27.3302 68.3047Z"
            fill="#060C41"
          />
        </svg>
        <div
          className={`absolute top-4 ${
            count >= 10 ? 'left-[18px]' : 'left-[22px]'
          } font-semibold text-white`}
        >
          {count}
        </div>
      </div>
    </div>
  );
};

export default HexagonPin;
