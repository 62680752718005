import { ReactNode } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  BedIcon,
  BathIcon,
  AreaIcon,
  ParkingIcon,
  StorageIcon,
} from '../../icons';
import { useGeoContext } from '../../hooks/useGeoContext';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

const SOLD_STATES = ['promised', 'notarized', 'sold'];

interface ICardWebsiteProps {
  property: any;
}

function PackageTypology({
  icon,
  value,
  label,
}: {
  icon: ReactNode;
  value: string;
  label: string;
}) {
  return (
    <div className="flex flex-row items-end">
      {icon}
      <span className="pl-2 text-slate-900 text-opacity-60 text-sm font-normal">
        <strong className="text-slate-900 text-opacity-90 text-sm font-bold">
          {value}
        </strong>{' '}
        {label}
      </span>
    </div>
  );
}

export default function CardWebsite({ property }: ICardWebsiteProps) {
  const router = useRouter();
  const { currentConfig } = useGeoContext();

  const isReserved = property.state.sellingState === 'reserved';
  const isSold = SOLD_STATES.includes(property.state.sellingState);

  return (
    <div
      className={classNames(
        'z-20 w-[330px] h-[510px] mx-4 mb-8 rounded-3xl drop-shadow-lg border border-slate-900 border-opacity-30 hover:scale-[1.01]',
        {
          'bg-slate-200 opacity-75': isReserved || isSold,
          'bg-white': !isReserved && !isSold,
        }
      )}
    >
      <Link
        href={{
          pathname: `/producto/${property.slug}`,
          query: router.query,
        }}
      >
        <div className="flex items-end rounded-t-3xl overflow-hidden w-full h-52">
          <Image
            loading="lazy"
            className=" "
            width={447}
            height={100}
            unoptimized
            src={
              property.images && property.images[0]
                ? property.images[0]
                : '/images/coming_soon.png'
            }
            alt={property.address}
          />
          <div className="absolute w-full flex flex-row  justify-between">
            {isSold && (
              <div className="z-50 w-auto py-2 px-8 bg-primary-600 text-secondary-500 text-xs font-medium rounded-tl-xl rounded-br-xl">
                VENDIDO
              </div>
            )}
            {isReserved && (
              <div className="z-50 w-auto py-2 px-8 bg-primary-600 text-secondary-500 text-xs font-medium rounded-tl-xl rounded-br-xl">
                RESERVADO
              </div>
            )}
          </div>
        </div>
        <div className="m-6">
          <div className="">
            <div className="flex justify-between items-center">
              <span className="text-slate-900 text-opacity-80 text-sm font-semibold">
                {property?.sku}
              </span>
              {property?.isExternal && (
                <span className="z-50 w-auto py-2 px-8  bg-secondary-600 text-white text-xs font-medium rounded-tl-xl rounded-br-xl">
                  EXTERNO
                </span>
              )}
            </div>
            <div className="flex justify-between items-center">
              <span className="text-primary text-[25px] font-bold">
                <NumberFormat
                  prefix={` ${currentConfig.currency} `}
                  value={property?.finances?.finalSellPrice || 0}
                  decimalScale={0}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  displayType={'text'}
                />
              </span>
            </div>
            <div className="h-10">
              <span className=" text-slate-900 text-opacity-80 text-sm font-medium">
                {property?.commune}
                {' - '}
                {property?.address}
                {property?.characteristics?.floor
                  ? `, Piso ${property?.characteristics?.floor}`
                  : ''}
              </span>
            </div>
          </div>

          {property?.finances?.minInstallmentValue > 0 && (
            <div className="flex flex-col items-center mt-3 mb-6 border-y border-y-slate-300">
              <span className="p-2 text-center text-primary text-sm font-medium">
                {currentConfig.code === 'CL'
                  ? 'CUOTAS DEL PIE DESDE'
                  : `${currentConfig.downpaymentFinancingLabel.toUpperCase()} DESDE`}{' '}
                <span>
                  <NumberFormat
                    value={property?.finances?.minInstallmentValue}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={0}
                    displayType={'text'}
                    prefix={'$'}
                  />
                </span>
              </span>
            </div>
          )}
          <div className="grid grid-cols-3 gap-x-2 gap-y-4">
            <PackageTypology
              icon={<AreaIcon size={22} color="#6D54F4" />}
              value={Math.round(
                property?.characteristics?.totalArea || 0
              )?.toString()}
              label="m2"
            />
            <PackageTypology
              icon={<BedIcon size={22} color="#6D54F4" />}
              value={property?.characteristics?.bedrooms}
              label="hab."
            />
            <PackageTypology
              icon={<BathIcon size={22} color="#6D54F4" />}
              value={property?.characteristics?.bathrooms}
              label={`baño${
                property?.characteristics?.bathrooms > 1 ? 's' : ''
              }`}
            />
            {property?.parkings > 0 && (
              <PackageTypology
                icon={<ParkingIcon size={22} color="#6D54F4" />}
                value={property?.parkings || '1'}
                label="est."
              />
            )}
            {property?.storages > 0 && (
              <PackageTypology
                icon={<StorageIcon size={22} />}
                value={property?.storages}
                label="bod."
              />
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}
