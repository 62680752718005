export const FileUploader = () => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19.5" cy="19.5" r="19" fill="white" stroke="#060C41" />
      <path
        d="M18.7564 13.6649H14.6447C14.2085 13.6649 13.7902 13.8382 13.4817 14.1466C13.1733 14.455 13 14.8734 13 15.3096V24.3553C13 24.7915 13.1733 25.2098 13.4817 25.5183C13.7902 25.8267 14.2085 26 14.6447 26H23.6904C24.1266 26 24.545 25.8267 24.8534 25.5183C25.1618 25.2098 25.3351 24.7915 25.3351 24.3553V20.2436M24.1723 12.5021C24.324 12.345 24.5055 12.2197 24.7062 12.1335C24.9068 12.0473 25.1226 12.002 25.341 12.0001C25.5594 11.9982 25.776 12.0398 25.9781 12.1225C26.1802 12.2052 26.3639 12.3273 26.5183 12.4817C26.6727 12.6361 26.7948 12.8198 26.8775 13.0219C26.9602 13.224 27.0018 13.4406 26.9999 13.659C26.998 13.8774 26.9527 14.0932 26.8665 14.2938C26.7803 14.4945 26.655 14.676 26.4979 14.8277L19.4373 21.8883H17.1117V19.5627L24.1723 12.5021Z"
        stroke="#060C41"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
