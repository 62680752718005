import React from 'react';
import CardProperty from '../CardProperty/CardProperty';

export const CardProperties = ({ properties }: any) => {
  return (
    <section className="pb-14">
      <div>
        <div className="flex flex-wrap lg:justify-start justify-center gap-4">
          {[...properties.parking, ...properties.storage].map(
            (property, index) => (
              <CardProperty key={index} property={property} />
            )
          )}
        </div>
      </div>
    </section>
  );
};
