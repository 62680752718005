const IconBedroom = (props: any) => {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group">
        <path
          id="Vector"
          d="M2.19922 16.6V19M23.7992 16.6V19M3.39922 2.8C3.39922 2.32261 3.58886 1.86477 3.92643 1.52721C4.26399 1.18964 4.72183 1 5.19922 1H20.7992C21.2766 1 21.7344 1.18964 22.072 1.52721C22.4096 1.86477 22.5992 2.32261 22.5992 2.8V9.4H3.39922V2.8Z"
          stroke="#F2006E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M15.9992 6.40039H9.99922C9.52183 6.40039 9.06399 6.59003 8.72643 6.9276C8.38886 7.26516 8.19922 7.723 8.19922 8.20039V9.40039H17.7992V8.20039C17.7992 7.723 17.6096 7.26516 17.272 6.9276C16.9344 6.59003 16.4766 6.40039 15.9992 6.40039Z"
          stroke="#F2006E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M1 11.2004C1 10.723 1.18964 10.2652 1.52721 9.9276C1.86477 9.59003 2.32261 9.40039 2.8 9.40039H23.2C23.6774 9.40039 24.1352 9.59003 24.4728 9.9276C24.8104 10.2652 25 10.723 25 11.2004V16.6004H1V11.2004Z"
          stroke="#F2006E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconBedroom;
