import { DotSpinner } from '@uiball/loaders';

interface SpinnerProps {
  color?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}

export function Spinner({ color = '#2F0CF0', size = 'medium' }: SpinnerProps) {
  const getSpinnerSize = () => {
    switch (size) {
      case 'small':
        return 20;
      case 'medium':
        return 25;
      case 'large':
        return 60;
      case 'xlarge':
        return 80;
      default:
        return 20;
    }
  };

  return (
    <div className="flex items-center justify-center">
      <DotSpinner color={color} size={getSpinnerSize()} />
    </div>
  );
}
