import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

type TabT = {
  name: string;
  href?: string;
};
interface INavTabsProps {
  tabs: TabT[];
  activeClass?: string;
  tabsClass?: string;
  hoverColor?: string;
}

export default function NavTabs({
  tabs,
  activeClass,
  tabsClass,
  hoverColor,
}: INavTabsProps): JSX.Element {
  const router = useRouter();

  return (
    <nav className="pt-5 pb-10">
      <ul className="flex relative">
        {tabs?.map(
          (tab, index) =>
            tab.href && (
              <li
                key={index}
                className={classNames(
                  'text-lg text-primary-100 text-center hover:font-bold border-b-4 px-4 md:px-6',
                  {
                    [`border-b-4 border-blue-500 ${activeClass}`]:
                      tab.href === router.asPath,
                    'border-primary-100': tab.href !== router.asPath,
                    [`hover:text-${hoverColor} hover:border-${hoverColor}`]:
                      hoverColor,
                    'hover:text-buydepa-blue hover:border-buydepa-blue':
                      !hoverColor,
                  },

                  tabsClass
                )}
              >
                <Link
                  href={tab.href}
                  className={classNames({
                    'text-buydepa-blue font-bold': tab.href === router.asPath,
                    'font-semibold': tab.href !== router.asPath,
                  })}
                >
                  {tab.name}
                </Link>
              </li>
            )
        )}
      </ul>
    </nav>
  );
}
