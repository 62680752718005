const ThumbsUp = ({ ...props }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3222 24.2548C17.1841 24.2548 18.8634 23.2245 20.0138 21.76C21.807 19.4722 24.058 17.5839 26.6227 16.216C28.2929 15.3289 29.7412 14.0076 30.4412 12.2543C30.9328 11.026 31.1852 9.71505 31.185 8.39199V6.92977C31.185 6.47028 31.3675 6.02961 31.6924 5.7047C32.0173 5.3798 32.458 5.19727 32.9175 5.19727C34.296 5.19727 35.618 5.74486 36.5927 6.71958C37.5674 7.6943 38.115 9.0163 38.115 10.3948C38.115 13.0559 37.5144 15.5761 36.4449 17.8283C35.8304 19.1173 36.692 20.7898 38.1196 20.7898H45.3407C47.7107 20.7898 49.8336 22.3929 50.0854 24.7514C50.1894 25.7262 50.2425 26.7149 50.2425 27.7198C50.252 34.0407 48.092 40.1735 44.1233 45.0933C43.227 46.2067 41.8433 46.7773 40.4157 46.7773H31.1388C30.0231 46.7773 28.9119 46.5971 27.8517 46.246L20.6583 43.8436C19.5983 43.491 18.4883 43.3116 17.3712 43.3123H13.6382M13.6382 43.3123C13.83 43.7858 14.0379 44.2478 14.2619 44.7029C14.717 45.6269 14.0817 46.7773 13.0538 46.7773H10.9563C8.90273 46.7773 6.99929 45.5807 6.401 43.6172C5.60091 40.9913 5.19532 38.2611 5.19749 35.516C5.19749 31.9286 5.87894 28.5029 7.1171 25.3566C7.82396 23.5687 9.62576 22.5223 11.55 22.5223H13.9824C15.0727 22.5223 15.7034 23.8066 15.1374 24.7399C13.1644 27.9876 12.1238 31.7159 12.1298 35.516C12.1298 38.2742 12.6657 40.9052 13.6405 43.3123H13.6382ZM32.9175 20.7898H38.115"
        stroke={props.color ? props.color : '#ffffff'}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbsUp
