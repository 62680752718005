import { Fragment, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { getFirstUrlSegment } from '@buydepa-public/utils';
import { classNames } from '../../../../../utils/class-names';
import { LogoBuydepa } from '../../../../../assets';
import Accordion from '../../Accordion';
import SocialMediaNav from '../../SocialNavs/SocialMediaNav';
import { useGeoContext } from '../../../hooks/useGeoContext';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/20/solid';
import { Popover, Transition } from '@headlessui/react';

export default function Header() {
  const { pathname, basePath } = useRouter();
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const router = useRouter();

  const handleClick = (itemName: string) => {
    if (itemName.toUpperCase() === 'VENDER') {
      router.push('/vende');
    } else if (itemName.toUpperCase() === 'COMPRAR') {
      router.push('/compra');
    } else {
      toggleDropdown(itemName);
    }
  };
  const toggleDropdown = (itemName: any) => {
    if (visibleDropdown === itemName) {
      // If the dropdown is already visible, hide it
      setVisibleDropdown(null);
    } else {
      // Otherwise, show the dropdown
      setVisibleDropdown(itemName);
    }
  };
  const { country, handleChangeCountry, countries, currentConfig } =
    useGeoContext();
  const navigation = useMemo(() => currentConfig.navigation, [currentConfig]);
  const navigationDetail = useMemo(
    () => currentConfig.navigationDetail,
    [currentConfig]
  );
  return (
    <div className="px-4 md:px-8 xl:px-0 bg-primary">
      <Popover.Group as="header" className="relative w-full mx-auto max-w-7xl">
        <Popover>
          <div className="bg-primary">
            <nav
              className="relative flex items-center justify-between"
              aria-label="Global"
            >
              <div className="flex items-center justify-around">
                <div>
                  <Link href={currentConfig.href}>
                    <div className="cursor-pointer">
                      <span className="sr-only">Buydepa logo</span>
                      <LogoBuydepa width="150" height="60" color="#19EC93" />
                    </div>
                  </Link>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="hidden  md:flex space-x-10 xl:ml-10 mr-4">
                  {navigation.map((item) => (
                    <div key={item.name} className="relative inline-block">
                      <button
                        onClick={() => handleClick(item.name)}
                        className="inline-flex items-center justify-center px-3 py-2 mx-2 text-base font-regular text-secondary rounded-md hover:text-buydepa-blue hover:bg-secondary focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white"
                      >
                        <span className="uppercase">{item.name}</span>
                      </button>
                      {item.children && visibleDropdown === item.name && (
                        <div className="absolute top-full right-0 p-2 w-auto bg-white rounded-lg shadow-md z-20">
                          <div className="flex flex-col">
                            {item.children.map((subItem) => (
                              <a
                                key={subItem.name}
                                href={subItem.href}
                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                              >
                                {subItem.name}
                                {subItem.icon && (
                                  <subItem.icon className="h-5 w-5 text-red-500 ml-1" />
                                )}
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="items-center hidden md:block">
                  <Popover>
                    <Popover.Button className="inline-flex items-center justify-center p-3 mx-4 text-txLight hover:rounded-md hover:bg-secondary focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white w-auto">
                      <span className="sr-only">Select Language</span>
                      {country && (
                        <div className="flex flex-row items-center px-2">
                          <span className="px-2 ">
                            {
                              countries.find((item) => item.code === country)
                                ?.flag
                            }
                          </span>
                          {/*
                          Commented until we have the website in English
                          <p className="text-[15px] font-bold">| ES</p> */}
                        </div>
                      )}
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          strokeWidth="1"
                          stroke="currentColor"
                          className="w-4 h-4 ml-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 17l-7-7h14z"
                          />
                        </svg>
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="duration-150 ease-out"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="duration-100 ease-in"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Popover.Panel
                        focus
                        className=" absolute top-12 right-26 p-2 w-auto transition origin-top transform z-20"
                      >
                        <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                          <div className="flex flex-col items-center px-5 pt-4 m-2">
                            {/*
                             // Select Language commited until translate the page into English //

                             <div className=" ">
                              <p className="text-sm font-semibold text-buydepa-primary mb-2">
                                Cambiar idioma
                              </p>
                              <p className="text-sm font-normal text-buydepa-primary mb-2">
                                Español - ES
                              </p>
                            </div> */}
                            <div>
                              <p className="text-sm font-semibold text-buydepa-primary mb-2">
                                Cambiar país
                              </p>
                              {countries.map((item: any) => (
                                <Fragment key={item.code}>
                                  <div className="flex flex-row focus:outline-none">
                                    <button
                                      onClick={() => handleChangeCountry(item)}
                                    >
                                      <span
                                        className={classNames(
                                          'text-base cursor-pointer focus:outline-none hover:text-buydepa-blue',
                                          country === item.code
                                            ? 'text-buydepa-primary text-sm font-normal'
                                            : 'text-slate-500 text-sm font-normal'
                                        )}
                                      >
                                        <div className="flex flex-row items-center !focus:outline-none">
                                          <div>{item.flag}</div>
                                          <div className="px-3 pb-1">
                                            {item.countryFilterValue}
                                          </div>
                                        </div>
                                      </span>
                                    </button>
                                  </div>
                                </Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>
                <div
                  className={`flex items-center ${
                    currentConfig.code !== 'CL' ? 'md:hidden' : ''
                  }`}
                >
                  <Popover.Button className="inline-flex items-center justify-center px-4 py-2 ml-4 text-txDark-100 bg-secondary rounded-md hover:bg-secondary-300 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </nav>
          </div>
          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="fixed inset-y-0 right-0 top-0 h-screen w-full md:w-1/2 xl:w-1/4 transition origin-top transform !z-[100] text-center"
            >
              <div className=" overflow-hidden h-screen bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                <div className="flex flex-row-reverse items-center justify-between px-5 pt-4">
                  <div>
                    <Popover.Button className="p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 outline-none">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <LogoBuydepa width="150" height="75" className="ml-" />
                </div>
                <svg
                  className="mb-2 px-4 mx-auto"
                  width="300"
                  height="2"
                  viewBox="0 0 419 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path opacity="0.2" d="M0 1L419 0.999963" stroke="#7483AB" />
                </svg>
                <div>
                  {navigation.map((item: any) => (
                    <div key={item.name} className="sm:hidden px-5">
                      <Link
                        href={item.href}
                        className="block px-3 text-base py-2 xl:py-4 font-regular text-buydepa-primary rounded-md hover:text-buydepa-blue hover:bg-gray-50"
                      >
                        <span
                          className={classNames(
                            'text-base uppercase',
                            getFirstUrlSegment(basePath) ===
                              getFirstUrlSegment(item.href) ||
                              getFirstUrlSegment(pathname) ===
                                getFirstUrlSegment(item.href)
                              ? 'text-gray-500 font-regular'
                              : 'text-buydepa-primary'
                          )}
                        >
                          {item.name}
                        </span>
                      </Link>
                    </div>
                  ))}
                  {navigationDetail.map((item: any, index: number) => (
                    <div key={index} className="px-5 ">
                      <Link
                        href={item.href}
                        className="block px-3 text-base py-2 xl:py-4 font-regular text-buydepa-primary rounded-md hover:text-buydepa-blue hover:bg-gray-50"
                      >
                        <span
                          className={classNames(
                            'text-base uppercase',
                            getFirstUrlSegment(basePath) ===
                              getFirstUrlSegment(item.href) ||
                              getFirstUrlSegment(pathname) ===
                                getFirstUrlSegment(item.href)
                              ? 'text-gray-500 font-regular'
                              : 'text-buydepa-primary'
                          )}
                        >
                          {item.name}
                        </span>
                      </Link>
                    </div>
                  ))}
                  <Accordion
                    className="flex !justify-center xl:hidden focus:outline-none"
                    title={
                      <>
                        <span className="sr-only">Seleccionar lenguaje</span>
                        {country && (
                          <div className="flex flex-row items-center px-2">
                            <span className="px-2 ">
                              {
                                countries.find((item) => item.code === country)
                                  ?.flag
                              }
                            </span>
                            {/*
                          Commented until we have the website in English
                          <p className="text-[15px] font-bold">| ES</p> */}
                          </div>
                        )}
                      </>
                    }
                  >
                    <div className="m-4 flex justify-center">
                      <div className="flex flex-col px-5 m-2 text-start">
                        {/*

                        // Select Language commited until translate the page into English //

                        <div>
                          <p className="text-sm font-semibold text-buydepa-primary mb-2">
                            Cambiar idioma
                          </p>
                          <p className="text-sm font-normal text-buydepa-primary mb-2">
                            Español - ES
                          </p>
                        </div> */}

                        <p className="text-sm font-semibold text-buydepa-primary mb-2">
                          Cambiar país
                        </p>
                        {countries.map((item: any) => (
                          <Fragment key={item.code}>
                            <div className="flex flex-row focus:outline-none">
                              <button onClick={() => handleChangeCountry(item)}>
                                <span
                                  className={classNames(
                                    'text-base cursor-pointer hover:text-buydepa-blue !focus:outline-none',
                                    country === item.code
                                      ? 'text-buydepa-primary text-sm font-normal'
                                      : 'text-slate-500 text-sm font-normal'
                                  )}
                                >
                                  <div className="flex flex-row items-center">
                                    <div>{item.flag}</div>
                                    <div className="px-3 pb-1">
                                      {item.countryFilterValue}
                                    </div>
                                  </div>
                                </span>
                              </button>
                            </div>
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </Accordion>

                  <div className="flex items-center justify-center flex-col">
                    <svg
                      className=" m-4"
                      width="300"
                      height="2"
                      viewBox="0 0 419 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M0 1L419 0.999963"
                        stroke="#7483AB"
                      />
                    </svg>
                    <div className="flex mt-2">
                      <SocialMediaNav />
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </Popover.Group>
    </div>
  );
}
