import React from 'react';

interface IPropertiesProps {
  property: IProperty;
}
interface IProperty {
  sku: string;
  listPrice: number;
  unit: number;
  floor: number;
}

const CardProperty = ({ property }: IPropertiesProps) => {
  return (
    <div className="border rounded-[10px] mt-4 p-3 w-[216px] ">
      <div className="flex flex-col border-b-2 pb-2">
        <div className="flex justify-between">
          <p className="text-sm text-primary-600 font-semibold">
            {property.sku.startsWith('P') ? 'Estacionamiento' : 'Bodega'}
          </p>
          <p className="text-sm text-primary-600 font-semibold">
            N° {property.unit || NaN}
          </p>
        </div>
        <small className="text-sm text-primary-600 my-1">
          SKU:{property.sku}
        </small>
        <div className="flex justify-between pt-1">
          {/* <small className="text-primary-600 flex gap-2">
            <IconRuler />
            {property.unit || 0}
          </small> */}
          <small className="text-primary-600 flex gap-2">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.42892 7.15385L1 9L4.42892 10.8462M4.42892 7.15385L9 9.61538L13.5711 7.15385M4.42892 7.15385L1 5.30769L9 1L17 5.30769L13.5711 7.15385M4.42892 10.8462L1 12.6923L9 17L17 12.6923L13.5711 10.8462M4.42892 10.8462L9 13.3077L13.5711 10.8462M13.5711 7.15385L17 9L13.5711 10.8462"
                stroke="#7483AB"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {property.floor || 0}
          </small>
        </div>
      </div>
      <p className="text-sm text-right text-primary-600 font-semibold mt-1">
        UF {property.listPrice || 0}
      </p>
    </div>
  );
};

export default CardProperty;
