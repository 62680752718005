import { useRouter } from 'next/router';
import {
  RectangleGroupIcon,
  BuildingOffice2Icon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { useMemo } from 'react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export interface SidePanelProps {
  isCollapsed: boolean;
  onToggle: () => void;
}

export function SidePanel({ isCollapsed, onToggle }: SidePanelProps) {
  const router = useRouter();

  const navigation = useMemo(
    () => [
      {
        name: 'Inicio',
        href: '/brokers/dashboard',
        icon: <RectangleGroupIcon width={24} />,
        current: router.pathname === '/dashboard',
      },
      {
        name: 'Stock',
        href: '/brokers/dashboard/stock',
        icon: <BuildingOffice2Icon width={24} />,
        current:
          router.pathname === '/dashboard/stock' ||
          router.pathname === '/dashboard/quotations' ||
          router.pathname === '/dashboard/create-quotation' ||
          router.pathname === '/dashboard/create-quotation/success' ||
          router.pathname === '/dashboard/visits' ||
          router.pathname.startsWith('/dashboard/schedule/') ||
          router.pathname.startsWith('/dashboard/schedule/success') ||
          router.pathname.startsWith('/dashboard/create-reserve/') ||
          router.pathname.startsWith('/dashboard/create-reserve/success'),
      },
      {
        name: 'Ayuda',
        href: '/brokers/dashboard/faq',
        icon: <QuestionMarkCircleIcon width={24} />,
        current: router.pathname === '/dashboard/faq',
      },
      {
        name: 'Salir',
        href: '/brokers/api/auth/logout',
        icon: <ArrowLeftOnRectangleIcon width={24} />,
        current: false,
      },
    ],
    [router.pathname]
  );

  return (
    <nav>
      <ul className="space-y-1 flex flex-row items-center justify-center w-full gap-2 xl:flex-col flex-wrap">
        <li className="hidden xl:block">
          <span
            onClick={onToggle}
            className={classNames(
              `text-gray-700 hover:text-blue-600 hover:bg-gray-50 group flex rounded-md p-2 text-sm font-semibold ${
                !isCollapsed && 'gap-x-3'
              }`
            )}
          >
            <i
              className={classNames(
                'text-gray-400 group-hover:text-blue-600 h-6'
              )}
              aria-hidden="true"
            />
            <Bars3Icon width={24} />
            {!isCollapsed && 'Menú'}
          </span>
        </li>
        {navigation.map((item) => (
          <li key={item.name}>
            <a
              href={item.href}
              className={classNames(
                item.current
                  ? 'bg-gray-50 text-blue-600'
                  : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
                `group flex rounded-md p-2 text-sm font-semibold ${
                  !isCollapsed && 'gap-x-3'
                }`
              )}
            >
              <i
                className={classNames(
                  item.current
                    ? 'text-blue-600'
                    : 'text-gray-400 group-hover:text-blue-600',
                  'h-6'
                )}
                aria-hidden="true"
              />
              {item.icon}
              {!isCollapsed && item.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
