import {
  Fragment,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Combobox } from '@headlessui/react';
import {
  CheckIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { useField } from 'formik';
import cn from 'classnames';
import debounce from 'lodash.debounce';
import { OptionT } from '../SelectFormik/SelectFormik';
import { Spinner } from '../Spinner/Spinner';

export interface SearchComboboxProps {
  name: string;
  label?: string;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  options: OptionT[];
  onChange: (search: string) => void;
  onChangeSelect?: (value: string | number) => void;
  onCreateOption?: () => void;
  createOptionLabel?: string;
  defaultSelectedOption?: OptionT;
  autoComplete?: string;
  noDataText?: string;
  className?: string;
  withSearchIcon?: boolean;
}
const SearchCombobox = forwardRef(
  (
    {
      name,
      label,
      placeholder,
      loading,
      disabled,
      options,
      onChange,
      onChangeSelect,
      onCreateOption,
      createOptionLabel,
      defaultSelectedOption,
      autoComplete,
      noDataText,
      className,
      withSearchIcon,
    }: SearchComboboxProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [field, { error, touched }, { setValue }] = useField({
      name,
    });
    const [selectedOption, setSelectedOption] = useState<OptionT | null>(null);
    const [focus, setFocus] = useState(false);

    useEffect(
      () => {
        if (defaultSelectedOption) {
          setSelectedOption(defaultSelectedOption);
          setValue(defaultSelectedOption.value);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [defaultSelectedOption]
    );

    const inputContainerClassName = cn(
      'w-full cursor-default py-1.5 pl-3 rounded-3xl border border-light-gray-400 pr-2 text-left flex items-center h-full',
      {
        '!pr-10': loading,
        '!bg-gray-50 cursor-not-allowed	': disabled,
      },
      className
    );

    const inputClassName = cn(
      'border-none h-full w-full bg-transparent border-transparent focus:border-transparent focus:ring-0',
      {
        'pl-5': withSearchIcon,
        'cursor-not-allowed	': disabled,
      }
    );

    const labelClassName = cn(
      'ease-in duration-100 ml-4 translate-y-2 w-fit text-sm text-primary-600 font-normal',
      {
        '!-translate-y-1 !bg-transparent': focus,
        'backdrop-blur-xl px-2': !focus,
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeInput = useCallback(
      debounce((value: string) => onChange(value), 500),
      []
    );

    const handleChangeSelect = (option: OptionT) => {
      setSelectedOption(option);
      setValue(option.value);
      onChangeSelect && onChangeSelect(option.value);
    };

    return (
      <div className="relative">
        <Combobox
          value={selectedOption}
          onChange={handleChangeSelect}
          name={field.name}
          disabled={disabled}
        >
          {label && (
            <div className={labelClassName}>
              <Combobox.Label>{label}</Combobox.Label>
            </div>
          )}
          <Combobox.Button className={inputContainerClassName}>
            {withSearchIcon && <MagnifyingGlassIcon width={20} height={20} />}
            <Combobox.Input
              ref={ref}
              autoComplete={autoComplete}
              onChange={(event) => handleChangeInput(event.currentTarget.value)}
              placeholder={placeholder}
              displayValue={(option: OptionT) => option && option.label}
              className={inputClassName}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
            {loading && (
              <div className="justify-end -mr-6">
                <Spinner size="small" />
              </div>
            )}
          </Combobox.Button>
          {touched && error && (
            <div className="error text-red-500 text-sm">{error}</div>
          )}
          <Combobox.Options className="absolute border border-light-gray-400 z-100 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow focus:outline-none sm:text-sm">
            {options?.length > 0 ? (
              options.map((option: OptionT) => (
                <Combobox.Option
                  key={option.value}
                  value={option}
                  as={Fragment}
                >
                  {({ active }) => (
                    <li className="relative">
                      <span
                        className={cn(
                          active ? 'bg-blue-500 text-white' : 'text-gray-900',
                          option.value === field.value
                            ? 'font-semibold'
                            : 'font-normal',
                          'block truncate py-2 pl-3 pr-9'
                        )}
                      >
                        {option.label}
                      </span>
                      {option.value === field.value && (
                        <span
                          className={cn(
                            active ? 'text-white' : 'text-blue-500',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </li>
                  )}
                </Combobox.Option>
              ))
            ) : (
              <>
                <div className="p-2">
                  {noDataText || 'No se han encontrado datos'}
                </div>
                {onCreateOption && (
                  <button
                    className="p-2 border-t border-gray-200 w-full text-left flex gap-2 items-center hover:bg-ghost-blue-300"
                    onClick={onCreateOption}
                  >
                    <PlusCircleIcon width={24} />
                    {createOptionLabel || 'Crear nuevo'}
                  </button>
                )}
              </>
            )}
          </Combobox.Options>
        </Combobox>
      </div>
    );
  }
);

export default SearchCombobox;
