import { MutableRefObject, useEffect, useState } from 'react';
import { KeenSliderInstance, KeenSliderPlugin, useKeenSlider } from 'keen-slider/react';
import Image from 'next/image';
import 'keen-slider/keen-slider.min.css';
import Link from 'next/link';
import { Fragment } from 'react';
import classNames from 'classnames';
import { ArrowLongLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Button } from '../Button/Button';
import Picture from '../Picture/Picture';

interface ICarouselProps {
  images: {
    src: any;
    alt: string;
    height?: number;
    width?: number;
    href?: string;
  }[];
  setOpen: (open: boolean) => void;
  slidesPerView?: number;
  showDots?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  classes?: string;
  initialImage?: any;
  backgroundImages?: boolean;
  showTotal?: boolean;
  dotsPosition?: 'top' | 'bottom';
  showArrows?: boolean;
}

function Arrow(props: {
  disabled: boolean;
  left?: boolean;
  onClick: () => void;
}) {
  return props.left ? (
    <button onClick={props.onClick} className='bg-primary-600/5 rounded-r-full py-4 arrow'>
      <ChevronLeftIcon
        className={classNames(
          'h-10 w-10 cursor-pointer',
          props.disabled ? 'text-primary-200' : 'text-primary-300',
        )}
      />
    </button>
  ) : (
    <button onClick={props.onClick} className='bg-primary-600/5 rounded-l-full py-4 arrow right-0'>
      <ChevronRightIcon
        onClick={props.onClick}
        className={classNames(
          'h-10 w-10 cursor-pointer',
          props.disabled ? 'text-primary-200' : 'text-primary-300',
        )}
      />
    </button>
  );
}
function ThumbnailPlugin(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active')
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active')
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}

const CarouselGalleryModal = ({
  images,
  setOpen,
  slidesPerView = 1,
  loop = false,
  classes = '',
  initialImage = 0,
  backgroundImages = false,
  showTotal = true,
  showArrows = true,
}: ICarouselProps) => {
  const [mounted, setMounted] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(initialImage);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: initialImage,
      loop: loop,
      slides: {
        perView: 'auto',
        spacing: 0,
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
  );
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: initialImage,
      slides: {
        origin: 'center',
        perView: 'auto',
        spacing:6,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  )

  useEffect(() => {
    setMounted(true);
    // eslint-disable-next-line prettier/prettier
  }, [images]);

  console.log(initialImage)
  return (
    <div className="flex h-full">
      <div className='flex flex-col justify-between w-full md:mx-auto h-screen'>
        {mounted && (
          <div className="navigation-wrapper flex flex-col pb-4 h-4/5">
            {showTotal && (
              <div className="text-primary-400 flex justify-between mb-4 px-12">
                <Button variant='link' size='small' className='font-thin mb-1' onClick={()=>setOpen(false)}><ArrowLongLeftIcon className='w-5 mr-2' />Volver</Button>
                <div>
                  {currentSlide+1 }/{images.length}
                </div>
              </div>
            )}

            <div ref={sliderRef} className="keen-slider flex h-full w-full overflow-x-auto snap-x snap-mandatory">
              {images.map(({ src, alt, href }, index) => {
                const clickable = (
                  <div
                    className={classNames(
                      'keen-slider__slide !w-full !h-full snap-center shrink-0 flex justify-center',
                    )}
                  >
                    <Link
                      href={href as any}
                      passHref
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className='flex items-center mx-auto'>
                        <Image
                          src={src}
                          alt={alt}
                          quality={100}
                          width={1079}
                          height={721}
                          className='object-contain w-auto h-full rounded-lg overflow-hidden'
                        />
                      </div>
                    </Link>
                  </div>
                );
                const nonClickable = (
                  <div
                    className={classNames(
                      'keen-slider__slide !w-full !h-full snap-center shrink-0 flex justify-center',
                    )}
                  >
                    <div className='flex items-center mx-auto relative' onClick={() => instanceRef.current?.next()}>
                      <Picture
                        src={src}
                        alt={alt}
                        quality={100}
                        width={1079}
                        height={721}
                        className='object-contain w-auto h-full rounded-lg overflow-hidden'
                      />
                    </div>
                  </div>
                );
                return (
                  <Fragment key={index}>
                    {href ? clickable : nonClickable}
                  </Fragment>
                );
              })}
            </div>  
            {loaded && instanceRef.current && showArrows && (
              <>
                <Arrow
                  left
                  onClick={() => instanceRef.current?.prev()}
                  disabled={currentSlide === 0}
                />

                <Arrow
                  onClick={() => instanceRef.current?.next()}
                  disabled={
                    currentSlide ===
                    instanceRef.current.track.details.slides.length - 1
                  }
                />
              </>
            )}          
          </div>
        )}

        

        {mounted && (
          <div ref={thumbnailRef} className="keen-slider thumbnail relative w-full overflow-x-auto h-1/5 flex grow">
            {images.map(({ src, alt, width, height, href }, index) => {
              return (
                <div 
                  key={index}
                  className="keen-slider__slide"
                  style={{ width: '90px', minWidth:'90px', maxWidth:'90px', height: '90px', minHeight: '90px', maxHeight: '90px' }}
                >
                  <Image
                    src={src}
                    alt={alt}
                    width={90}
                    height={90}
                    className='h-full rounded-md overflow-hidden'
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
      
    </div>
  );
}


export default CarouselGalleryModal;