import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
} from 'react';
import { FilterIcon } from '../../../../assets/FilterIcon';
import { SearchIcon } from '../../../../assets/SearchIcon';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Button } from '../Button/Button';

interface SearchComponentProps {
  className?: string;
  label?: string;
  labelClassName?: string;
  value?: string;
  error?: string;
  disabled?: boolean;
  panelOpen?: () => void;
  handleSearch?: MouseEventHandler<HTMLButtonElement>;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  handleKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  [key: string]: any;
}

export const SearchComponent: FC<SearchComponentProps> = ({
  value,
  label,
  labelClassName,
  error,
  disabled,
  panelOpen,
  handleSearch,
  handleChange,
  handleKeyUp,
  ...props
}) => {
  return (
    <div className="flex items-start mb-5">
      {panelOpen && (
        <span onClick={panelOpen}>
          <FilterIcon />
        </span>
      )}
      <div className="pl-4">
        <label
          className={`block text-sm font-base text-primary-600 font-semibold pl-4 ${labelClassName} `}
        >
          {label}
        </label>
        <div className="flex flex-col">
          <input
            {...props}
            type="text"
            value={value}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
          {error && <span className="text-red-500">{error}</span>}
        </div>
      </div>
      <div className={handleKeyUp ? 'pl-4 pt-5' : 'pl-4'}>
        <Button
          variant={panelOpen ? 'border' : 'fill'}
          color="primary"
          className="h-10"
          onClick={handleSearch}
          disabled={disabled}
        >
          {panelOpen ? 'Buscar' : <MagnifyingGlassIcon width={20} />}
        </Button>
      </div>
    </div>
  );
};

export default SearchComponent;
