const IconCalendar = ({ ...props }) => (
  <svg
    width="23"
    height="25"
    viewBox="0 0 23 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9148 3.3H18.7183V1H16.3251V3.3H6.75244V1H4.35928V3.3H3.16269C1.83449 3.3 0.781497 4.335 0.781497 5.6L0.769531 21.7C0.769531 22.31 1.02167 22.895 1.47047 23.3263C1.91928 23.7577 2.52799 24 3.16269 24H19.9148C21.2311 24 22.308 22.965 22.308 21.7V5.6C22.308 4.335 21.2311 3.3 19.9148 3.3ZM19.9148 21.7H3.16269V10.2H19.9148V21.7ZM19.9148 7.9H3.16269V5.6H19.9148V7.9ZM17.5217 13.65H11.5388V19.4H17.5217V13.65Z"
      fill={props.color || '#2F0CF0'}
      stroke="white"
    />
  </svg>
);

export default IconCalendar;
