const Ticket= ({ ...props }) => {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 14.5L6 22.5L20.5 3"
        stroke={props.color ? props.color : '#3A5AFF'}
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Ticket
