import { useState } from 'react';
import Image from 'next/image';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

interface BeforeAfterSliderI {
  beforeSrc: string;
  afterSrc: string;
  containerClassName?: string;
}

const BeforeAfterSlider = ({
  beforeSrc,
  afterSrc,
  containerClassName,
}: BeforeAfterSliderI): JSX.Element => {
  const [position, setPosition] = useState<number>(50);

  return (
    <section
      className={`place-content-center relative overflow-hidden rounded-2xl ${containerClassName}`}
    >
      <div className="max-w-[1350px] max-h-[90vh]">
        <Image
          src={beforeSrc}
          style={{ width: `${position}%` }}
          alt="Real state agent"
          width={1350}
          height={500}
          quality={100}
          className={'h-[100%] object-cover object-left absolute inset-0'}
        />
        <Image
          src={afterSrc}
          alt="apartment"
          width={1350}
          height={500}
          quality={100}
          className="w-[100%] h-[100%] object-cover object-left"
        />
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value={position}
        className="absolute inset-0 cursor-pointer opacity-0 w-[100%] h-[100%]"
        aria-label="Percentage of before photo shown"
        onInput={(e) => setPosition(parseInt(e.currentTarget.value))}
      />
      <div
        className={
          'absolute inset-0 w-[2px] h-[100%] bg-black bg-opacity-50 z-10 -translate-x-1/2 pointer-events-none'
        }
        style={{ left: `${position}%` }}
      />
      <div
        aria-hidden
        className={
          'absolute bg-slate-900 bg-opacity-60 backdrop-blur-sm w-10 h-10 xl:w-16 xl:h-16 rounded-full grid place-items-center top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none z-20 shadow-md'
        }
        style={{ left: `${position}%` }}
      >
        <div className="flex items-center">
          <ChevronLeftIcon
            width={25}
            height={25}
            className="w-5 h-5 xl:w-6 xl:h-6"
            color="white"
          />
          <ChevronRightIcon
            width={25}
            height={25}
            className="w-5 h-5 xl:w-6 xl:h-6"
            color="white"
          />
        </div>
      </div>
    </section>
  );
};

export default BeforeAfterSlider;
