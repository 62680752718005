import { AppProps } from 'next/app';
import { ManagedUIContext, RouterProgressBar } from '@buydepa-public/ui';
import { GlobalHeadScripts } from '@/components/atoms/GlobalScripts';
import client from '@/apollo-client';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';

import '@/styles/global.scss';
import '@/styles/dateTimePicker.scss';
import 'react-toastify/dist/ReactToastify.css';

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      {process.env.NEXT_PUBLIC_ENV_NAME === 'PROD' && <GlobalHeadScripts />}
      <ToastContainer />
      <ApolloProvider client={client}>
        <ManagedUIContext>
          <RouterProgressBar />
          <Component {...pageProps} />
        </ManagedUIContext>
      </ApolloProvider>
    </>
  );
}
