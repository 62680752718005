import { Button } from '../Button/Button';

const QUANTITY_OPTIONS = [1, 2, 3, 4];

interface MultiOptionalProps {
  values: number[];
  onSelected: (values: number[]) => void;
}

function MultiOptional({ values, onSelected }: MultiOptionalProps) {
  const handleClick = (option: number) => {
    let newValues = [...values];
    if (newValues.includes(option)) {
      newValues = newValues.filter((value) => value !== option); // Remove the option
    } else {
      newValues.push(option);
    }
    onSelected(newValues);
  };

  return (
    <div className="flex justify-around">
      {QUANTITY_OPTIONS.map((option, index) => {
        return (
          <Button
            key={index}
            color="primary"
            type="button"
            className="!p-0 w-10 h-10 justify-center hover:bg-primary-400 hover:border-primary-400"
            variant={`${values.includes(option) ? 'fill' : 'border'}`}
            onClick={() => handleClick(option)}
          >
            {index !== 3 ? option : `+${option}`}
          </Button>
        );
      })}
    </div>
  );
}

export default MultiOptional;
