const FileIcon = ({ ...props }) => {
  return (
    <svg
      width="47"
      height="58"
      viewBox="0 0 47 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46 35.4615V27.9231C46 25.3525 44.9333 22.8872 43.0345 21.0696C41.1357 19.2519 38.5603 18.2308 35.875 18.2308H31.375C30.4799 18.2308 29.6215 17.8904 28.9885 17.2845C28.3556 16.6786 28 15.8569 28 15V10.6923C28 8.12175 26.9333 5.65647 25.0345 3.83881C23.1356 2.02115 20.5603 1 17.875 1H12.25M12.25 37.6154H34.75M12.25 46.2308H23.5M19 1H4.375C2.512 1 1 2.44738 1 4.23077V53.7692C1 55.5526 2.512 57 4.375 57H42.625C44.488 57 46 55.5526 46 53.7692V26.8462C46 19.9913 43.1554 13.4173 38.0919 8.57016C33.0284 3.72307 26.1608 1 19 1Z"
        stroke={props.color ? props.color : '#7483AB'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileIcon;
