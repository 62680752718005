import React from 'react';

export const CardInfoQuotation = () => {
  return (
    <div className="mt-14">
      <p className="text-2xl text-primary-600 font-medium mb-8">
        Información de la cotización
      </p>
      <span className="text-lg text-primary-600 font-medium">
        Propiedades a cotizar:
      </span>
      <div className="mt-10">
        {/* <ScarlettTable
          data={dummyData()}
          columns={columns}
          loading={false}
          totalPages={0}
        /> */}
      </div>
    </div>
  );
};
