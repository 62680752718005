import SelectFormik from '../SelectFormik/SelectFormik';
import InputFormik from '../InputFormik/InputFormik';
import { Button } from '../Button/Button';
import { FormikValues } from 'formik';

export const NewClientForm = ({ values }: { values: FormikValues }) => {
  return (
    <>
      <p className="text-3xl text-primary-600">Agregar nuevo cliente</p>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-14">
        <SelectFormik
          label="Tipo de cliente"
          name="type"
          options={[
            { label: 'Persona', value: 'natural' },
            { label: 'Empresa', value: 'society' },
          ]}
        />
        <InputFormik label="RUT" name="dni" type="text" disabled />
      </div>
      {values.type === 'natural' ? (
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-8">
          <InputFormik label="Nombre" name="firstName" type="text" />
          <InputFormik label="Apellido" name="lastName" type="text" />
        </div>
      ) : (
        <div className="grid lg:grid-cols-1 grid-cols-1 gap-8 mt-8">
          <InputFormik label="Razón social" name="companyName" type="text" />
        </div>
      )}
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mt-8">
        <InputFormik label="Telefono" name="phoneNumber" type="text" />
        <InputFormik label="Mail" name="email" type="text" />
      </div>
      <div className="flex justify-end mt-10">
        <Button variant="border" color="primary" type="submit">
          Agregar cliente
        </Button>
      </div>
    </>
  );
};
