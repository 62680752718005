import { print } from 'graphql';
import axios from 'axios';
import { format } from 'rut.js';
import { graphqlRequest } from '../graphql';
import { flattenDict } from './flattenDict';
import { formatDate } from './date';
import { formatName } from './formatName';
import { gql } from '@apollo/client';

const ORIENTATIONS: { [key: string]: string } = {
  N: 'NORTE',
  S: 'SUR',
  E: 'ESTE',
  O: 'OESTE',
};

type QuotationPdfResultT = {
  status: number;
  data: any;
};

type QuotationPdfParamsT = {
  accessToken: string;
  financesApiUrl: string;
  date: any;
  salesApiUrl: string;
  pdfApiUrl: string;
  pdfApiKey: string;
  country: string;
  quotationUuid: string;
};

type QuotationDetails = {
  discountPercentage: number;
  downpaymentDiscountPercentage: number;
  code: string;
  createdAt: string;
  discount: number;
  downpaymentDiscount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any;
  clientName: string;
  clientEmail: string;
  dni: string;
  phoneNumber: string;
  broker: any;
};

export const transformQuotation = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
): QuotationDetails => {
  const quotationByUuid = data?.quotationByUuid || {};

  return {
    discountPercentage: quotationByUuid?.discountPercentage || 0,
    discount: quotationByUuid?.discount || 0,
    downpaymentDiscount: quotationByUuid?.downpaymentDiscount || 0,
    downpaymentDiscountPercentage:
      quotationByUuid?.downpaymentDiscountPercentage || 0,
    code: quotationByUuid?.quotationCode || '',
    createdAt: formatDate(quotationByUuid?.createdAt) || '',
    properties: quotationByUuid?.package,
    clientEmail: quotationByUuid?.client?.email || '',
    clientName: formatName(quotationByUuid?.client) || '',
    dni: quotationByUuid?.client?.dni || '',
    phoneNumber: quotationByUuid?.client?.phoneNumber || '',
    broker: quotationByUuid?.broker,
  };
};

const GET_UF_PRICE = gql`
  query GetUFPriceByDate($date: String!) {
    getUF(date: $date) {
      value
    }
  }
`;

const GET_QUOTATION_FOR_PDF = gql`
  query GetQuotationByUuid($uuid: String!) {
    quotationByUuid(uuid: $uuid) {
      data {
        attributes {
          quotationCode
          discountPercentage
          discount
          downpaymentDiscount
          downpaymentDiscountPercentage
          createdAt
          client {
            data {
              attributes {
                firstName
                lastName
                dni
                email
                phoneNumber
              }
            }
          }
          broker {
            data {
              attributes {
                firstName
                lastName
                dni
                phoneNumber
                broker_company {
                  data {
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          package {
            data {
              attributes {
                listPrice
                apartment {
                  data {
                    attributes {
                      listPrice
                      sku
                      unit
                      tower
                      orientation
                      bathrooms
                      bedrooms
                      totalArea
                      interiorArea
                      floor
                      images
                      project {
                        data {
                          attributes {
                            constructionYear
                            address {
                              data {
                                attributes {
                                  street
                                  number
                                  countryCode
                                  chilean_address {
                                    data {
                                      attributes {
                                        chilean_commune {
                                          data {
                                            attributes {
                                              chilean_region {
                                                data {
                                                  attributes {
                                                    name
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                storages {
                  data {
                    attributes {
                      listPrice
                      unit
                    }
                  }
                }
                parkings {
                  data {
                    attributes {
                      listPrice
                      unit
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const generateQuotationPdf = async ({
  accessToken,
  financesApiUrl,
  date,
  salesApiUrl,
  pdfApiUrl,
  pdfApiKey,
  country,
  quotationUuid,
}: QuotationPdfParamsT): Promise<QuotationPdfResultT> => {
  const ufData = await graphqlRequest(
    financesApiUrl,
    print(GET_UF_PRICE),
    { date },
    accessToken
  );
  const ufPrice = ufData?.data?.getUF?.value || 0;

  if (!quotationUuid) {
    return {
      status: 400,
      data: {
        error: 'Quotation UUID was not specified',
      },
    };
  }

  const quotationData = await graphqlRequest(
    salesApiUrl,
    print(GET_QUOTATION_FOR_PDF),
    { uuid: quotationUuid },
    accessToken
  );

  if (!quotationData?.data?.quotationByUuid) {
    return {
      status: 400,
      data: {
        error: 'Quotation not found',
      },
    };
  }

  const quotation = transformQuotation(flattenDict(quotationData));

  const orientation =
    ORIENTATIONS[quotation?.properties?.apartment?.orientation] || '';

  const payload = {
    template: 'sales_quotation.html',
    quotation: {
      code: quotation?.code.toString(),
      date: quotation?.createdAt.toString(),
    },
    user: {
      name: quotation?.clientName.toString(),
      rut: format(quotation?.dni).toString(),
      phone: quotation?.phoneNumber.toString(),
      email: quotation?.clientEmail.toString(),
    },
    broker: {
      company: quotation?.broker.broker_company?.name.toString(),
      name:
        quotation?.broker.firstName +
        ' ' +
        quotation?.broker.lastName.toString(),
    },
    package: {
      apartment: {
        address: `${quotation?.properties?.apartment?.project?.address?.street} ${quotation?.properties?.apartment?.project?.address?.number}`,
        unit: quotation?.properties?.apartment?.unit.toString(),
        bedrooms: quotation?.properties?.apartment?.bedrooms.toString(),
        bathrooms: quotation?.properties?.apartment?.bathrooms.toString(),
        construction_year:
          quotation?.properties?.apartment?.project?.constructionYear.toString(),
        exterior_area: (
          quotation?.properties?.apartment?.totalArea -
          quotation?.properties?.apartment?.interiorArea
        ).toString(),
        orientation,
        interior_area:
          quotation?.properties?.apartment?.interiorArea.toString(),
        total_area: quotation?.properties?.apartment?.totalArea.toString(),
        floor: quotation?.properties?.apartment?.floor?.toString(),
        listprice_uf: quotation?.properties?.apartment?.listPrice.toString(),
        listprice_clp: (
          quotation?.properties?.apartment?.listPrice * ufPrice
        ).toString(),
      },
      parkings: {
        amount: quotation?.properties?.parkings.length?.toString() || '',
        listprice_clp: quotation?.properties?.parkings
          ?.reduce(
            (acc: number, parking: { listPrice: number }) =>
              acc + parking?.listPrice * ufPrice,
            0
          )
          .toString(),
        listprice_uf: quotation?.properties?.parkings
          ?.reduce(
            (acc: number, parking: { listPrice: number }) =>
              acc + parking?.listPrice,
            0
          )
          .toString(),
      },
      storages: {
        amount: quotation?.properties?.storages.length?.toString() || '',
        listprice_clp: quotation?.properties?.storages
          ?.reduce(
            (acc: number, parking: { listPrice: number }) =>
              acc + parking?.listPrice * ufPrice,
            0
          )
          .toString(),
        listprice_uf: quotation?.properties?.storages
          ?.reduce(
            (acc: number, parking: { listPrice: number }) =>
              acc + parking?.listPrice,
            0
          )
          .toString(),
      },
      offer: {
        discount_percentage: quotation?.discountPercentage.toString(),
        discount_uf: quotation?.discount.toString(),
        downpayment_percentage:
          quotation?.downpaymentDiscountPercentage.toString(),
        downpayment_uf: quotation?.downpaymentDiscount.toString(),
        discount_clp: (quotation?.discount * ufPrice).toString(),
        downpayment_clp: (quotation?.downpaymentDiscount * ufPrice).toString(),
      },
    },
  };

  const response = await axios.post(
    pdfApiUrl,
    {
      body: payload,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': pdfApiKey,
      },
    }
  );

  if (response?.data?.statusCode === 500) {
    return {
      status: 500,
      data: {
        error: 'An error occurred while generating the pdf',
      },
    };
  }

  return {
    status: 200,
    data: response.data,
  };
};
