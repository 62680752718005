import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { SearchComponent } from '../SearchComponent/SearchComponent';
import * as Yup from 'yup';
import { format, validate } from 'rut.js';
interface CardInfoRutProps {
  data: any;
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}

export const CardInfoRut: FC<CardInfoRutProps> = ({
  data,
  searchInput,
  setSearchInput,
  setSearchQuery,
}) => {
  const [error, setError] = useState('');
  const rutSchema = Yup.object().shape({
    dni: Yup.string()
      .required('El RUT es obligatorio')
      .test('valid-rut', 'El RUT no es válido', (value) => {
        if (!value) return true;

        const formattedRut = format(value);
        const isValidRut = validate(formattedRut);
        return isValidRut;
      }),
  });
  const handleKeyUp = () => {
    rutSchema
      .validate({ dni: searchInput })
      .then(() => {
        setError('');
      })
      .catch((error: Yup.ValidationError) => {
        setError(error.errors[0]);
      });
  };
  const disabled = error ? true : false;
  return (
    <>
      <div className="mt-8">
        <p className="text-2xl text-primary-600 font-medium mb-2">
          Información del cliente final
        </p>
        <small className="text-base text-[#454545]">
          Corrobora que los datos del cliente antes de vincularlo a la visita,
          en caso de no ser la primera transaccion del cliente, la visita se le
          asignara automaticamente a los datos guardados con anterioridad
          utilizando el rut del cliente
        </small>
        <div className="flex justify-center mt-8">
          <SearchComponent
            className="input"
            label="RUT"
            value={searchInput}
            handleChange={(e: any) => setSearchInput(e.target.value)}
            handleSearch={() => setSearchQuery(searchInput)}
            handleKeyUp={handleKeyUp}
            error={error}
            disabled={disabled}
            placeholder="Busca aquí por RUT"
          />
        </div>
      </div>
      <p className="flex flex-col text-xl text-primary-600 font-medium mt-14">
        {data && 'Datos asociados a este RUT:'}
      </p>
    </>
  );
};
