export const BuydepaWithAuraIcon: React.FC<{
  width?: string;
  height?: string;
  className?: string;
}> = ({ width = 600, height = 116, className = '' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 359 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M195.546 173.709H180.435V187.093H195.546V173.709Z"
        fill="#2C45C9"
      />
      <path
        d="M195.546 195.497H180.435V208.88H195.546V195.497Z"
        fill="#768CFB"
      />
      <path
        d="M195.546 217.286H180.435V230.67H195.546V217.286Z"
        fill="#768CFB"
      />
      <path
        d="M171.799 195.497H156.688V208.88H171.799V195.497Z"
        fill="#2C45C9"
      />
      <path
        d="M171.799 217.286H156.688V230.67H171.799V217.286Z"
        fill="#3A5AFF"
      />
      <path
        d="M132.942 175.888C154.53 138.226 202.023 125.465 239.339 146.941C250.75 153.789 260.31 162.815 266.478 173.709V171.842C266.478 158.769 259.694 146.63 248.283 140.094L196.472 109.902C185.37 103.366 171.492 103.366 160.39 109.902L108.579 140.405C97.4764 146.941 91 159.08 91 172.153V232.847C91 245.92 97.4764 258.059 108.579 264.595L160.39 295.098C171.492 301.634 185.37 301.634 196.472 295.098L198.014 294.164C185.678 293.853 173.034 290.429 161.623 283.581C124.307 261.482 111.354 213.238 132.942 175.888Z"
        fill="url(#paint0_linear_1595_6305)"
      />
      <path
        d="M248.591 140.405L196.472 109.902C185.37 103.366 171.492 103.366 160.39 109.902L108.579 140.405C97.4764 146.941 91 159.08 91 172.153V174.02C94.084 163.438 106.42 154.1 117.831 147.253C155.147 125.465 202.64 138.538 224.228 176.199C245.816 213.861 232.863 261.794 195.547 283.581C189.996 286.694 184.445 289.184 178.585 291.051C185.061 292.919 191.538 294.164 198.014 294.164L248.591 264.595C259.694 258.059 266.478 245.92 266.478 232.847V171.842C266.787 158.769 259.694 146.941 248.591 140.405Z"
        fill="url(#paint1_linear_1595_6305)"
      />
      <path
        d="M109.812 139.472L108.271 140.095L109.812 139.472Z"
        fill="url(#paint2_linear_1595_6305)"
      />
      <path
        d="M248.591 140.405L247.049 139.472C252.909 150.677 256.301 163.127 256.301 176.511C256.301 220.086 221.452 255.258 178.276 255.258C157.305 255.258 138.493 247.165 124.615 233.47C129.55 253.702 142.194 271.754 161.315 283.271C172.725 290.118 185.37 293.542 197.706 293.853L196.164 294.787L248.283 264.284C259.385 257.748 266.478 245.609 266.478 232.536V171.842C266.787 158.769 259.694 146.942 248.591 140.405Z"
        fill="url(#paint3_linear_1595_6305)"
      />
      <path
        opacity="0.05"
        d="M358.073 135.123C358.073 132.307 357.927 129.463 357.54 126.677C354.676 105.957 344.645 89.3798 328.03 76.8684C323.155 73.2003 317.709 70.2735 312.427 67.1723C289.207 53.5222 265.952 39.9205 242.703 26.314C232.406 20.286 222.293 13.8995 211.739 8.34637C193.52 -1.23828 174.433 -2.39639 154.876 3.99015C147.763 6.31121 141.434 10.1974 135.053 13.977C103.275 32.7974 71.5167 51.6372 39.6906 70.3753C32.0587 74.8672 24.8969 79.8679 19.0094 86.5451C9.14857 97.7434 3.25144 110.783 0.910997 125.48C-0.00482697 131.241 0.033938 137.037 0.0387837 142.851C0.08724 182.484 0.033938 222.111 1.86315e-05 261.744C-0.004827 269.497 0.935225 277.119 3.14968 284.547C8.61555 302.893 19.2275 317.376 35.7171 327.29C50.9518 336.449 120.147 377.191 139.331 388.694C150.006 395.095 161.369 399.112 173.832 399.859C189.546 400.804 204.045 397.005 217.365 388.651C219.439 387.323 304.291 336.778 321.023 326.874C334.014 319.189 343.802 308.475 350.479 294.96C355.679 284.436 358.092 273.242 358.092 261.516C358.092 219.383 358.107 177.25 358.077 135.118L358.073 135.123Z"
        fill="#3A5AFF"
      />
      <path
        opacity="0.08"
        d="M313.554 151.342C313.554 149.23 313.445 147.097 313.155 145.007C311.007 129.468 303.484 117.035 291.022 107.651C287.366 104.9 283.281 102.705 279.32 100.379C261.905 90.1416 244.464 79.9404 227.027 69.7355C219.305 65.2145 211.72 60.4246 203.805 56.2598C190.14 49.0713 175.825 48.2027 161.157 52.9926C155.822 54.7334 151.076 57.6481 146.289 60.4827C122.456 74.5981 98.6375 88.7279 74.7679 102.781C69.044 106.15 63.6726 109.901 59.2571 114.909C51.8614 123.308 47.4386 133.087 45.6832 144.11C44.9964 148.431 45.0255 152.777 45.0291 157.139C45.0654 186.863 45.0255 216.583 45 246.308C44.9964 252.123 45.7014 257.839 47.3623 263.41C51.4617 277.17 59.4206 288.032 71.7879 295.468C83.2139 302.337 135.111 332.893 149.498 341.521C157.505 346.322 166.027 349.334 175.374 349.894C187.16 350.603 198.033 347.753 208.024 341.488C209.579 340.492 273.218 302.584 285.767 295.155C295.511 289.391 302.852 281.356 307.86 271.22C311.759 263.327 313.569 254.932 313.569 246.137C313.569 214.537 313.58 182.938 313.558 151.338L313.554 151.342Z"
        fill="#3A5AFF"
      />
      <path
        opacity="0.1"
        d="M270.036 169.561C270.036 168.154 269.964 166.731 269.77 165.338C268.338 154.978 263.323 146.69 255.015 140.434C252.578 138.6 249.854 137.137 247.213 135.586C235.603 128.761 223.976 121.96 212.352 115.157C207.203 112.143 202.147 108.95 196.87 106.173C187.76 101.381 178.216 100.802 168.438 103.995C164.881 105.156 161.717 107.099 158.526 108.988C142.637 118.399 126.758 127.819 110.845 137.188C107.029 139.434 103.448 141.934 100.505 145.273C95.5743 150.872 92.6257 157.391 91.4555 164.74C90.9976 167.621 91.017 170.518 91.0194 173.426C91.0436 193.242 91.017 213.056 91 232.872C90.9976 236.748 91.4676 240.559 92.5748 244.274C95.3078 253.446 100.614 260.688 108.859 265.645C116.476 270.224 151.074 290.595 160.666 296.347C166.003 299.548 171.685 301.556 177.916 301.929C185.773 302.402 193.022 300.502 199.683 296.325C200.72 295.661 243.146 270.389 251.511 265.437C258.007 261.594 262.901 256.237 266.24 249.48C268.839 244.218 270.046 238.621 270.046 232.758C270.046 211.692 270.053 190.625 270.039 169.559L270.036 169.561Z"
        fill="#3A5AFF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1595_6305"
          x1="222.763"
          y1="279.337"
          x2="133.236"
          y2="125.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2475" stopColor="#060C41" />
          <stop offset="0.6748" stopColor="#2039B9" />
          <stop offset="0.931" stopColor="#3A5AFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1595_6305"
          x1="237.574"
          y1="270.667"
          x2="148.064"
          y2="117.054"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3154" stopColor="#3A5AFF" />
          <stop offset="0.578" stopColor="#2039B9" />
          <stop offset="0.996" stopColor="#060C41" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1595_6305"
          x1="109.563"
          y1="139.289"
          x2="108.534"
          y2="140.307"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5119" stopColor="#6F3185" />
          <stop offset="0.8696" stopColor="#DB972E" />
          <stop offset="0.9797" stopColor="#E5C13A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1595_6305"
          x1="272.729"
          y1="162.882"
          x2="154.511"
          y2="280.015"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2475" stopColor="#060C41" />
          <stop offset="0.6748" stopColor="#2039B9" />
          <stop offset="0.931" stopColor="#3A5AFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
