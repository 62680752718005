import { FC, ReactNode } from 'react';

interface CardDataNullProps {
  icon: ReactNode;
  description: string | ReactNode;
}

export const CardDataNull: FC<CardDataNullProps> = (props) => {
  return (
    <div className="bg-ghost-blue-300 flex flex-col justify-center items-center lg:p-40 p-10">
      <div className="bg-ghost-blue-600 rounded-full p-8">{props.icon}</div>
      {typeof props.description === 'string' ? (
        <p className="text-primary-600 text-sm text-center font-semibold pt-8 w-40">
          {props.description}
        </p>
      ) : (
        props.description
      )}
    </div>
  );
};
