const PercentageBookmark = () => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.598 36.5179L38.6763 21.4396M49.985 12.6615V55.3658L40.5611 51.5962L31.1371 55.3658L21.7132 51.5962L12.2892 55.3658V12.6615C12.2892 9.87701 14.3147 7.49213 17.0816 7.17045C26.4206 6.08641 35.8537 6.08641 45.1926 7.17045C47.957 7.49213 49.985 9.87701 49.985 12.6615ZM25.4828 23.3244H25.5029V23.3445H25.4828V23.3244ZM26.4252 23.3244C26.4252 23.5743 26.3259 23.814 26.1491 23.9907C25.9724 24.1675 25.7327 24.2668 25.4828 24.2668C25.2328 24.2668 24.9931 24.1675 24.8164 23.9907C24.6396 23.814 24.5404 23.5743 24.5404 23.3244C24.5404 23.0744 24.6396 22.8347 24.8164 22.658C24.9931 22.4813 25.2328 22.382 25.4828 22.382C25.7327 22.382 25.9724 22.4813 26.1491 22.658C26.3259 22.8347 26.4252 23.0744 26.4252 23.3244ZM36.7915 34.6331H36.8116V34.6532H36.7915V34.6331ZM37.7339 34.6331C37.7339 34.8831 37.6346 35.1228 37.4579 35.2995C37.2811 35.4762 37.0414 35.5755 36.7915 35.5755C36.5416 35.5755 36.3019 35.4762 36.1251 35.2995C35.9484 35.1228 35.8491 34.8831 35.8491 34.6331C35.8491 34.3832 35.9484 34.1435 36.1251 33.9667C36.3019 33.79 36.5416 33.6907 36.7915 33.6907C37.0414 33.6907 37.2811 33.79 37.4579 33.9667C37.6346 34.1435 37.7339 34.3832 37.7339 34.6331Z"
        stroke="#7483AB"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PercentageBookmark
