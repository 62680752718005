const IconLivingRoom = (props: any) => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.85 5V5.15H21C22.5672 5.15 23.85 6.43284 23.85 8V13C23.85 14.5672 22.5672 15.85 21 15.85H20.85V16V17.85H19.15V16V15.85H19H5H4.85V16V17.85H3.15V16V15.85H3C1.43284 15.85 0.15 14.5672 0.15 13V8C0.15 6.43284 1.43284 5.15 3 5.15H3.15V5V3C3.15 1.43284 4.43284 0.15 6 0.15H10C10.7301 0.15 11.3949 0.434331 11.8993 0.891181L12 0.982374L12.1007 0.891181C12.6051 0.434331 13.2699 0.15 14 0.15H18C19.5672 0.15 20.85 1.43284 20.85 3V5ZM19.1004 5.8914L19.15 5.84672V5.78V3C19.15 2.36716 18.6328 1.85 18 1.85H14C13.3672 1.85 12.85 2.36716 12.85 3V10V10.15H13H18H18.15V10V8C18.15 7.16551 18.5197 6.41506 19.1004 5.8914ZM11 10.15H11.15V10V3C11.15 2.36716 10.6328 1.85 10 1.85H6C5.36716 1.85 4.85 2.36716 4.85 3V5.78V5.84672L4.89955 5.8914C5.48033 6.41506 5.85 7.16551 5.85 8V10V10.15H6H11ZM21 14.15C21.6328 14.15 22.15 13.6328 22.15 13V8C22.15 7.36716 21.6328 6.85 21 6.85C20.3672 6.85 19.85 7.36716 19.85 8V11.85H4.15V8C4.15 7.36716 3.63284 6.85 3 6.85C2.36716 6.85 1.85 7.36716 1.85 8V13C1.85 13.6328 2.36716 14.15 3 14.15H21Z"
        fill="#F2006E"
        stroke="#F5F5F5"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default IconLivingRoom;
