import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { getInstance } from './axios-instance';

export type SWRQueryResponse<TData = any, TError = any> = SWRResponse<
  TData,
  TError
> & { loading: boolean };

const instance = getInstance();

export async function fetcher(url: string, body: unknown) {
  try {
    const response: AxiosResponse = await instance.post(url, body);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError;
      if (serverError && serverError.response) {
        throw {status: serverError.response.status, data: serverError.response.data};
      }
    }
    throw error;
  }
}

export function useCustomSWR(
  key: string | null,
  fetcher: ((...args: any[]) =>  Promise<any>),
  config?: SWRConfiguration
) {
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    key,
    fetcher,
    config
  );

  return {
    data,
    error,
    mutate,
    loading: isValidating || isLoading,
  };
}
