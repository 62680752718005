const LogoGorent = ({...props}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.64 56.69" {...props}>
    <defs>
      <style>{'.cls-1{fill:#334155}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M52.75 32.64c-5.31-.35-9.12 2.13-12.09 7.07V2.5C40.7.64 40.11-.06 38.2 0c-3.68.12-7.37-.08-11.05.06a27.75 27.75 0 0 0-20 9.32c-4 4.39-6.09 9.71-7.16 15.47v6.29a7.75 7.75 0 0 1 .27.94c2 12.34 10.82 21.54 23.18 23.62 5.26.89 10.63.22 16 .29 1.1 0 1.23-.82 1.24-1.64v-5.16c5.64 7.46 12.82 9 18.66 4.25a11.74 11.74 0 0 0 1.47-16.7 11.5 11.5 0 0 0-8.06-4.1ZM97.46 32.67A10.92 10.92 0 0 0 86 40.93c-1.89 7.91 1.27 13.36 9.14 15.76h4.34c2.84-.82 5.39-2.08 7-4.68.31-.48.94-1.07.46-1.56a10.62 10.62 0 0 0-3.94-2.16c-1-.39-1.25.73-1.74 1.26a5.6 5.6 0 0 1-8.68-.79c-.9-1.51-.58-2.17 1.16-2.18h10c4.74 0 4.74 0 4-4.67a10.64 10.64 0 0 0-10.28-9.24Zm2.93 9.33h-7.08c-1.22 0-1.31-.64-.78-1.52A4.69 4.69 0 0 1 97 38.21a4.59 4.59 0 0 1 4.3 2.24c.6 1.02.38 1.6-.91 1.55ZM134.68 41.57c-.16-5.07-3.87-8.49-8.91-8.57a8 8 0 0 0-6.86 3.34c.23-3-1.25-3.13-3-3h-.79c-2.55 0-2.6 0-2.6 2.68v16.09c0 4.44-.12 3.74 4 3.89 1.8.07 2.46-.54 2.41-2.34-.09-3.53-.11-7.07 0-10.6a4.81 4.81 0 0 1 5.32-4.82c2.81.1 4.26 1.73 4.31 5 .05 2.94 0 5.88 0 8.83 0 4 0 4 4.1 3.94 1.41 0 2-.45 2-1.92.02-4.15.15-8.34.02-12.52ZM152.38 50.8h-.79c-1.44.11-2-.56-2-2 .06-2.28 0-4.57 0-6.86 0-3.32 0-3.32 3.33-3.35 2.61 0 2.69-.1 2.68-2.62s0-2.59-2.66-2.6h-1.57c-1.26.09-1.82-.44-1.78-1.71s0-2.48 0-3.73c0-1-.49-1.48-1.56-1.58-4.6-.44-4.78-.3-4.8 4.26v.19c0 2.55 0 2.55-2.66 2.57-2.82 0-2.86 0-2.86 2.61s.07 2.58 2.7 2.61c2.82 0 2.82 0 2.82 2.85v8.82c0 3.83 1.9 5.67 5.68 5.74h4.78a1.24 1.24 0 0 0 1.3-.66c1.14-2.29-.11-4.5-2.61-4.54ZM82.39 33.41c-2.25-.07-4.54-.27-6.47 2.54.12-2.4-1-2.72-2.38-2.57h-1c-2.79 0-2.79 0-2.79 2.84v15.89c0 4.26-.61 3.84 4.09 3.92 1.56 0 2.08-.57 2-2.07-.06-3.53 0-7.07 0-10.6 0-2.93 1.5-4.43 4.46-4.62a17.42 17.42 0 0 1 1.77 0c1 0 1.51-.38 1.53-1.36s0-1.83 0-2.74a1.15 1.15 0 0 0-1.21-1.23Z"
          className="cls-1"
        />
      </g>
    </g>
  </svg>
)
export default LogoGorent
