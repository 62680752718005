const ColombianFlag = ({ ...props }) => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1009_4188)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H19V14H0V0Z"
        fill="#FFE800"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7H19V14H0V7Z"
        fill="#00148E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.5H19V14H0V10.5Z"
        fill="#DA0010"
      />
    </g>
    <defs>
      <clipPath id="clip0_1009_4188">
        <rect width="19" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ColombianFlag;
