export const BuydepaIsotype = ({...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 108 120"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M25.748 43.924c6.354-11.139 16.387-18.723 27.626-22.109 11.404 3.349 21.604 11.027 28.034 22.3 5.938 10.41 7.59 22.102 5.525 33.026-8.696 9.716-21.255 15.806-35.249 15.806-11.583 0-22.086-4.07-30.25-11.074-3.681-12.325-2.534-26.04 4.314-37.949Zm38.736 73.045.94-.573 30.839-18.118c6.769-4.005 10.906-11.443 10.906-19.453V41.445c.116-4.963-1.499-9.633-4.355-13.42a21.888 21.888 0 0 0-6.74-6.033l-31.59-18.5a21.644 21.644 0 0 0-22 0l-31.59 18.69C4.123 26.187.175 33.625.175 41.635V78.825c0 8.01 3.95 15.448 10.718 19.453l31.59 18.691a21.644 21.644 0 0 0 22.001 0Zm-9.778-74.381h9.213v8.2h-9.213v-8.2Zm0 13.352h9.213v8.2h-9.213v-8.2Zm9.213 13.35h-9.213v8.2h9.213v-8.2ZM40.23 55.94h9.213v8.2h-9.214v-8.2Zm9.213 13.35h-9.214v8.2h9.214v-8.2Z"
      clipRule="evenodd"
    />
  </svg>
)