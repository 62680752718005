import { mutate } from 'swr';
import axios from 'axios';
import { useState } from 'react';
import { getInstance } from './axios-instance';

export type CustomMutationT = [
  (data: any, headers?: any) => Promise<any>,
  boolean,
  string | null
];

export type UseCustomMutationProps = {
  url: string;
  mutateKey?: string | string[];
  onError?: (error: any) => void;
  onSuccess?: (data: any) => void;
};

const instance = getInstance();

export const useCustomMutation = ({
  url,
  mutateKey,
  onError,
  onSuccess,
}: UseCustomMutationProps): CustomMutationT => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const mutateData = async (data: any, headers?: any) => {
    try {
      setLoading(true);
      const response = await instance.post(url, data, {
        headers: { 'Content-Type': 'application/json', ...headers },
      });
      if (mutateKey) {
        if (typeof mutateKey === 'string') {
          mutate(mutateKey);
        } else if (Array.isArray(mutateKey)) {
          mutateKey.forEach((key) => {
            mutate(key);
          });
        }
      } else {
        mutate(url);
      }

      onSuccess && onSuccess(response?.data);
      return response?.data;
    } catch (err: any) {
      setError(err?.response?.data ?? 'Something went wrong');
      onError && onError(err);
    } finally {
      setLoading(false);
    }
  };

  return [mutateData, loading, error];
};
