import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import type { ReactNode } from 'react';

export type CardModalTypeT =
  | 'primary'
  | 'info'
  | 'success'
  | 'error'
  | 'warning';

interface CardModalProps {
  title: string;
  type?: CardModalTypeT;
  open?: boolean;
  children: ReactNode;
}

function CardModal({
  title,
  type = 'primary',
  open,
  children,
}: CardModalProps) {
  const headerColors = {
    primary: 'bg-primary-500',
    info: 'bg-cyan-600',
    success: 'bg-green-600',
    error: 'bg-red-600',
    warning: 'bg-orange-600',
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => console.log('open')}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 flex justify-center top-1/3 -translate-y-1/2">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-y-full"
            enterTo="translate-y-1/2"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="-translate-y-0"
            leaveTo="-translate-y-full"
          >
            <Dialog.Panel className="relative flex w-full md:min-w-md md:max-w-lg">
              <div className="flex grow flex-col overflow-y-auto drop-shadow-xl">
                <div className="mt-2 p-6">
                  <div className="bg-white shadow relative rounded-md">
                    <div
                      className={`${
                        headerColors[type] || 'bg-primary-500'
                      } text-lg font-semibold p-6 text-white text-center rounded-t-md`}
                    >
                      {title || 'Confirmar acción'}
                    </div>
                    <div className="px-8">{children}</div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default CardModal;
