export const removeFirstUrlSegment = (url: string): string => {
  const segments = url.split('/');
  segments.shift();
  return segments.join('/');
};

export const getFirstUrlSegment = (
  url: string,
  withoutSlashPrefix?: boolean
): string => {
  if (url.includes('/')) {
    const segments = url.split('/');
    return segments[withoutSlashPrefix ? 0 : 1] || '';
  }
  return url;
};
