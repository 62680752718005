import { Fragment } from 'react';
import { Spinner } from '../Spinner/Spinner';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useField } from 'formik';
import cn from 'classnames';

import type { OptionT } from '../SelectFormik/SelectFormik';
import type { ReactNode } from 'react';

interface ComboboxFormikProps {
  name: string;
  label?: string;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  options: OptionT[];
  className?: string;
  selectedOptionClassName?: string;
  optionsClassName?: string;
  startIcon?: ReactNode;
  multiple?: boolean;
  hideErrors?: boolean;
  singleDisplayName?: (value: string | number) => string;
  [key: string]: any;
}

export default function ComboboxFormik({
  name,
  label,
  placeholder,
  loading,
  disabled,
  options,
  className,
  selectedOptionClassName,
  optionsClassName,
  startIcon,
  multiple,
  hideErrors,
  singleDisplayName,
  ...rest
}: ComboboxFormikProps) {
  const [field, { error, touched }, { setValue }] = useField({
    name,
  });

  const inputClassName = cn(
    {
      'relative w-full cursor-default bg-white py-1.5 pl-3 pr-10 text-left focus:outline-none':
        true,
      'rounded-3xl border border-primary-600': !disabled,
      'border-x-0 border-t-0 border-b-2 border-primary-600 outline-none disabled':
        disabled,
      'appearance-none pr-10 bg-gray-100': loading,
    },
    className
  );

  const labelClassName = cn({
    'block text-sm font-base text-primary-600 font-semibold ml-4': !disabled,
    'block text-sm font-base text-primary-600 font-semibold': disabled,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleListChange = (values: any) => {
    if (multiple) {
      const newValues = values?.map((val: any) => {
        if (val.value) {
          return val.value;
        }
        return val;
      });
      setValue(newValues);
    } else {
      setValue(values);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  };

  return (
    <Listbox
      multiple={multiple}
      disabled={disabled}
      value={field.value}
      onChange={handleListChange}
      name={name}
      {...rest}
    >
      {({ open }) => (
        <div className="flex flex-col">
          {label && (
            <Listbox.Label className={labelClassName}>{label}</Listbox.Label>
          )}
          <div className="relative w-full flex items-center justify-center">
            {startIcon && <div className="pl-5 lg:pl-2">{startIcon}</div>}
            <Listbox.Button className={inputClassName}>
              <div className="flex gap-2">
                {!multiple && !Array.isArray(field.value) ? (
                  loading ? (
                    <span className="block truncate">Cargando...</span>
                  ) : (
                    `${field.value} ${
                      singleDisplayName !== undefined
                        ? singleDisplayName(field.value)
                        : ''
                    }`
                  )
                ) : field.value?.length > 0 ? (
                  field.value.length > 3 ? (
                    <span className="block truncate">
                      {field.value.length} Seleccionados
                    </span>
                  ) : (
                    field.value?.map((item: string, idx: number) => (
                      <span
                        key={idx}
                        className={`block truncate px-1 rounded bg-ghost-blue-600 ${selectedOptionClassName}`}
                      >
                        {options?.find((option) => option.value === item)
                          ?.label || ''}
                      </span>
                    ))
                  )
                ) : (
                  <span className="block truncate">
                    {loading
                      ? 'Cargando...'
                      : placeholder
                      ? placeholder
                      : 'Seleccionar'}
                  </span>
                )}
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                {loading ? (
                  <Spinner />
                ) : (
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-primary"
                    aria-hidden="true"
                  />
                )}
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute border border-primary-300 z-10 top-16 max-h-60 w-full overflow-auto rounded-b-md bg-white py-1 text-base shadow focus:outline-none sm:text-sm ${optionsClassName}`}
              >
                {loading ? (
                  <span className="px-2">Cargando...</span>
                ) : options && options.length > 0 ? (
                  options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        cn(
                          active
                            ? 'bg-gray-100 text-primary-500 cursor-pointer'
                            : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3'
                        )
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={cn(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={cn(
                                active ? 'text-white' : 'text-primary-500',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))
                ) : (
                  <span className="px-2">No hay opciones para seleccionar</span>
                )}
              </Listbox.Options>
            </Transition>
          </div>
          {touched && error && !hideErrors && (
            <div className="error text-red-500 text-sm">{error}</div>
          )}
        </div>
      )}
    </Listbox>
  );
}
