import type { InputHTMLAttributes } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  type?: string;
}

export function Input({ name, label, type = 'text', ...rest }: InputProps) {
  return (
    <>
      {label && (
        <p>
          <label htmlFor={name}>{label}</label>
        </p>
      )}
      <input type={type} className="input" id={name} {...rest} />
    </>
  );
}
